import React from 'react';
import { useIonRouter, IonChip, IonButton, IonItem, IonLabel, IonInput, IonIcon, IonCard, IonGrid, IonRow, IonCol, IonProgressBar, IonLoading } from '@ionic/react';
import { compass, eye, eyeOff, logIn, mail, personAdd, personCircle } from 'ionicons/icons';

import ReCAPTCHA from 'react-google-recaptcha';
import PageBase from '../shared/PageBase';

import { useLocation } from "react-router-dom";
import { signIn } from './AuthCore';

import "./auth.css"
import AppConfig from '../../App.config';

import { GATrackSignIn } from '../../core/google';

const sep = "[{SEPARADOR}]";

const useEmailAndPassword = () => {
	const location = useLocation();

	let email = '';
	let password = '';

	if (location.state && location.state.email) {
		email = location.state.email;
	}

	if (location.state && location.state.password) {
		password = location.state.password;
	}

	return email + sep + password;
}

const SignIn = () => {

	const PageContent = () => {

		const router = useIonRouter();

		const loginData = useEmailAndPassword().split(sep);



		const recaptchaRef = React.createRef();

		const [username, setUsername] = React.useState(loginData[0]);
		const [password, setPassword] = React.useState(loginData[1]);
		const [loggingIn, setLoggingIn] = React.useState(false);

		const [showPassword, setShowPassword] = React.useState(true);
		const [passwordIcon, setPasswordIcon] = React.useState(eyeOff);

		const eyePressed = () => {
			setShowPassword(!showPassword);
			setPasswordIcon(showPassword ? eye : eyeOff);
		}

		const handleReCapcha = (token) => {

			recaptchaRef.current.reset();

			signIn({ username, password, token })
				.then(data => {

					GATrackSignIn(username);

					//window.history.replaceState({}, document.title);
					window.location.reload(true);
					// router.push({
					// 	pathname: "/home",
					// 	state: { operation: "LOGIN" }
					// }, "forward", "push");

				})
				.catch(err => {
					setLoggingIn(false);
				});
		}

		return (<>
			<IonGrid id="auth-grid">
				<IonRow id="auth-row" className="ion-justify-content-center">
					<IonCol id="auth-col" className="ion-align-self-center" size-md="6" size-lg="5" size-xs="12">
						<IonCard >
							<div style={{ textAlign: "center", padding: 15 }} >
								<h4>Login</h4>
								<IonIcon
									style={{ fontSize: "70px" }}
									icon={personCircle}
								/>
							</div>
							<div style={{ padding: 15 }} >
								<IonItem fill="solid" style={{ marginBottom: 18 }} >
									<IonLabel position="stacked">E-Mail</IonLabel>
									<IonInput
										onChangeText={(email) => { setUsername(email); setLoggingIn(false) }}
										onFocus={() => { setLoggingIn(false) }}
										value={username}

										onIonChange={(e) => setUsername(e.detail.value)} >
									</IonInput>
									<IonIcon slot="end" icon={mail} />
								</IonItem>

								<IonItem fill="solid" >
									<IonLabel position="stacked">Password</IonLabel>
									<IonInput
										type={showPassword ? "password" : "text"}
										onFocus={() => { setLoggingIn(false) }}
										value={password}
										onIonChange={(e) => setPassword(e.detail.value)} >

									</IonInput>

									<IonIcon slot="end" icon={passwordIcon} onClick={eyePressed} />
								</IonItem>

							</div>


							<div style={{ padding: 15 }} >
								<IonButton id="auth-button" size="large" expand="block" onClick={() => {
									if (username && password && username.trim() != '' && password.trim() != '') {
										setLoggingIn(true);

										recaptchaRef.current.execute();
									}
									else {
										alert("Please, inform Email and Password.");
									}
								}

								}>
									Login
									<IonIcon icon={logIn} slot="end" />
								</IonButton>

							</div>
							<div style={{ padding: 15 }} >
								{loggingIn && <IonProgressBar type="indeterminate"></IonProgressBar>}
							</div>

							<div style={{ padding: 15, textAlign: "right" }} >
								<a target="_self" href="/dashboard/register">

									<IonChip outline color="secondary">
										<IonIcon icon={personAdd} />
										<IonLabel>Register</IonLabel>
									</IonChip>

								</a>
								<br />
								<a target="_blank" rel="noreferrer" href="https://jiujitsux.com/my-account/lost-password/">

									<IonChip outline color="secondary">
										<IonIcon icon={compass} />
										<IonLabel>Forgot your password?</IonLabel>
									</IonChip>

								</a>


							</div>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>

			<ReCAPTCHA
				style={{ innerHeight: "100%", innerWidth: "100%" }}
				ref={recaptchaRef}
				size="invisible"
				sitekey={AppConfig.Google.ReCapchaKey}
				onChange={handleReCapcha}
			/>

			<IonLoading
				isOpen={loggingIn}
				onDidDismiss={() => setLoggingIn(false)}
				message={'Please wait...'}
				duration={6000}
			/>


		</>);

	}

	return (<PageBase title='Sign In' showButtons={false} robots="noindex, nofollow" description="Log-in into your account" canonical="https://jiujitsux.app/dashboard" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">
		<PageContent />
	</PageBase>);

}


export default SignIn;