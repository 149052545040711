import React from 'react';
import { IonItem, IonLabel, IonInput, IonTextarea } from '@ionic/react';

function InputLabel({ type, caption, value, placeholder, onChange, onBlur, rows = 3, maxlength = null }) {

	let position = "stacked";
	let content;

	switch (type) {
		case "label":
			content = <IonLabel>{value}</IonLabel>;
			position = "";
			break;

		case "textarea":
			content = <IonTextarea maxlength={maxlength} type="text" placeholder={placeholder} value={value} onIonChange={e => onChange && onChange(e.detail.value)} rows={rows} autoGrow={true} onIonBlur={e => onBlur && onBlur(e.detail.value)} />;
			break;

		default:
			content = <IonInput maxlength={maxlength} type={type} placeholder={placeholder} value={value} onIonChange={e => onChange && onChange(e.detail.value)} onIonBlur={e => onBlur && onBlur(e.detail.value)} />;
			break;
	}

	return (
		<IonItem>
			<IonLabel position={position}>{caption}</IonLabel>
			{content}
		</IonItem>
	)
}

export default InputLabel;