import React, { Suspense } from 'react';
import { IonItem, IonLabel, IonSpinner } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getMaxElements } from '../../core/Functions';

import 'swiper/css';
import { GATrackSwipeToEnd } from '../../core/google';

const BotCourseItemTemplate = React.lazy(() => import('./BotCourseItemTemplate'));

function shuffleCourses(array) {
	array.sort(() => Math.random() - 0.5);
}

const CourseRenderItem = ({ item, showPrice }) => {
	return (<Suspense fallback={<IonSpinner name="bubbles" color="secondary" />}>
		<BotCourseItemTemplate key={1} item={item.item1} showPrice={showPrice} />
		{item.item2 && <BotCourseItemTemplate key={2} item={item.item2} showPrice={showPrice} />}
		{item.item3 && <BotCourseItemTemplate key={3} item={item.item3} showPrice={showPrice} />}
		{item.item4 && <BotCourseItemTemplate key={4} item={item.item4} showPrice={showPrice} />}
		{item.item5 && <BotCourseItemTemplate key={5} item={item.item5} showPrice={showPrice} />}
	</Suspense>);
}

export default function BotCourseSection({ showPrice, title, courses, shuffle = false }) {

	if (courses.length == 0)
		return (<></>);

	if (shuffle)
		shuffleCourses(courses);

	return (
		<div >
			{title &&
				<IonItem lines="none" >
					<IonLabel>
						<h2 dangerouslySetInnerHTML={{ __html: title }} />
					</IonLabel>
				</IonItem>}

			<Swiper style={{ borderRadius: 5, backgroundColor: "#4B34F8" }}
				spaceBetween={0}
				slidesPerView={3}	>

				{courses.map((item, index) => {
					return (
						<SwiperSlide key={index}>
							<CourseRenderItem item={item} showPrice={showPrice} />
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);



}