import React, { Suspense } from 'react';
import { useLocation } from "react-router-dom";
import { useIonRouter } from "@ionic/react";

import { IonAvatar, IonSpinner, IonGrid, IonRow, IonCol, IonLabel } from '@ionic/react';

import ReviewSummary from '../../courses/reviews/ReviewSummary';
import Stars from '../../courses/reviews/Stars';
import PageBase from '../../shared/PageBase';
import { getAuthorDetails } from '../../../core/backend/GQLQueries';
import Splash from '../../shared/Splash';
import Error from '../../shared/Error';
import { GATrackOpenedAuthorsPage } from '../../../core/google';
import './AuthorPage.css';

const CourseDetailsSuggestions = React.lazy(() => import('../../courses/CourseDetailSusggestions'));

const useParams = () => {
	const location = useLocation();

	let name = "";
	let id = 0;
	let query;

	try {
		//Query String
		if (location.search) {
			query = new URLSearchParams(location.search);
		}

		//Id
		if (location.state && location.state.id)
			id = location.state.id;
		else
			id = query.get("id");

		//Name
		if (location.state && location.state.name)
			name = location.state.name;
		else
			name = query.get("name");

		return { id: Number(id), name };
	}
	catch
	{
		return { id: Number(id), name };
	}
}


export default function AuthorPage() {

	let name = "";
	let id = 0;

	({ id, name } = useParams());

	const router = useIonRouter();

	const [state, setState] = React.useState({
		dataStatus: { called: true, loading: true, error: false },
		dataSource: {}
	});

	const showAllReviews = () => {
		router.push({
			pathname: "/home/all-reviews", state:
			{
				isAllFromAuthor: true,
				id: id,
				title: state.dataSource.name
			}
		}, "forward", "push");
	}

	const loadIntructorDetails = (abortController) => {

		getAuthorDetails(id, abortController).then(result => {

			GATrackOpenedAuthorsPage(id, name);

			setState(
				{
					dataStatus: { called: false, loading: false, error: false },
					dataSource: result.data.authorDetails
				}
			);

		}).catch(err => {
			if (err) {
				setState(
					{
						dataStatus: { called: true, loading: false, error: true },
						dataSource: {}
					}
				);
			}
		});
	}

	const PageContent = () => {
		return (<IonGrid>
			<IonRow className='ion-justify-content-center'>
				<IonCol size={12} size-xl={6} size-lg={6} size-md={12} size-sm={12}>
					<IonGrid>
						<IonRow>
							<IonRow>
								<IonCol>
									<IonLabel><h1>{state.dataSource.name}</h1></IonLabel>
								</IonCol>
							</IonRow>
						</IonRow>
						<IonRow>
							<IonCol>
								<IonAvatar className='avatar'>
									<img alt={state.dataSource.name + " - profile picture"} src={state.dataSource.pictureMedium} />
								</IonAvatar>
							</IonCol>
						</IonRow>

						<IonRow>
							<IonCol>
								<br />
								<br />
								<ReviewSummary
									title="Author Rating"
									onShowAllReviews={() => showAllReviews()}
									summary={state.dataSource.reviewsSummary}
									showAllReviews={false} />
							</IonCol>
						</IonRow>

					</IonGrid>
				</IonCol>
				<IonCol size={12} size-xl={6} size-lg={6} size-md={12} size-sm={12}>

					<IonGrid>
						<IonRow>
							<IonCol>
								<IonLabel position='stacked'><Stars rating={state.dataSource.rating} reviews={state.dataSource.reviews} /></IonLabel>
							</IonCol>
						</IonRow>

						<IonRow>
							<IonCol>
								{state.dataSource.students !== 0 && <IonLabel>{state.dataSource.students} students</IonLabel>}
							</IonCol>
						</IonRow>

						<IonRow>
							<IonCol>
								<IonLabel>
									{"a.k.a: "}
									{state.dataSource.name}
								</IonLabel>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								{state.dataSource.bio
									? <div dangerouslySetInnerHTML={{ __html: state.dataSource.bio }} />
									: "No bio Information yet."
								}
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonCol>
			</IonRow>
		</IonGrid>);
	}


	React.useEffect(() => {

		let abortController = new AbortController();

		if (id && id > 0)
			loadIntructorDetails(abortController);

		return () => {
			abortController.abort();
		}

	}, [id]);

	if (state.dataStatus.called && state.dataStatus.loading) return <Splash />;
	if (state.dataStatus.called && state.dataStatus.error) return <Error />;

	return (<PageBase title={name} robots="index, follow" description={" All courses from " + name} canonical="https://jiujitsux.app/home/author-bio" og_type="website" image={state.dataSource.pictureMedium} image_alt={state.dataSource.name + " - profile picture"}>

		<div style={{ paddingLeft: "5%", paddingRight: "5%", paddingBottom: "2%", paddingTop: "2%" }}>
			<PageContent />

			<Suspense fallback={<IonSpinner name="bubbles" color="secondary" />}>
				<CourseDetailsSuggestions overrideTitle="Instructor Courses" type="author" id={id} />
			</Suspense>
		</div>
	</PageBase>);
}
