import SendMessageForm from './SendMessageForm';
import MessageContainer from './MessageContainer';
import { IonItem, IonLabel } from '@ionic/react';
import { IsAuthenticated } from '../auth/AuthCore';

const Chat = ({ me, sendMessage, messages, updateDoc }) => (<div>

    <MessageContainer me={me} messages={messages} updateDoc={updateDoc} />
    <SendMessageForm sendMessage={sendMessage} />

</div>);

export default Chat;