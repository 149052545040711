import { setLogin, setUser } from '../../core/backend/GQLMutations';
import { getMaxElements } from '../../core/Functions';

export const loggedUserData = () => {
	try {
		const credentials = localStorage.getItem("userdata");
		if (credentials) {
			return JSON.parse(credentials);
		}
		else
			return { userId: 0 };

	} catch (error) {
		return false;
	}

}

export const IsAdmin = (credentials) => {
	try {
		if (credentials) {

			return credentials.isAdmin || credentials.isManager;
		}
	} catch (error) {
		return false;
	}
}


export const IsAuthenticated = () => {
	let userToken;
	try {
		const credentials = localStorage.getItem("userdata");
		if (credentials) {
			userToken = JSON.parse(credentials).token;

			return userToken && userToken != undefined && userToken != "";
		}
	} catch (error) {
		return false;
	}

}

export const anonymousUser = "anonymous";

export const loggedUser = () => {
	let email = localStorage.getItem("email");

	if (!email || email.trim() == '')
		email = anonymousUser;

	return email;
}

export const signIn = (inputData) => {
	return new Promise((resolve, reject) => {

		setLogin(inputData.username, inputData.password, inputData.token).then(result => {

			if (!result.success) {
				alert("Communication problems were found. Try again.");
				return;
			}

			const data = result.data;

			if (data.UserLogin.token === 'Invalid Credentials') {
				alert("Please, inform a valid Email and Password.");
			} else {
				localStorage.setItem("userdata", JSON.stringify(data.UserLogin));
				localStorage.setItem("email", inputData.username);

				resolve(data.UserLogin);

			}
		})
			.catch(e => {
				alert("Something went wrong, internet maybe?. Try again.");
				reject(JSON.stringify(e, null, 2));
			});

	});
}

export const signOut = () => {
	return new Promise((resolve, reject) => {
		const max = getMaxElements();
		localStorage.clear();
		localStorage.setItem("maxElements", max);
		resolve(undefined);
	});
}

export const signUp = (inputData) => {
	return new Promise((resolve, reject) => {
		setUser(inputData.username, inputData.password, inputData.firstname, inputData.lastname, "", inputData.nickname).then(result => {

			if (!result.success) {
				alert("Communication problems were found. Try again.");
				return;
			}

			const data = result.data;

			if (data.CreateUser.token.length < 150) {
				alert(data.CreateUser.token);
			} else {

				localStorage.setItem("userdata", JSON.stringify(data.CreateUser));
				localStorage.setItem("email", inputData.username);

				resolve(data.CreateUser);
			}
		})
			.catch(e => {
				alert("Something went wrong, internet maybe?. Try again.");
				reject(JSON.stringify(e, null, 2));
			});
	});
}
