import React from 'react';

import FeedPlayer from '../../video/FeedPlayer';
import Splash from '../../shared/Splash';
import Error from '../../shared/Error';

import { getFreeContent } from '../../../core/backend/GQLQueries';
import PageBase from '../../shared/PageBase';
import { GATrackOpenedRolls } from '../../../core/google';
import { IsAuthenticated } from '../../auth/AuthCore';

export default function Feed() {

	const [playList, setPlayList] = React.useState([]);

	const [called, setCalled] = React.useState(true);
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState(false);

	const refreshList = () => {
		window.location.reload(true);
	}

	const loadRolls = (abortController) => {
		setCalled(true);
		setLoading(true);

		getFreeContent(false, abortController).then(result => {
			GATrackOpenedRolls(IsAuthenticated());
			setPlayList(result.data.freeContent);
			setLoading(false);
		}).catch(err => {
			if (err) {
				setError(true);
				setLoading(false);
			}
		});
	}


	React.useEffect(() => {

		let abortController = new AbortController();

		loadRolls(abortController)

		return () => {
			abortController.abort();
		}

	}, []);

	if ((called && loading) || playList.length == 0) return <Splash />;
	if (called && error) return <Error />;

	return (
		<PageBase title='Rolls' robots="index, nofollow" description="Watch over 3000+ Techniques for free!" canonical="https://jiujitsux.app/rolls" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">
			<FeedPlayer playList={playList} refreshList={refreshList} />
		</PageBase>
	);

}