import React from 'react';
import { IonItem, IonLabel, IonInput, IonTextarea } from '@ionic/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function RichInput({ isReadOnly, caption, value, onChange, height = 200 }) {

	//style={{height: height}}

	let position = "stacked";
	let content = isReadOnly
		? <div dangerouslySetInnerHTML={{ __html: value }}></div>
		: <ReactQuill style={{ marginTop: 15, width: "100%" }} theme="snow" value={value} onChange={e => onChange(e)} />

	return (
		<IonItem>
			<IonLabel position={position}>{caption}</IonLabel>
			{content}
		</IonItem>
	)
}

export default RichInput;