import React from 'react';

import Splash from '../shared/Splash';
import Error from '../shared/Error';

import CourseSectionMultiple from './CourseSectionMultiple';

import { breakInChuncks } from '../../core/Functions';
import { getCourses } from '../../core/backend/GQLQueries';

export default function Courses({ maxElements, courseDivision = "myEnrolledCoursesNew" }) {

	const showPrice = courseDivision != "myEnrolledCoursesNew";
	const [refreshing, setRefreshing] = React.useState(false);
	const [dataSource, setDataSource] = React.useState([]);

	const [called, setCalled] = React.useState(true);
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState(false);

	const listCourses = (abortController) => {
		setRefreshing(true);
		setCalled(true);
		setLoading(true);

		getCourses(courseDivision, abortController).then(result => {

			const data = result.data;

			setDataSource(data[courseDivision]);
			setRefreshing(false);
			setLoading(false);
		}).catch(err => {
			if (err) {
				setError(true);
				setLoading(false);
			}
		});
	}


	React.useEffect(() => {

		let abortController = new AbortController();

		listCourses(abortController)

		return () => {
			abortController.abort();
		}

	}, []);

	if ((called && loading)) return <Splash />;
	if ((called && error)) return <Error err={error} />;

	return (
		<CourseSectionMultiple title="My Courses" courses={breakInChuncks(4, dataSource)} horizontal={true} showPrice={showPrice} showProgress={true} refreshing={false} maxElements={maxElements} />
	);
};
