import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';
import { useEffect, useRef } from 'react';
import { IsAdmin, loggedUserData } from '../auth/AuthCore';
import BotSuggestions from '../courses/BotSuggestions';

const MessageContainer = ({ me, messages, updateDoc }) => {
    const messageRef = useRef();
    const dummy = useRef();
    const credentials = loggedUserData();

    const scrollDown = () => {
        if (messageRef && messageRef.current) {
            const { scrollHeight, clientHeight } = messageRef.current;
            messageRef.current.scrollTo({ left: 0, top: scrollHeight - clientHeight, behavior: 'smooth' });
        }
    }

    useEffect(() => {
        scrollDown();
        //setTimeout(scrollDown, 50);  <span ref={dummy}></span>

        //        dummy.current.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);


    const getClass = (m) => {
        const c = `msg ${m.user_id !== me ? "other-msg" : "my-msg"}`;
        return c;
    }

    const getOffset = (m) => {
        const off = m.user_id !== me ? 0 : 3;
        return off;
    }

    return (<div style={{ height: window.innerHeight - 165 }} className='message-container' ref={messageRef} ><IonGrid>

        {messages.map((m, index) => {

            return (<IonRow key={index}>
                <IonCol size='9' offset={getOffset(m)} className={getClass(m)}>
                    <i style={{ fontSize: 10, color: "GrayText" }} >{m.nick}</i>

                    <br />
                    {m.text === 'best_sellers'
                        ? <BotSuggestions type="sales" id={0} />
                        : <span>{m.text}</span>
                    }
                    <div style={{ textAlign: "right" }} >
                        <i style={{ fontSize: 10, color: "GrayText" }} >{m.createdAt && new Date(m.createdAt.seconds * 1000).toUTCString()}</i>
                        {m.id && IsAdmin(credentials) && <div><IonButton size='small' onClick={() => updateDoc(m)}>X</IonButton></div>}
                    </div>



                </IonCol>
            </IonRow>);
        }


        )}


    </IonGrid>

    </div>);
}

export default MessageContainer;