import React from 'react';
import { IonContent, IonThumbnail, IonSkeletonText, IonItem, IonList, IonListHeader, IonLabel, IonAvatar } from '@ionic/react';
import "./Splash.css";
import PageBase from './PageBase';

const Splash = () => {

	const PageContent = () => {

		return (
			<IonContent >
				<IonList>
					<IonListHeader>
						<IonLabel>
							<IonSkeletonText animated style={{ width: '20%' }} />
						</IonLabel>
					</IonListHeader>
					<IonItem>
						<IonAvatar slot="start">
							<IonSkeletonText animated />
						</IonAvatar>
						<IonLabel>
							<h3>
								<IonSkeletonText animated style={{ width: '50%' }} />
							</h3>
							<p>
								<IonSkeletonText animated style={{ width: '80%' }} />
							</p>
							<p>
								<IonSkeletonText animated style={{ width: '60%' }} />
							</p>
						</IonLabel>
					</IonItem>
					<IonItem>
						<IonThumbnail slot="start">
							<IonSkeletonText animated />
						</IonThumbnail>
						<IonLabel>
							<h3>
								<IonSkeletonText animated style={{ width: '50%' }} />
							</h3>
							<p>
								<IonSkeletonText animated style={{ width: '80%' }} />
							</p>
							<p>
								<IonSkeletonText animated style={{ width: '60%' }} />
							</p>
						</IonLabel>
					</IonItem>
					<IonItem>
						<IonSkeletonText animated style={{ width: '27px', height: '27px' }} slot="start" />
						<IonLabel>
							<h3>
								<IonSkeletonText animated style={{ width: '50%' }} />
							</h3>
							<p>
								<IonSkeletonText animated style={{ width: '80%' }} />
							</p>
							<p>
								<IonSkeletonText animated style={{ width: '60%' }} />
							</p>
						</IonLabel>
					</IonItem>

					<IonItem>
						<IonAvatar slot="start">
							<IonSkeletonText animated />
						</IonAvatar>
						<IonLabel>
							<h3>
								<IonSkeletonText animated style={{ width: '50%' }} />
							</h3>
							<p>
								<IonSkeletonText animated style={{ width: '80%' }} />
							</p>
							<p>
								<IonSkeletonText animated style={{ width: '60%' }} />
							</p>
						</IonLabel>
					</IonItem>
					<IonItem>
						<IonThumbnail slot="start">
							<IonSkeletonText animated />
						</IonThumbnail>
						<IonLabel>
							<h3>
								<IonSkeletonText animated style={{ width: '50%' }} />
							</h3>
							<p>
								<IonSkeletonText animated style={{ width: '80%' }} />
							</p>
							<p>
								<IonSkeletonText animated style={{ width: '60%' }} />
							</p>
						</IonLabel>
					</IonItem>
					<IonItem>
						<IonSkeletonText animated style={{ width: '27px', height: '27px' }} slot="start" />
						<IonLabel>
							<h3>
								<IonSkeletonText animated style={{ width: '50%' }} />
							</h3>
							<p>
								<IonSkeletonText animated style={{ width: '80%' }} />
							</p>
							<p>
								<IonSkeletonText animated style={{ width: '60%' }} />
							</p>
						</IonLabel>
					</IonItem>

					<IonItem>
						<IonAvatar slot="start">
							<IonSkeletonText animated />
						</IonAvatar>
						<IonLabel>
							<h3>
								<IonSkeletonText animated style={{ width: '50%' }} />
							</h3>
							<p>
								<IonSkeletonText animated style={{ width: '80%' }} />
							</p>
							<p>
								<IonSkeletonText animated style={{ width: '60%' }} />
							</p>
						</IonLabel>
					</IonItem>
					<IonItem>
						<IonThumbnail slot="start">
							<IonSkeletonText animated />
						</IonThumbnail>
						<IonLabel>
							<h3>
								<IonSkeletonText animated style={{ width: '50%' }} />
							</h3>
							<p>
								<IonSkeletonText animated style={{ width: '80%' }} />
							</p>
							<p>
								<IonSkeletonText animated style={{ width: '60%' }} />
							</p>
						</IonLabel>
					</IonItem>
					<IonItem>
						<IonSkeletonText animated style={{ width: '27px', height: '27px' }} slot="start" />
						<IonLabel>
							<h3>
								<IonSkeletonText animated style={{ width: '50%' }} />
							</h3>
							<p>
								<IonSkeletonText animated style={{ width: '80%' }} />
							</p>
							<p>
								<IonSkeletonText animated style={{ width: '60%' }} />
							</p>
						</IonLabel>
					</IonItem>
				</IonList>

			</IonContent>
		);
	}

	return (<PageBase GaTrack={false} title="Splash" robots="noindex, nofollow" description="Splash Page" canonical="https://jiujitsux.app/splash" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">
		<PageContent />
	</PageBase>);

}

export default Splash;