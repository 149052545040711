
import React from 'react';

import { IonLabel, IonImg, IonText, IonItem, IonProgressBar } from '@ionic/react';

import Stars from './reviews/Stars';
//import ImageRenderer from '../shared/lazyimage/ImageRenderer';

export default function CourseItemTemplate({ showPrice, item, marginTop = 0, showProgress = false }) {

	return (

		<IonItem lines="none" routerLink={{ pathname: "/home/course-detail", state: { courseId: item.id } }} style={{ marginTop: marginTop, cursor: "pointer" }} >
			{/* <div > */}
			<IonImg position='stacked' src={item.defaultThumbnail} />
			{/* <ImageRenderer
				url={item.defaultThumbnail}
				thumb={item.defaultThumbnail}
			/> */}
			{/* </div> */}
			<IonLabel position='stacked'>{item.title}</IonLabel>
			<IonLabel position='stacked'>{item.author}</IonLabel>


			<IonLabel position='stacked' style={{ marginBottom: showPrice ? 0 : 20 }}>
				<Stars rating={item.rating} reviews={item.reviews} />
			</IonLabel>

			{showPrice &&
				<IonLabel position='stacked' style={{ marginBottom: 10 }}>

					{"$" + item.productDiscountedPrice}
					{item.productDiscountedPrice != item.productPrice &&

						<IonText style={{ fontSize: 14, textDecoration: "line-through", marginTop: 6, marginLeft: 3 }} color="danger" >
							{"$" + item.productPrice}
						</IonText>
					}
				</IonLabel>}

			{showProgress &&
				<div>
					<IonProgressBar color="medium" value={item.progress / 100} style={{ width: 120, height: 8 }} ></IonProgressBar>
					<IonText>{item.progress}%</IonText>
				</div>
			}

		</IonItem>

	);
}
