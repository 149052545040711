import React from 'react';
import { IonItem, IonLabel, IonSkeletonText } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getMaxElements } from '../../../core/Functions';

import 'swiper/css';
import CourseItemTemplateSkeleton from './CourseItemTemplateSkeleton';
import { useAppContext } from '../../shared/AppContext';

export default function CourseSectionSkeleton() {

	const [appState, changeAppState] = useAppContext();

	return (
		<div style={{ marginBottom: 65 }}>
			<IonItem lines="none" >
				<IonLabel>
					<IonSkeletonText animated style={{ width: '20%' }} />
				</IonLabel>
			</IonItem>

			<Swiper
				spaceBetween={0}
				slidesPerView={appState.slidesPerView} >

				{[1, 2, 3, 4, 5].map((item) => {

					return (
						<SwiperSlide key={item}>
							<CourseItemTemplateSkeleton />
						</SwiperSlide>
					);
				})}

			</Swiper>
		</div>
	);



}