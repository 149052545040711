import React, { useState } from "react";
import { IonItem, IonLabel, IonInput, IonTextarea, IonIcon, useIonAlert } from "@ionic/react";
import { checkmark, close, createOutline, trashOutline } from "ionicons/icons";
import { Draggable } from "react-beautiful-dnd";
import { getItemStyle } from "./operations";

const Lesson = ({ topicIndex, lessonIndex, item, onDelete, onSave }) => {

	const [isEditing, setIsEditing] = useState(item.title.includes("New item"));

	const [lessonVimeoId, setLessonVimeoId] = useState(item.vimeoId);
	const [lessonTitle, setLessonTitle] = useState(item.title);
	const [lessonDescription, setLessonDescription] = useState(item.description);
	const [present] = useIonAlert();


	const confirmDelete = () => {
		present({
			header: 'Confirm',
			message: `Do you want delete the lesson <b>${lessonTitle}</b>?`,
			buttons: ['Cancel', { text: 'Ok', handler: (d) => onDelete(topicIndex, lessonIndex) }],
			//onDidDismiss: (e) => console.log('deletion canceled!'),
		});
	}

	const saveIt = () => {
		onSave(topicIndex, lessonIndex, lessonTitle, lessonDescription, lessonVimeoId);
		setIsEditing(false);
	}


	return (
		<Draggable
			key={item.id}
			draggableId={item.id}
			index={lessonIndex}
		>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={getItemStyle(
						snapshot.isDragging,
						provided.draggableProps.style
					)}
				>
					{!isEditing ?
						<div>

							<div style={{ textAlign: "center" }}>
								<button style={{ borderWidth: 2, borderColor: "black", borderRadius: 25, backgroundColor: "transparent" }} onClick={() => confirmDelete()}>
									<IonIcon size="large" slot="icon-only" icon={trashOutline} ></IonIcon>
								</button>
							</div>

							<br />

							<div style={{ textAlign: "center" }}>
								<iframe referrerpolicy="strict-origin-when-cross-origin" title="video" src={`https://player.vimeo.com/video/${item.vimeoId}`} width="320" height="180" frameBorder="0" allow="picture-in-picture"></iframe>
							</div>

							<div style={{ textAlign: "center" }}>
								<h2>{item.title}</h2>
								<p style={{ color: "darkgrey" }} ><i>{item.description ? item.description : "this lesson has no description."}</i></p>
							</div>
							<br />
							<br />

							<div style={{ textAlign: "center" }}>
								<button style={{ borderWidth: 2, borderColor: "black", borderRadius: 25, backgroundColor: "transparent" }} onClick={() => setIsEditing(true)}>
									<IonIcon size="large" slot="icon-only" icon={createOutline} ></IonIcon>
								</button>
							</div>


						</div>

						: <div>

							<IonItem>
								<IonLabel position="stacked">Vimeo Id</IonLabel>
								<IonInput type="text" placeholder="Enter vimeo video Id" value={lessonVimeoId} onIonChange={e => setLessonVimeoId(e.detail.value)} > </IonInput>
							</IonItem>

							<br />
							<IonItem>
								<IonLabel position="stacked">Lesson Title</IonLabel>
								<IonInput maxlength={50} type="text" placeholder="Enter lesson title" value={lessonTitle} onIonChange={e => setLessonTitle(e.detail.value)} > </IonInput>
							</IonItem>
							<br />
							<IonItem>
								<IonLabel position="stacked">Lesson Description</IonLabel>
								<IonTextarea rows={3} autoGrow={true} maxlength={500} type="text" placeholder="Enter lesson description (optional)" value={lessonDescription} onIonChange={e => setLessonDescription(e.detail.value)} > </IonTextarea>
							</IonItem>

							<br />
							<div style={{ textAlign: "center" }}>
								<button style={{ marginRight: 10, borderWidth: 2, borderColor: "black", borderRadius: 25, backgroundColor: "transparent" }} onClick={() => saveIt()}>
									<IonIcon size="large" color="success" slot="icon-only" icon={checkmark} ></IonIcon>
								</button>
								<button style={{ borderWidth: 2, borderColor: "black", borderRadius: 25, backgroundColor: "transparent" }} onClick={() => setIsEditing(false)}>
									<IonIcon size="large" color="danger" slot="icon-only" icon={close} ></IonIcon>
								</button>

							</div>

						</div>
					}
				</div>
			)}
		</Draggable>
	);
}

const Lessons = ({ topicIndex, items, onDeleteLesson, onSaveLesson }) => {

	return items.map((item, index) => (
		<Lesson key={item.id} topicIndex={topicIndex} lessonIndex={index} item={item} onDelete={onDeleteLesson} onSave={onSaveLesson} />
	));

}

export default Lessons;