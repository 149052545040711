import React from 'react';
import { IonLabel } from '@ionic/react';

import AuthorList from './AuthorList';
import AuthorListBig from './AuthorListBig';

const AuthorModule = ({ showTitle, dsLine1, dsLine2, dsLine3 }) => {

	return (<div style={{ marginLeft: 15, marginTop: 40 }}>
		{showTitle && ((dsLine1.length + dsLine2.length + dsLine3.length) > 0) && <IonLabel style={{ marginLeft: 20 }}><h1>Meet the Instructors</h1></IonLabel>}
		<AuthorListBig dataSource={dsLine1} />
		<br />
		<AuthorList dataSource={dsLine2} />
		<AuthorList dataSource={dsLine3} />
	</div>);

}

export default AuthorModule;