import React from 'react';
import { IonLoading, IonList, IonToggle, IonChip, IonButton, IonItem, IonLabel, IonInput, IonIcon, IonCard, IonGrid, IonRow, IonCol, IonProgressBar, useIonRouter } from '@ionic/react';
import { checkmarkDone, documentTextOutline, eye, eyeOff, logIn, mail, person, personAdd } from 'ionicons/icons';

import ReCAPTCHA from 'react-google-recaptcha';
import PageBase from '../shared/PageBase';
import { signUp } from './AuthCore';
import AppConfig from '../../App.config';
import "./auth.css"

import { GATrackSignUp } from '../../core/google';


const SignUp = () => {

	const PageContent = () => {
		const router = useIonRouter();

		const recaptchaRef = React.createRef();

		const [firstname, setFirstname] = React.useState('');
		const [lastname, setLastname] = React.useState('');

		const [username, setUsername] = React.useState('');
		const [password, setPassword] = React.useState('');
		const [loggingIn, setLoggingIn] = React.useState(false);

		const [showPassword, setShowPassword] = React.useState(true);
		const [passwordIcon, setPasswordIcon] = React.useState(eyeOff);

		const [eula, setEULA] = React.useState(false);
		const [terms, setTerms] = React.useState(false);

		const eyePressed = () => {
			setShowPassword(!showPassword);
			setPasswordIcon(showPassword ? eye : eyeOff);
		}

		const handleReCapcha = (token) => {
			recaptchaRef.current.reset();

			signUp({ username, password, firstname, lastname, nickname: "PWA2022" + token })
				.then(data => {

					GATrackSignUp(username);

					window.location.reload(true);
					// router.push({
					// 	pathname: "/home"
					// }, "forward", "push");

				})
				.catch(err => {
					setLoggingIn(false);
				});
		}

		return (<>
			<IonGrid id="auth-grid">
				<IonRow id="auth-row" className="ion-justify-content-center">
					<IonCol id="auth-col" className="ion-align-self-center" size-md="6" size-lg="5" size-xs="12">
						<IonCard >
							<div style={{ textAlign: "center" }} >
								<h4>Sign Up</h4>
								<IonIcon
									style={{ fontSize: "70px" }}
									icon={personAdd}
								/>
							</div>

							<div style={{ padding: 15 }} >
								<IonItem fill="solid" style={{ marginBottom: 10 }}>
									<IonLabel position="stacked">First Name</IonLabel>
									<IonInput
										onChangeText={(fname) => { setFirstname(fname); setLoggingIn(false) }}
										onFocus={() => { setLoggingIn(false) }}
										value={firstname}
										onIonChange={(e) => setFirstname(e.detail.value)} >
									</IonInput>
									<IonIcon slot="end" icon={person} />
								</IonItem>

								<IonItem fill="solid" style={{ marginBottom: 10 }}>
									<IonLabel position="stacked">Last Name</IonLabel>
									<IonInput
										onChangeText={(lname) => { setLastname(lname); setLoggingIn(false) }}
										onFocus={() => { setLoggingIn(false) }}
										value={lastname}
										onIonChange={(e) => setLastname(e.detail.value)} >
									</IonInput>
									<IonIcon slot="end" icon={checkmarkDone} />
								</IonItem>

								<IonItem fill="solid" style={{ marginBottom: 10 }}>
									<IonLabel position="stacked">E-Mail</IonLabel>
									<IonInput
										onChangeText={(email) => { setUsername(email); setLoggingIn(false) }}
										onFocus={() => { setLoggingIn(false) }}
										value={username}

										onIonChange={(e) => setUsername(e.detail.value)} >
									</IonInput>
									<IonIcon slot="end" icon={mail} />
								</IonItem>


								<IonItem fill="solid" style={{ marginBottom: 10 }}>
									<IonLabel position="stacked">Password</IonLabel>
									<IonInput
										type={showPassword ? "password" : "text"}
										onFocus={() => { setLoggingIn(false) }}
										value={password}
										onIonChange={(e) => setPassword(e.detail.value)} >

									</IonInput>

									<IonIcon slot="end" icon={passwordIcon} onClick={eyePressed} />
								</IonItem>

							</div>

							<IonItem lines="none">
								<a color='secondary' rel="noreferrer" target="_blank" href="https://jiujitsux.com/legal-mobile-eula/">

									<IonChip outline color="secondary">
										<IonIcon icon={documentTextOutline} />
										<IonLabel>Do you agree with the Jiu Jitsu X EULA?</IonLabel>
									</IonChip>
								</a>
								<IonToggle id="eulaToggle" slot="end" checked={eula} onIonChange={(e) => { setEULA(!eula); e.preventDefault(); }}></IonToggle>
							</IonItem>

							<IonItem lines="none">
								<a color='secondary' rel="noreferrer" target="_blank" href="https://jiujitsux.com/terms-and-conditions/">

									<IonChip outline color="secondary">
										<IonIcon icon={documentTextOutline} />
										<IonLabel>Do you agree with Jiu Jitsu X Terms?</IonLabel>
									</IonChip>
								</a>
								<IonToggle id="termsToggle" slot="end" checked={terms} onIonChange={(e) => { setTerms(!terms); e.preventDefault(); }}></IonToggle>
							</IonItem>

							<div style={{ paddingLeft: 15, paddingRight: 15 }}>

								<IonButton id="auth-button" disabled={!(eula && terms && username && password && firstname && lastname)} size="large" expand="block" onClick={() => {
									if (eula && terms && username && password && firstname && lastname && username.trim() != '' && password.trim() != '' && firstname.trim() != '' && lastname.trim() != '') {

										if (password.includes('=')
											|| password.includes('"')
											|| password.includes("'")
											|| password.includes(",")
											|| password.includes("*")
											|| password.includes('?')
											|| password.includes('&')
											|| password.includes('/')
											|| password.includes('\\')) {
											alert("Password cannot contain: * ? & = / \\ \" ' ,");
										}
										else {
											setLoggingIn(true);
											recaptchaRef.current.execute();
										}


									}
									else {
										alert("Please, inform all Fields, Read and Agree with the Terms of Use.");
									}
								}

								}>
									Register
									<IonIcon icon={logIn} slot="end" />
								</IonButton>
							</div>

							<div style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: 15 }}>
								{loggingIn && <IonProgressBar type="indeterminate"></IonProgressBar>}
							</div>

						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>

			<ReCAPTCHA
				style={{ innerHeight: "100%", innerWidth: "100%" }}
				ref={recaptchaRef}
				size="invisible"
				sitekey={AppConfig.Google.ReCapchaKey}
				onChange={handleReCapcha}
			/>

			<IonLoading
				isOpen={loggingIn}
				onDidDismiss={() => setLoggingIn(false)}
				message={'Please wait...'}
				duration={6000}
			/>

		</>);
	}

	return (<PageBase title='Sign Up' showButtons={false} robots="noindex, nofollow" description="Create your account and join to our community" canonical="https://jiujitsux.app/dashboard/register" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">
		<PageContent />
	</PageBase>);


}

export default SignUp;
