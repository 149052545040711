import React from 'react';

import { breakInChuncks } from '../../../core/Functions';

import CourseSectionMultiple from '../../courses/CourseSectionMultiple';


import {
	useLocation
} from "react-router-dom";

import PageBase from '../../shared/PageBase';
import { getCoursesFromCategory } from '../../../core/backend/GQLQueries';
import Splash from '../../shared/Splash';
import Error from '../../shared/Error';
import { GATrackOpenedCategory } from '../../../core/google';

const useParams = () => {
	const location = useLocation();

	let title = "";
	let id = 0;
	let query;

	try {
		//Query String
		if (location.search) {
			query = new URLSearchParams(location.search);
		}

		//Id
		if (location.state && location.state.id)
			id = location.state.id;
		else
			id = query.get("id");

		//Title
		if (location.state && location.state.title)
			title = location.state.title;
		else
			title = query.get("title");

		return { id: Number(id), title };
	}
	catch
	{
		return { id: Number(id), title };
	}
}


export default function Category() {

	let title = "";
	let id = 0;

	({ id, title } = useParams());

	const [state, setState] = React.useState({
		dataStatus: { called: true, loading: true, error: false },
		dataSource: []
	});

	const loadCourses = (abortController) => {

		getCoursesFromCategory(id, abortController).then(result => {

			GATrackOpenedCategory(id, title);

			setState(
				{
					dataStatus: { called: false, loading: false, error: false },
					dataSource: result.data.coursesFromCategory
				}
			);

		}).catch(err => {
			if (err) {
				setState(
					{
						dataStatus: { called: true, loading: false, error: true },
						dataSource: []
					}
				);
			}
		});
	}

	React.useEffect(() => {

		let abortController = new AbortController();

		if (id && id > 0)
			loadCourses(abortController);

		return () => {
			abortController.abort();
		}

	}, [id]);

	if (state.dataStatus.called && state.dataStatus.loading) return <Splash />;
	if (state.dataStatus.called && state.dataStatus.error) return <Error />;

	return (<PageBase title={title} robots="index, follow" description={state.dataSource.length + " courses about " + title} canonical="https://jiujitsux.app/home/course-by-category" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">
		<CourseSectionMultiple title={title} courses={breakInChuncks(2, state.dataSource)} horizontal={true} showPrice={true} refreshing={false} />
	</PageBase>);
}
