import React from "react";
import { IonApp, setupIonicReact } from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { AppContextProvider } from "./components/shared/AppContext";

import Main from "./Main";
import { setMaxElements } from "./core/Functions";

import { AppPlayerContextProvider } from "./components/shared/AppPlayerContext";

setupIonicReact();

const App: React.FC = () => {
  const handleResize = () => {
    return setMaxElements();
  };

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.addEventListener("resize", handleResize);
      window.addEventListener("load", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", handleResize);
      isMounted = false;
    };
  }, []);

  return (
    <AppPlayerContextProvider>
      <AppContextProvider>
        <IonApp>
          <Main />
        </IonApp>
      </AppContextProvider>
    </AppPlayerContextProvider>
  );
};

export default App;
