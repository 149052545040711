export default {
	AppHome: process.env.REACT_APP_HOME,
	LegionAPI: process.env.REACT_APP_LEGION_API_BASE_URL,
	BackendEndpoint: process.env.REACT_APP_BACKEND,
	ChatEndpoint: process.env.REACT_APP_CHAT,
	ChatLogLevel: process.env.REACT_APP_CHAT_LOG_LEVEL,

	Google: {
		ReCapchaKey: process.env.REACT_APP_GOOGLE_RECAP,
		GATrackingCode: process.env.REACT_APP_GOOGLE_GA_ID || '',
		GTMId: process.env.REACT_APP_GOOGLE_GTM_ID || '',
		GATrackingTransport: "beacon", // beacon/xhr/image
		ConversionCurrency: "USD"

	},

	Stripe: {
		PurchaseSuccessFallBack: process.env.REACT_APP_HOME + process.env.REACT_APP_PURCHASE_SUCCESS,
		PurchaseFailFallBack: process.env.REACT_APP_HOME + process.env.REACT_APP_PURCHASE_FAIL,
	},

	Parameters: {
		UpdateLibraryTimeSpan: 1,
		UpdateCategoriesTimeSpan: 168
	}


}