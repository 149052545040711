import React from 'react';
import { IonChip, IonLabel } from '@ionic/react';
import { Link } from 'react-router-dom';
import { GATrackExplodeCategoryChip } from '../../../core/google';


const CategoryItem = ({ item }) => {

	return (
		<Link style={{ textDecoration: 'none', fontSize: 12 }} onClick={() => GATrackExplodeCategoryChip(item.id, item.name)} to={
			{
				pathname: "/home/course-by-category",
				state: {
					title: item.name, id: item.id
				}
			}
		} >


			<IonChip color="medium">
				<IonLabel>{item.name}</IonLabel>
			</IonChip>
		</Link>
	);
}

export default CategoryItem;