import React from 'react';

import { IonLoading, useIonAlert, IonContent, IonHeader, IonToolbar, IonTitle, IonModal, IonGrid, IonCol, IonRow, IonLabel, IonButton, IonIcon, IonChip, IonList, IonItem, IonToggle, IonInput, IonText } from '@ionic/react';

import { logOut, helpCircleOutline, helpBuoyOutline, hammerOutline, moon, sunny } from 'ionicons/icons';

import { useAppContext } from '../../shared/AppContext';
import { loggedUserData, signOut } from '../../auth/AuthCore';
import { useGetPartner } from '../../../core/hooks';
import { setRequestPartnerApproval } from '../../../core/backend/GQLMutations';
import { getMyAffiliateLink, getMyAffiliateCode } from '../../../core/Functions';

export default function AccountTab() {
	const [showLoading, setShowLoading] = React.useState(false);

	const [presentAlert] = useIonAlert();


	const modal = React.useRef(null);
	const input = React.useRef(null);

	const [socialMediaLink, setSocialMediaLink] = React.useState('');

	function confirm() {
		modal.current?.dismiss(input.current?.value, 'confirm');
	}

	function onWillDismiss(ev) {

		if (ev.detail.role === 'confirm') {
			setShowLoading(true);

			setSocialMediaLink(ev.detail.data);

			setRequestPartnerApproval(ev.detail.data).then(result => {

				if (!result.success) {
					if (process.env.NODE_ENV === 'development')
						console.log(result.data);
				}
				else {
					presentAlert({
						header: 'Jiu Jitsu X',
						subHeader: 'Important message',
						message: 'Your application was submited with success!',
						buttons: ['OK'],
					});
				}

				setShowLoading(false);

			}).catch(e => {
				if (process.env.NODE_ENV === 'development') console.log(e);
				setShowLoading(false);
			});
		}
	}

	const partnerCookie = useGetPartner();

	const userData = loggedUserData();

	const [appState, changeAppState] = useAppContext();

	const myLink = getMyAffiliateLink();
	const myCode = getMyAffiliateCode();

	return (<>
		<IonModal ref={modal} trigger="open-modal" onWillDismiss={(ev) => onWillDismiss(ev)}>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Affiliate Marketer Application</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<IonItem>
					<IonText>
						<h6>
							This information is entirely optional however, providing it to us could possibly help you sell more.
						</h6>
					</IonText>
				</IonItem>
				<br />
				<IonItem lines='none'>
					<IonLabel position="stacked">Enter one of yours social media platform link</IonLabel>
					<IonInput ref={input} type="text" placeholder="Social Media Profile Link" />
				</IonItem>
				<IonItem style={{ marginTop: 50 }} lines='none'>
					<IonButton size='large' color="medium" slot='end' onClick={() => modal.current?.dismiss()}>Cancel</IonButton>
					<IonButton size='large' color="secondary" slot='end' strong={true} onClick={() => confirm()}>
						Apply
					</IonButton>

				</IonItem>

			</IonContent>
		</IonModal>

		<IonGrid>

			{!appState.isIOS && <IonList>
				<IonItem lines="full"></IonItem>
				<IonItem lines="full">
					<IonIcon slot="start" icon={appState.isThemeDark ? sunny : moon}></IonIcon>
					<IonLabel>
						{`Toggle ${appState.isThemeDark ? "Dark" : "Light"} Theme`}
					</IonLabel>
					<IonToggle id="themeToggle" slot="end" checked={appState.isThemeDark}
						onIonChange={() => changeAppState(
							{
								...appState,
								isThemeDark: !appState.isThemeDark
							}


						)}></IonToggle>
				</IonItem>
			</IonList>}

			<IonRow class='ion-justify-content-center'>

				<IonCol class='ion-justify-content-center' >
					<a color='secondary' rel="noreferrer" target="_blank" href="https://www.canva.com/design/DAEwzc5jxss/L9ff1iXvkocCtSNV2UvL4w/view?utm_content=DAEwzc5jxss&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton">

						<IonChip outline color="secondary">
							<IonIcon icon={helpBuoyOutline} />
							<IonLabel>App Help</IonLabel>
						</IonChip>
					</a>

				</IonCol>
			</IonRow>
			<IonRow class='ion-justify-content-center'>

				<IonCol class='ion-justify-content-center' >


					<a target="_blank" rel="noreferrer" href="https://jiujitsux.com/app-faq/">

						<IonChip outline color="primary">
							<IonIcon icon={helpCircleOutline} />
							<IonLabel>FAQ</IonLabel>
						</IonChip>
					</a>

				</IonCol>
			</IonRow>
			<IonRow class='ion-justify-content-center'>

				<IonCol class='ion-justify-content-center' >


					<a target="_blank" rel="noreferrer" href="https://jiujitsux.com/submit-a-ticket/">

						<IonChip outline color="primary">
							<IonIcon icon={hammerOutline} />
							<IonLabel>Tec Support</IonLabel>
						</IonChip>

					</a>

				</IonCol>
			</IonRow>

			<IonRow class='ion-justify-content-center'>
				<IonCol class='ion-justify-content-center' >
					<IonLabel position='stacked' style={{ borderWidth: 1, borderStyle: 'dashed', borderColor: 'silver', paddingStart: 5, paddingEnd: 5, marginTop: 40, alignSelf: 'center' }}>Logged User: {userData.userEmail}</IonLabel>
				</IonCol>
			</IonRow>

			<IonRow class='ion-justify-content-center'>

				<IonCol class='ion-justify-content-center' >
					<IonButton
						size="large"
						expand="block"
						onClick={() => signOut().then(data => {
							window.location.reload(true);

						})}>
						Sign out
						<IonIcon icon={logOut} slot="end" />
					</IonButton>

				</IonCol>
			</IonRow>

			<IonRow class='ion-justify-content-center'>
				<IonCol class='ion-justify-content-center' >
					<div style={{ textAlign: "right" }}>
						<h6>Referral User on File: <i><b>{userData.referralOfName}</b></i></h6>
						<h6>Referral User on Cookies: <i><b>{partnerCookie}</b></i> </h6>

						{!myCode || myCode == "NONE"
							? <IonButton id="open-modal" color="secondary">Become an Affiliate Marketer</IonButton>
							: <div>
								<h6>My Affiliate Marketer Link: <i><b>{myLink}</b></i> <button onClick={() => navigator.clipboard.writeText(myLink)}>Copy</button> </h6>
								<h6>My Affiliate Marketer Code: <i><b>{myCode}</b></i> <button onClick={() => navigator.clipboard.writeText(myCode)}>Copy</button> </h6>
							</div>
						}
					</div>

				</IonCol>
			</IonRow>
		</IonGrid >

		<IonLoading
			isOpen={showLoading}
			onDidDismiss={() => setShowLoading(false)}
			message={'Please wait...'}
			duration={5000}
		/>

	</>);
}