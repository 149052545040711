import { gql } from 'graphql-request'
import { executeGQL } from './GQClient';


export const getAuthor = async (email, abortController = undefined) => {
	return await executeGQL(gql`query author($email: String!) {
        author(email: $email) { id, name, pictureSmall } }`, { email: email }, undefined, abortController);
}


export const getCourseDraft = async (id, abortController = undefined) => {
	return await executeGQL(gql`query courseDraft($id: Long!) {
        courseDraft(id: $id) { basicInfo, importContent, courseContent } }`, { id: id }, undefined, abortController);
}


export const getListAllCourseDrafts = async (abortController = undefined) => {
	return await executeGQL(gql`query listAllCourseDrafts {
        listAllCourseDrafts { label, value } }`, {}, undefined, abortController);
}


export const getCombineScriptAndVimeoProject = async (script, projectId, abortController = undefined) => {
	return await executeGQL(gql`query {
        combineScriptAndVimeoProject(script: "${script}", projectId: "${projectId}" ) {title, description, lessons {id, title, description, vimeoId, duration} } }`, {}, undefined, abortController);
}


export const getCourseFreeLessons = async (courseId = 0, abortController = undefined) => {
	if (!courseId)
		courseId = 0;

	return await executeGQL(gql`query {
        courseFreeLessons(courseId: ${courseId}) {id, title, isPreview, ignoreOnPlayer, video, defaultPlayUrl, download, thumbnail, duration, vimeoId } }`, {}, undefined, abortController);
}

export const getAllReviews = async (courseId = 0, abortController = undefined) => {
	if (!courseId)
		courseId = 0;

	return await executeGQL(gql`query courseReviews ($courseId: Long!) {
            courseReviews(courseId: $courseId) {rating, totalReviews, fiveStars, fourStars, threeStars, twoStars, oneStars, reviews {author, date, comment, rating, courseId} }	}`, { courseId: courseId }, undefined, abortController);
}

export const getSiteReviews = async (abortController = undefined) => {

	return await executeGQL(gql`query allReviews {
				allReviews {rating, totalReviews, fiveStars, fourStars, threeStars, twoStars, oneStars, reviews {author, date, comment, rating, courseId}, myReview {author, date, comment, rating, courseId}}
			}`, {}, undefined, abortController);
}

export const getAuthorReviews = async (sid = 0, abortController = undefined) => {
	if (!sid)
		sid = 0;

	return await executeGQL(gql`query authorReviews ($authorId: Long!) {
				authorReviews(authorId: $authorId) {rating, totalReviews, fiveStars, fourStars, threeStars, twoStars, oneStars, reviews {author, date, comment, rating, courseId}, myReview {author, date, comment, rating, courseId}}
			}`, { authorId: sid }, undefined, abortController);
}

export const getAuthorDetails = async (sid = 0, abortController = undefined) => {
	if (!sid)
		sid = 0;

	return await executeGQL(gql`query authorDetails ($id: Long!) {
				authorDetails(id: $id) {id, name, bio, pictureMedium, rating, reviews, students, reviewsSummary { rating, totalReviews, fiveStars, fourStars, threeStars, twoStars, oneStars, reviews { author, date, comment, rating, courseId }, myReview { author, date, comment, rating, courseId } }}
			}`, { id: sid }, undefined, abortController);
}

export const getWholeLibrary = async (enteredFilter, bringJustCourses, abortController = undefined) => {
	return await executeGQL(gql`query wholeLibrary ($filter: String!, $justCourses: Boolean!) {
			wholeLibrary(filter: $filter, justCourses: $justCourses) {
          		categories { id, name },  
          		courses { id, title, isMine, author, rating, reviews, productPrice, productDiscountedPrice, defaultThumbnail },
          		courseCategory { categoryId, courseId }
			}
		}`, { filter: enteredFilter, justCourses: bringJustCourses }, undefined, abortController);
}


export const getSaleById = async (id = 0, abortController = undefined) => {
	if (!id)
		id = 0;

	return await executeGQL(gql`query saleById {
        saleById(id: "${id}") { id, origin, date, userId, courseId, author, category, price, status, customerId, paymentId, sessionId, email, password, isAuthenticated, expiredAutoLogin } }`, {}, undefined, abortController);
}

export const getAllPlayLists = async (abortController = undefined) => {
	return await executeGQL(gql`query allPlayLists {
        allPlayLists {id, name } }`, {}, undefined, abortController);
}

export const getAllPlayListLessons = async (playListId = 0, abortController = undefined) => {
	if (!playListId)
		playListId = 0;

	return await executeGQL(gql`query allPlayListLessons { allPlayListLessons(playListId: ${playListId}) {id, title, children {id, title, isPreview, ignoreOnPlayer, video, defaultPlayUrl, download, thumbnail, duration, vimeoId}} }`, {}, undefined, abortController);
}

export const getUploadAgreement = async (abortController = undefined) => {
	return await executeGQL(gql`query isUploaderAgreementValid {
        isUploaderAgreementValid }`, {}, undefined, abortController);
}

export const getDDLTopics = async (courseId = 0, abortController = undefined) => {
	if (!courseId)
		courseId = 0;

	return await executeGQL(gql`query courseTopics($courseId: Long!) {
        courseTopics(courseId: $courseId) {label, value} }`, { variables: { courseId: courseId } }, undefined, abortController);
}

export const getDDLMyCourses = async (abortController = undefined) => {
	return await executeGQL(gql`query listUserAuthorshipCourses {
	        listUserAuthorshipCourses {label, value} }`, {}, undefined, abortController);
}

export const getCourseAndAuthor = async (inputData, abortController = undefined) => {
	return await executeGQL(gql`query courseAndAuthor($inputData: String!) {
        courseAndAuthor(inputData: $inputData) }`, { variables: { inputData: inputData } }, undefined, abortController);
}

export const getUserCourses = async (userEmail, abortController = undefined) => {
	return await executeGQL(gql`query listCoursesAndPermissions($userEmail: String!) {
        listCoursesAndPermissions(userEmail: $userEmail) {courseId, courseThumbnail, hasAccess} }`, { variables: { userEmail: userEmail } }, undefined, abortController);
}



export const getExpenses = async (beginDate, endDate, abortController = undefined) => {
	return await executeGQL(gql`query expenses($filter: ExpenseFilterInput! ) {
					expenses(filter: $filter) {id, date, instructor {displayName}, category {name}, description, amount} }`, { filter: { beginDate: beginDate, endDate: endDate } }, undefined, abortController);
}


export const getDDLs = async (abortController = undefined) => {
	return await executeGQL(gql`query ddls {
					allInstructors {value, label}
  					allExpensesCategories {value, label}}`, {}, undefined, abortController);
}


export const getEarnings = async (abortController = undefined) => {
	return await executeGQL(gql`query myPayOuts {
					myPayOuts {month, amount, status} }`, {}, undefined, abortController);
}

export const getBookmark = async (filter, abortController = undefined) => {
	return await executeGQL(gql`query tutorFavorites {
        tutorFavorites {id, key, title, excerpt, description, vimeoId, defaultPlayThumbnail, defaultPlayUrl, defaultDownloadUrl, videoDuration, isFavorite} }`, {}, undefined, abortController);
}

export const getFilteredCourses = async (filter, abortController = undefined) => {
	return await executeGQL(gql`query allCourses ($filter: String!) {
			filterCoursesByCategory(filter: $filter) {id, title, courses {id, title, isMine, author, rating, productPrice, productDiscountedPrice, defaultThumbnail}} }`, { filter: filter }, undefined, abortController);
}


export const getFreeContent = async (onlymine, abortController = undefined) => {
	return await executeGQL(gql`query freeContent ($onlyMine: Boolean!) {
		freeContent(onlyMine: $onlyMine) {courseId, lessonTitle, courseTitle, defaultThumbnail, defaultPlayUrl, coursePrice, courseDiscountedPrice} }`, { onlyMine: onlymine }, undefined, abortController);
}


export const getPriceTier = async (abortController = undefined) => {
	return await executeGQL(gql`query storePriceTiers {
		storePriceTiers }`, {}, undefined, abortController);
}

export const getEulaAndGeneral = async (appVersion, abortController = undefined) => {

	return await executeGQL(gql`
	query isEulaAndGeneralValid {
        subscriptionStatusChanged,
		isEulaAndGeneralValid,		
		storePriceTiers,
		versionAndAlerts(appVersion: ${appVersion})
    }`, {}, undefined, abortController);
}

export const getWholeLibraryCategories = async (enteredFilter, abortController = undefined) => {
	return await executeGQL(gql`query allCategories ($filter: String!) { wholeLibraryCategories(filter: $filter) {id, name} }`, { filter: enteredFilter }, undefined, abortController);
}

export const getCoursesFromCategory = async (id = 0, abortController = undefined) => {
	if (!id)
		id = 0;

	return await executeGQL(gql`query coursesFromCategory ($categoryId: Long!) {
				coursesFromCategory(categoryId: $categoryId) {id, title, isMine, authorId, author, rating, reviews, productPrice, productDiscountedPrice, defaultThumbnail}				
			}`, { categoryId: id }, undefined, abortController);
}

export const getUserSuggestions = async (stype, sid = 0, abortController = undefined) => {
	if (!sid)
		sid = 0;

	return await executeGQL(gql`query userSuggestions ($type: String!, $id: Long!) {
				userSuggestions(type: $type, id: $id) {id, title, lines, shuffle, addCategories, courses {id, title, isMine, authorId, author, rating, reviews, productPrice, productDiscountedPrice, defaultThumbnail}}
			}`, { type: stype, id: sid }, undefined, abortController);
}


export const getHomeCourses = async (enteredFilter, abortController = undefined) => {
	return await executeGQL(gql`query allCourses ($filter: String!) {
				allLibraryCourses (filter: $filter) {id, title, isMine, authorId, author, rating, reviews, productPrice, progress, productDiscountedPrice, defaultThumbnail},
				lightHomeData(filter: $filter) {id, title, lines, shuffle, addCategories, addAuthors, showPrice, showProgress, coursesIds},
				wholeLibraryCategories(filter: $filter) {id, name},
				wholeLibraryAuthors(filter: $filter) {id, name, pictureSmall},
				promoEndTime,
				promoBanner
			}`, { filter: enteredFilter }, undefined, abortController);
}

export const getCourses = async (courseDivision, abortController = undefined) => {
	return await executeGQL(gql`query courses { ${courseDivision} {id, key, title, author, excerpt, productId, defaultThumbnail, rating, productPrice, productDiscountedPrice, progress } }`, {}, undefined, abortController);
}

export const getCourseDetail = async (courseId = 0, postId = "00000000000000000000", abortController = undefined) => {

	if (!courseId)
		courseId = 0;

	return await executeGQL(gql`query newCourseDetails($courseId: Long!, $postId: String!) {
    newCourseDetails(courseId: $courseId, postId: $postId)  {
        courseId, 
		hasFreeCourse,
				title ,
				excerpt, 
				description,
				author,
				authorId,
				productId ,
				price ,
				discountedPrice,
				defaultThumbnail, 
				defaultVideo ,
				isMyCourse,
				isPromo,
				promoHoursLeft,
				lastUpdate,
				rating,
				reviews,
				benefits,
				targetAudience,
				totalEnrolls,
				extraField1,
				extraField3,				
				reviewsSummary {rating, totalReviews, fiveStars, fourStars, threeStars, twoStars, oneStars, reviews {author, date, comment, rating, courseId}, myReview {author, date, comment, rating, courseId} }
  	} }`, { courseId: courseId, postId: postId }, undefined, abortController);
}

export const getCourseCurriculum = async (courseId = 0, isMine, abortController = undefined) => {
	if (!courseId)
		courseId = 0;

	return await executeGQL(gql`query courseCurriculum($courseId: Long!, $isMine: Boolean!) {
    courseCurriculum(courseId: $courseId, isMine: $isMine)  { id, title, children {id, title, isPreview, ignoreOnPlayer, video, defaultPlayUrl, download, thumbnail, duration, vimeoId, watchPercent } }
  	}`, { courseId: courseId, isMine: isMine }, undefined, abortController);
}