
import React from 'react';
import PageBase from '../shared/PageBase';

import { getSaleById } from '../../core/backend/GQLQueries';
import { useIonRouter } from '@ionic/react';

import { useLocation } from "react-router-dom";

import { GATrackPurchaseComplete } from '../../core/google';
import AppConfig from '../../App.config';


const useParams = () => {
	const location = useLocation();

	if (location.search) {
		const params = new URLSearchParams(location.search);
		return {
			session: params.get("session"),
			courseId: params.get("courseId"),
			title: params.get("courseTitle"),
			value: params.get("value")
		};
	}
	else
		return {
			session: "0",
			courseId: 0,
			title: "no-title",
			value: 0
		};
}

export default function PurchaseSuccess() {

	const router = useIonRouter();
	const params = useParams();

	const getData = (abortController) => {

		getSaleById(params.session, abortController)
			.then(result => {

				const last = localStorage.getItem("last_purchase");

				if (!last || last !== result.data.saleById.id) {
					GATrackPurchaseComplete(result.data.saleById.id,
						result.data.saleById.price,
						result.data.saleById.courseId,
						params.title,
						result.data.saleById.author,
						result.data.saleById.category);

					localStorage.setItem("last_purchase", result.data.saleById.id);
				}

				router.push({
					pathname: "/home/thank-you", state:
					{
						data: result.data.saleById
					}
				}, "forward", "push");
			})
			.catch(err => { });
	}


	React.useEffect(() => {

		let abortController = new AbortController();

		let myTimeout = setTimeout(function () {
			getData(abortController);
		}, 7000);

		return () => {
			clearTimeout(myTimeout);
			abortController.abort();
		}

	}, []);

	return (<PageBase title="Purchase Finishing Transaction" robots="noindex, nofollow" description="Purchase feedback - Success" canonical={AppConfig.Stripe.PurchaseSuccessFallBack} og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">
		<div style={{ textAlign: "center" }}>
			<br />
			<img alt="Processing Transaction" src="https://jiujitsux.com/wp-content/uploads/2022/06/wait_transaction.gif" />
			<h1>We are Processing your Purchase.</h1>
			<h2>Please wait...</h2>
		</div>
	</PageBase>);

}