
import React from 'react';

import ReviewList from './ReviewList';
import RatingSummary from './RatingSummary';
import { IonLabel, IonItem, IonGrid, IonRow, IonCol } from '@ionic/react';

export default function ReviewSummary({ onShowAllReviews, summary, showAllReviews, title = "Course Rating", showLink = false }) {


	if (!summary || summary.reviews.length < 1)
		return null;

	return (<div style={{ marginLeft: 20 }}>
		<IonLabel><h1>Student Feedback</h1></IonLabel>
		<RatingSummary title={title} summary={summary} />
		<ReviewList onShowAll={onShowAllReviews} summary={summary} showAll={showAllReviews} showLink={showLink} />
	</div>);
}