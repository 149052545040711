import React from 'react';
import { IonLabel, IonItem, IonInput, IonIcon } from '@ionic/react';
import { searchOutline } from 'ionicons/icons';
import PageBase from '../../shared/PageBase';
import { GATrackSearch } from '../../../core/google';
import { useSearch } from '../../shared/hooks';
import SearchLazy from './SearchLazy';

export default function Home() {

	const initialTerm = useSearch();

	const handleOnNewSearch = (term) => {
		GATrackSearch(term);
		if (window.history.replaceState) {
			var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?search=' + term;
			window.history.replaceState({ path: newurl }, '', newurl);
			window.location.reload(true);
		}
	}

	const InputTerm = ({ initialTerm, onNewSearch }) => {
		const [queryBox, setQueryBox] = React.useState(initialTerm);

		return (<IonItem fill="solid" >
			<IonLabel position="stacked">What are you looking for</IonLabel>
			<IonInput
				value={queryBox}
				onIonChange={(e) => { setQueryBox(e.detail.value) }}
				onKeyUp={(e) => e.key == 'Enter' && onNewSearch(queryBox)} >
			</IonInput>
			<IonIcon slot="end" icon={searchOutline} onClick={() => onNewSearch(queryBox)} />
		</IonItem>
		);
	}

	const PageContent = () => (<>
		<InputTerm initialTerm={initialTerm} onNewSearch={handleOnNewSearch} />
		<SearchLazy />
	</>);

	return (<PageBase title='Search' robots="index, follow" description="Look for your favorite Intructor, Course or Technique" canonical="https://jiujitsux.app/search" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">
		<PageContent />
	</PageBase>);

}
