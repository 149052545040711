import React from 'react';
import { IonChip, IonLabel, IonAvatar, IonItem, IonCard, IonButton } from '@ionic/react';
import { Link } from 'react-router-dom';
import { GATrackExplodeAuthorChip } from '../../../core/google';


const AuthorItemBig = ({ item }) => {

	return (<div style={{ textAlign: "center" }}>
		<Link style={{ textDecoration: 'none', fontSize: 12 }} onClick={() => GATrackExplodeAuthorChip(item.id, item.name)} to={
			{
				pathname: "/home/author-bio",
				state: {
					name: item.name, id: item.id
				}
			}
		} >

			<IonAvatar style={{ marginTop: 10, marginLeft: 33 }}>
				<img src={item.pictureSmall} />
			</IonAvatar>
			<IonLabel>
				{item.name}
			</IonLabel>


		</Link>
	</div>
	);
}

export default AuthorItemBig;