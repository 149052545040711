
import React from 'react';
import PageBase from '../shared/PageBase';

import { IonIcon, IonButton } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';

import { useLocation } from "react-router-dom";

import { GATrackPurchaseCanceled } from '../../core/google';
import AppConfig from '../../App.config';




const useParams = () => {
	const location = useLocation();

	if (location.search) {
		const params = new URLSearchParams(location.search);
		return {
			id: params.get("courseId"),
			title: params.get("courseTitle"),
			value: params.get("value")
		};
	}
	else
		return {
			id: 0,
			title: "no-title",
			value: 0
		};
}

export default function PurchaseFail() {

	const params = useParams();

	React.useEffect(() => {
		//TODO: Diff
		GATrackPurchaseCanceled(params.id, params.title, params.value);
	}, []);

	const PageContent = () => {
		return (

			<div style={{ textAlign: "center" }}>
				<br />
				<img style={{ width: 200 }} alt="[X] ERROR [X]" src="https://jiujitsux.com/wp-content/uploads/2022/06/error.png" />
				<h1>Purchase Aborted</h1>
				<br />
				<IonButton size="large" routerLink={{ pathname: "/home/course-detail", state: { courseId: params.id } }}>
					Go back to Course page
					<IonIcon icon={arrowBack} slot="end" />
				</IonButton>
			</div>);
	}

	return (<PageBase title={`Purchase Fail : ${params.title}`} robots="noindex, nofollow" description="Purchase feedback - Failure" canonical={AppConfig.Stripe.PurchaseFailFallBack} og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo" >
		<PageContent />
	</PageBase>);

}