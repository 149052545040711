
import React from 'react';

import Stars from './Stars';

import { IonButton, IonLabel, IonChip } from '@ionic/react';

import { Link } from 'react-router-dom';

export default function ReviewList({ onShowAll, summary, showAll, showLink = false }) {


	const ReviewItem = ({ item }) => {
		return (<div style={{ marginLeft: 10 }}>
			<IonLabel style={{ fontWeight: "bolder" }}>{item.author}</IonLabel>
			<br />

			<IonLabel>
				<h5>
					<Stars rating={item.rating} reviews={item.reviews} />
					{item.date}
				</h5>
			</IonLabel>

			<IonLabel ><p>{item.comment}
				{showLink && <Link style={{ textDecoration: 'none' }} to={
					{
						pathname: "/home/course-detail",
						state: { courseId: item.courseId }
					}
				} >

					<IonChip color="medium">
						See Course...
					</IonChip>

				</Link>}
			</p></IonLabel>

			<br />
		</div>);
	}

	const ShowList = () => {
		return (
			summary.reviews.map((item, index) => {
				return (
					<ReviewItem key={index} item={item} />
				)
			})
		);
	}

	if (!summary)
		return null;

	return (<>
		<ShowList />
		{!showAll && <IonButton style={{ width: "96%" }} onClick={() => onShowAll()}>See all</IonButton>}
	</>);
}