import React from "react";

import {
  IonSpinner,
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonLabel,
  IonIcon,
} from "@ionic/react";

import {
  home,
  helpBuoyOutline,
  personAdd,
  searchOutline,
  helpCircleOutline,
  hammerOutline,
  pricetag,
  starHalfOutline,
} from "ionicons/icons";
import { Suspense } from "react";

const DynamicMenu = React.lazy(() => import("./components/DynamicMenu"));

export const Menu = () => {
  return (
    <IonMenu side="start" contentId="main">
      <IonHeader>
        <IonToolbar color="light">
          <IonTitle>JJX Jan-07</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonMenuToggle auto-hide="false">
            <IonItem button routerLink={"/home"} routerDirection="none">
              <IonIcon slot="end" ios={home} md={home}></IonIcon>
              <IonLabel>Home</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle auto-hide="false">
            <IonItem button routerLink={"/search"} routerDirection="none">
              <IonIcon
                slot="end"
                ios={searchOutline}
                md={searchOutline}
              ></IonIcon>
              <IonLabel>Search</IonLabel>
            </IonItem>

            <IonItem button routerLink={"/dashboard"} routerDirection="none">
              <IonIcon slot="end" ios={personAdd} md={personAdd}></IonIcon>
              <IonLabel>Sign Up</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonItem></IonItem>

          <IonMenuToggle auto-hide="false">
            <IonItem
              button
              routerLink={
                "/home/all-reviews?isAllFromAuthor=true&id=-1&title=WHOLE-SITE-REVIEWS"
              }
              routerDirection="none"
            >
              <IonIcon
                color="primary"
                slot="end"
                ios={starHalfOutline}
                md={starHalfOutline}
              ></IonIcon>
              <IonLabel color="primary">Read the Reviews!</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonItem></IonItem>

          <IonMenuToggle auto-hide="false">
            <Suspense
              fallback={<IonSpinner name="bubbles" color="secondary" />}
            >
              <DynamicMenu name="Categories" />
            </Suspense>
          </IonMenuToggle>

          <IonItem></IonItem>
          <IonMenuToggle auto-hide="false">
            <IonItem
              href="https://www.canva.com/design/DAEwzc5jxss/L9ff1iXvkocCtSNV2UvL4w/view?utm_content=DAEwzc5jxss&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton"
              target="_blank"
              routerDirection="none"
            >
              <IonIcon
                slot="end"
                ios={helpBuoyOutline}
                md={helpBuoyOutline}
              ></IonIcon>
              <IonLabel>Mobile App Help</IonLabel>
            </IonItem>
            <IonItem
              href="https://jiujitsux.com/app-faq/"
              target="_blank"
              routerDirection="none"
            >
              <IonIcon
                slot="end"
                ios={helpCircleOutline}
                md={helpCircleOutline}
              ></IonIcon>
              <IonLabel>FAQ</IonLabel>
            </IonItem>

            <IonItem
              href="https://jiujitsux.com/submit-a-ticket/"
              target="_blank"
              routerDirection="none"
            >
              <IonIcon
                slot="end"
                ios={hammerOutline}
                md={hammerOutline}
              ></IonIcon>
              <IonLabel>Tec Support</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};
