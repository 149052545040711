import React from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
const DropDownList = ({ dataSource, onChange, placeholder, value }) => {

	const compareWith = (o1, o2) => {
		//return o1 && o2 ? o1.value === o2.value : o1 === o2;
		return o1 && o2 ? o1 === o2 : o1 === o2;
	};

	return (
		<IonSelect
			interface="action-sheet"
			placeholder={placeholder}
			compareWith={compareWith}
			value={value}
			onIonChange={(ev) => onChange(ev.detail.value)}>
			{dataSource && dataSource.map((item) => (
				<IonSelectOption key={item.value} value={item.value}>
					{item.label}
				</IonSelectOption>
			))}
		</IonSelect>
	);

}

export default DropDownList;