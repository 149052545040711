import React from 'react';
import { IonChip, IonLabel, IonAvatar, IonItem, IonCard, IonButton } from '@ionic/react';
import { Link } from 'react-router-dom';
import { GATrackExplodeAuthorChip } from '../../../core/google';


const AuthorItem = ({ item }) => {

	return (<div style={{ textAlign: "center" }}>
		<Link style={{ textDecoration: 'none' }} onClick={() => GATrackExplodeAuthorChip(item.id, item.name)} to={
			{
				pathname: "/home/author-bio",
				state: {
					name: item.name, id: item.id
				}
			}
		} >

			<IonChip color="medium">
				<IonAvatar>
					<img src={item.pictureSmall} />
				</IonAvatar>
				<IonLabel>
					{item.name}
				</IonLabel>
			</IonChip>

		</Link>
	</div>
	);
}

export default AuthorItem;