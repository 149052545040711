import React from 'react';
import { informationCircle, playSkipBackCircle, playSkipForwardCircle, refreshCircle, volumeHigh, volumeMute } from 'ionicons/icons';
import { IonLabel, IonItem, IonIcon, useIonViewWillLeave, useIonViewDidEnter } from '@ionic/react';
import ReactPlayer from "react-player";
import { Link } from 'react-router-dom';
import { GATrackOpenedCourseDetailsFromRolls, GATrackRollsInteraction } from '../../core/google';
import { loggedUser } from '../auth/AuthCore';


export default function FeedPlayer({ playList, refreshList }) {

	const userEmail = loggedUser();
	const logUserInteraction = () => GATrackRollsInteraction(userEmail);

	const [idx, setIdx] = React.useState(0);
	const [paused, setPaused] = React.useState(false);
	const [muted, setMuted] = React.useState(false);

	const refresh = () => {
		logUserInteraction();
		refreshList();
	}

	const onEnd = () => {
		next();
	}

	const next = () => {
		logUserInteraction();

		let newIdx = idx + 1;
		if (newIdx == playList.length)
			newIdx = 0;
		setIdx(newIdx);
		setPaused(false);
	}

	const previous = () => {
		logUserInteraction();

		let newIdx = idx - 1;
		if (newIdx == -1)
			newIdx = playList.length - 1;
		setIdx(newIdx);
		setPaused(false);

	}

	const handleBlur = () => {
		setPaused(true);
	}

	const handleEnter = () => {
		setPaused(false);
	}


	useIonViewDidEnter(() => {
		handleEnter();
	});

	useIonViewWillLeave(() => {
		handleBlur();
	});

	React.useEffect(() => {

		window.addEventListener("blur", handleBlur);

		return () => {
			window.removeEventListener("blur", handleBlur);
		}

	}, []);


	return (<>

		<IonItem>
			<IonLabel ><h1>{playList && playList[idx].lessonTitle}</h1></IonLabel><br />
		</IonItem>

		<ReactPlayer

			playsInline
			playsinline={true}
			playing={!paused}
			muted={muted}
			onEnded={onEnd}
			width="100%"
			height="auto"
			controls={true}
			url={playList && playList[idx].defaultPlayUrl}
			config={{
				file: {
					attributes: {
						controlsList: 'nodownload'
					}
				}
			}}
		/>

		<IonItem>
			<IonIcon style={{ cursor: "pointer" }} size="large" icon={playSkipBackCircle} onClick={previous} />

			<IonIcon style={{ marginLeft: 15, cursor: "pointer" }} size="large" icon={refreshCircle} onClick={refresh} />

			<IonIcon style={{ marginLeft: 15, cursor: "pointer" }} size="large" icon={muted ? volumeMute : volumeHigh} onClick={() => setMuted(!muted)} />

			<IonIcon style={{ marginLeft: 15, cursor: "pointer" }} size="large" icon={playSkipForwardCircle} onClick={next} />

			<Link style={{ textDecoration: 'none', fontSize: 12 }} onClick={() => GATrackOpenedCourseDetailsFromRolls(playList[idx].courseId, playList[idx].courseTitle, playList[idx].lessonTitle, playList[idx].courseDiscountedPrice)} slot='end' to={{ pathname: "/home/course-detail", state: { courseId: playList[idx].courseId } }} >
				<IonIcon style={{ cursor: "pointer" }} color="primary" size="large" icon={informationCircle} />
			</Link>


		</IonItem>


	</>


	);
};
