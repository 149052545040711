
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module';

import AppConfig from "../App.config";

/* 
 * *****************
 * Usefull Links   *      
 * ***************** 
 * https://support.google.com/analytics/answer/9267735?hl=en
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase
 * 
 */




/* 
 * *****************
 * Config          *
 * ***************** 
 */


const Categories = {
	Purchase: "purchase",
	UserEngagement: "user"
};

/* 
 * *****************
 * Core            *
 * ***************** 
 */

const GATrackCustomEvent = (category, action, label, value, nonInteraction) => {
	let args = {
		category: category,
		action: action,
		label: label,
		value: value,
		nonInteraction: nonInteraction,
		transport: AppConfig.Google.GATrackingTransport
	};

	ReactGA.event(args);
}

const GATrackEvent = (name, parameters) => {
	ReactGA.event(name, parameters)
}

export const GATrackInitialize = () => {

	//	TagManager.initialize({ gtmId: AppConfig.Google.GTMId });

	ReactGA.initialize(AppConfig.Google.GATrackingCode);

	//Initialize GA4
	// ReactGA.initialize([
	// 	{
	// 		trackingId: AppConfig.Google.GATrackingCode,
	// 		//gaOptions: { debug: true },
	// 		//gtagOptions: {...}, 
	// 	}
	// ]);

	//Initialize Tag Magager
}

/* 
 * *****************
 * Hits            *
 * ***************** 
 */

export const GATrackHit = (page) => {
	ReactGA.send({
		hitType: "pageview",
		page: page
	});
}


/* 
 * *****************
 * Purchases       *
 * ***************** 
 */
export const GATrackPurchaseComplete = (transactionId, value, courseId, courseTitle, courseAuthor, courseCategory) => {

	const params =
	{
		transaction_id: transactionId,
		value: value,
		currency: AppConfig.Google.ConversionCurrency,
		items: [{
			item_id: courseId,
			item_name: courseTitle,
			index: 0,
			item_brand: courseAuthor,
			item_category: courseCategory,
			price: value
		}]
	};

	GATrackEvent("purchase", params);
}

export const GATrackPurchaseCanceled = (courseId, courseTitle, value) => {

	GATrackCustomEvent(Categories.Purchase, "purchase_failed", `${courseId}:${courseTitle}`, value, true);
}

export const GATrackPurchaseStarted = (courseId, courseTitle, value) => {

	GATrackCustomEvent(Categories.Purchase, "purchase_started", `${courseId}:${courseTitle}`, value, false);
}

/* 
 * *****************
 * Users           *
 * ***************** 
 */
export const GATrackSignIn = (email) => {
	GATrackEvent("login", { method: "wordpress" });
	//GATrackCustomEvent(Categories.UserEngagement, "sign_in", `${email}`, new Date().getTime(), false);
}

export const GATrackSignUp = (email) => {
	GATrackEvent("sign_up", { method: "wordpress" });
	//GATrackCustomEvent(Categories.UserEngagement, "sign_up", `${email}`, new Date().getTime(), false);
}

export const GATrackOpenedReviews = (courseId, courseTitle) => {
	GATrackEvent("select_content", { content_type: "reviews", item_id: `${courseId}:${courseTitle}` });
}

export const GATrackOpenedCourseDetail = (courseId, courseTitle) => {
	GATrackEvent("select_content", { content_type: "course_details", item_id: `${courseId}:${courseTitle}` });
}

export const GATrackOpenedAuthorsPage = (authorId, authorName) => {
	GATrackEvent("select_content", { content_type: "authors_page", item_id: `${authorId}:${authorName}` });
}

export const GATrackOpenedCategory = (categoryId, categoryTitle) => {
	GATrackEvent("select_content", { content_type: "course_category", item_id: `${categoryId}:${categoryTitle}` });
}

export const GATrackOpenedRolls = (isAuthenticated) => {
	GATrackEvent("select_content", { content_type: "rolls", item_id: isAuthenticated ? "client" : "visitor" });
}

export const GATrackOpenedPlayList = (name) => {
	let params = { content_type: "playlist" };

	if (name.toLowerCase() === "favorites")
		params.item_id = name;

	GATrackEvent("select_content", params);
}

export const GATrackOpenedPlayer = (courseId, courseTitle, type) => {
	GATrackEvent("select_content", { content_type: "player" + type, item_id: `${courseId}:${courseTitle}` });
}

export const GATrackReview = (courseId, courseTitle, rating) => {

	GATrackCustomEvent(Categories.UserEngagement, "review_course", `${courseId}:${courseTitle} : ${rating} Stars`, rating, false);
}

export const GATrackSwipeToEnd = (title, size) => {

	GATrackCustomEvent(Categories.UserEngagement, "swiped_all", `${title}:${size}`, size, false);
}

export const GATrackExplodeCategoryChip = (id, name) => {

	GATrackCustomEvent(Categories.UserEngagement, "view_category_home", `${id}:${name}`, 0, false);
}

export const GATrackExplodeAuthorChip = (id, name) => {

	GATrackCustomEvent(Categories.UserEngagement, "view_author_home", `${id}:${name}`, 0, false);
}

export const GATrackExplodeCategoryMenu = (id, name) => {

	GATrackCustomEvent(Categories.UserEngagement, "view_category_menu", `${id}:${name}`, 0, false);
}

export const GATrackOpenedSideMenu = () => {

	GATrackCustomEvent(Categories.UserEngagement, "side_menu_open", Date(), 0, false);
}

export const GATrackClickedJJXLogo = () => {

	GATrackCustomEvent(Categories.UserEngagement, "logo_click", Date(), 0, false);
}

export const GATrackOpenedCourseDetailsFromRolls = (courseId, courseTitle, lessonTitle, price) => {

	GATrackCustomEvent(Categories.UserEngagement, "rolls_more_info", `${courseId}:${courseTitle}:${lessonTitle}`, price, false);
}

export const GATrackRollsInteraction = (user) => {

	GATrackCustomEvent(Categories.UserEngagement, "rolls_interaction", user, new Date().getTime(), false);
}

export const GATrackStartedCourse = (courseId, courseTitle) => {

	GATrackCustomEvent(Categories.UserEngagement, "course_started", `${courseId}:${courseTitle}`, new Date().getTime(), false);
}

export const GATrackEnjoyingCourse = (courseId, courseTitle) => {

	GATrackOpenedPlayer(courseId, courseTitle, "");
	GATrackCustomEvent(Categories.UserEngagement, "course_enjoyed", `${courseId}:${courseTitle}`, new Date().getTime(), false);
}

export const GATrackPlayedFreeLesson = (courseId, courseTitle) => {

	GATrackOpenedPlayer(courseId, courseTitle, "_free");
	GATrackCustomEvent(Categories.UserEngagement, "course_play_free_lesson", `${courseId}:${courseTitle}`, new Date().getTime(), false);
}

export const GATrackAddedToPlaylist = (courseId, courseTitle, lessonTitle) => {

	GATrackCustomEvent(Categories.UserEngagement, "course_add_lesson_pl", `${courseId}:${courseTitle}:${lessonTitle}`, new Date().getTime(), false);
}

export const GATrackSearch = (keyphrase) => {

	GATrackCustomEvent(Categories.UserEngagement, "search_manual_track", keyphrase, new Date().getTime(), false);
}
