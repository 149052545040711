import React from 'react';

import { breakInChuncks } from '../../core/Functions';
import { getUserSuggestions } from '../../core/backend/GQLQueries';
import CourseSectionSkeleton from './skeleton/CourseSectionSkeleton';
import BotCourseSection from './BotCourseSection';

export default function BotSuggestions({ id, type }) {

	const [state, setState] = React.useState({
		dataStatus: { called: true, loading: true, error: false },
		dataSource: []
	});

	const loadCourses = (abortController) => {

		getUserSuggestions(type, id, abortController).then(result => {

			setState(
				{
					dataStatus: { called: false, loading: false, error: false },
					dataSource: result.data.userSuggestions
				}
			);

		}).catch(err => {
			if (err) {
				setState(
					{
						dataStatus: { called: true, loading: false, error: true },
						dataSource: []
					}
				);
			}
		});

	}

	React.useEffect(() => {

		let abortController = new AbortController();

		loadCourses(abortController);

		return () => {
			abortController.abort();
		}

	}, []);


	if (state.dataStatus.called && state.dataStatus.loading) return <CourseSectionSkeleton />;

	return (<BotCourseSection shuffle={state.dataSource.shuffle} courses={breakInChuncks(state.dataSource.lines, state.dataSource.courses)} horizontal={true} showPrice={true} refreshing={false} />);
}
