import React, { useState, useImperativeHandle, forwardRef } from "react";

import { IonItem, IonLabel, IonItemGroup, IonItemDivider, IonAvatar, IonImg } from "@ionic/react";
import DropDownList from "../../../shared/DropDownList";
import InputLabel from './InputLabel';
import RichInput from "./RichInput";

const imgBaseUrl = 'https://legionfiles.blob.core.windows.net/course-covers';

const coaches = [
	{
		value: '018794b6-3ff2-70ea-a87d-cbd975f0a538',
		label: 'Keenan Cornelius',
		email: 'keenan@jiujitsux.com',
	},
	{
		value: '0187f9f4-e511-75fc-ba8d-aa0fe0a8c12b',
		label: 'Miha Perhavec',
		email: 'miha@keenanonline.com',
	},
	{
		value: '018794af-d6a6-713a-aa8b-36fd1e9a9fd9',
		label: 'Andris Brunovskis',
		email: 'andrisbrunovskis@gmail.com',
	},
	{
		value: '018794be-fd3b-7635-ab44-dcf4a56eefb5',
		label: 'Austin Fraley',
		email: 'austinaaronfraley@gmail.com',
	},
	{
		value: '018794e7-6153-7746-b76e-f6eae1accd51',
		label: 'Michael Salazar',
		email: 'salazarmike@yahoo.com',
	},
	{
		value: '018794b5-0e00-754f-b827-1b32437b805f',
		label: 'Sloan Clymer',
		email: 'sloanclymer@gmail.com',
	},
	{
		value: '018794ae-c3fa-76f8-881f-285c66c20333',
		label: 'Paige Borras',
		email: 'paige.b619@gmail.com',
	},
	{
		value: '018794bd-a1d3-7381-bb01-1d2e6381afde',
		label: 'Jonathan Fennell',
		email: 'sunshine@jiujitsux.com',
	},
	{
		value: '018794c0-8907-75f4-bb14-1c18aa8bcf24',
		label: 'Mike Gardner',
		email: 'mikegardner90@outlook.com',
	},
	{
		value: '01873383-5a3b-7436-b410-e9feac5a01c2',
		label: 'Paulo Filho',
		email: 'amf.paulo@gmail.com',
	},
	{
		value: '018794c4-e616-7535-865c-b0b68d530c64',
		label: 'Anthony Harris',
		email: 'ccprorec@gmail.com',
	},
];

const BasicInfo = ({ draftList, basicInfo, onLoadDraft, isReadyOnly = false }, ref) => {

	//https://medium.com/numatic-ventures/how-to-integrate-vimeo-into-react-application-24b09cd286fd

	const [loadDraft, setLoadDraft] = useState(basicInfo.loadDraft);
	const [authorEmail, setAuthorEmail] = useState(basicInfo.author && basicInfo.author.email);
	const [authorAvatar] = useState(basicInfo.author && basicInfo.author.avatar);
	const [authorFirstName] = useState(basicInfo.author && basicInfo.author.firstName);
	const [authorLastName] = useState(basicInfo.author && basicInfo.author.lastName);
	const [authorAvatarImageText] = useState(basicInfo.author && basicInfo.author.avatarImageText);
	const [authorAvatarUrl] = useState(basicInfo.author && basicInfo.author.avatarUrl);
	const [courseType, setCourseType] = useState((basicInfo.type || basicInfo.type === 0) && basicInfo.type.toString());
	const [courseTitle, setCourseTitle] = useState(basicInfo.title);
	const [courseDescription, setCourseDescription] = useState(basicInfo.description);
	const [courseVideo, setCourseVideo] = useState(basicInfo.introVideo);
	const [courseCover, setCourseCover] = useState(basicInfo.cover);
	const [whatLearn, setWhatLearn] = useState(basicInfo.whatWillLearn);
	const [targetAudience, setTargetAudience] = useState(basicInfo.targetAudience);
	const [seoTitle, setSEOTitle] = useState(basicInfo.seoTitle);
	const [seoDescription, setSEODescription] = useState(basicInfo.seoDescription);
	const [seoCoverText, setSEOCoverText] = useState(basicInfo.seoCoverText);

	const sanitezeString = (str) => {
		return str.replace("'", "`").replace('"', '`').replace("{", "").replace("}", "").replace("[", "").replace("]", "");
	}

	useImperativeHandle(ref, () => ({

		getData: () => {
			return {
				loadDraft,
				author: {
					email: authorEmail,
					firstName: authorFirstName,
					lastName: authorLastName,
					avatarUrl: authorAvatarUrl,
					avatarImageText: authorAvatarImageText,
					avatar: authorAvatar
				},
				type: Number(courseType),
				title: courseTitle,
				description: courseDescription,
				introVideo: courseVideo,
				cover: courseCover,
				whatWillLearn: whatLearn,
				targetAudience,
				seoTitle,
				seoDescription,
				seoCoverText
			};
		}
	}));

	const selectDraft = (option) => {
		setLoadDraft(option);

		if (option !== "-1")
			onLoadDraft(option);
	}

	const textType = !isReadyOnly ? "text" : "label";
	const textareaType = !isReadyOnly ? "textarea" : "label";

	const dropdownComponent = (!isReadyOnly
		? <>
			<IonItemGroup>
				<IonItemDivider style={{ backgroundColor: "LightSteelBlue" }}>
					<IonLabel color="secondary">Load Draft</IonLabel>
				</IonItemDivider>

				<IonItem>
					<IonLabel position="stacked">Saved Work</IonLabel>
					<DropDownList placeholder="Select Drafts you saved" dataSource={draftList} onChange={(option) => selectDraft(option)} value={loadDraft} />
				</IonItem>
			</IonItemGroup>
			<br />
			<br />
			<br />
		</>
		: <></>);


	return (<>
		{dropdownComponent}

		<IonItemGroup>
			<IonItemDivider style={{ backgroundColor: "GhostWhite" }}>
				<IonLabel color="secondary">Author</IonLabel>
			</IonItemDivider>

			<DropDownList placeholder="Select Author" dataSource={coaches} onChange={(option) => setAuthorEmail(option)} value={authorEmail} />

			<div style={{ marginTop: 10, marginLeft: 20 }}>
				<IonAvatar>
					<img alt={"Avatar"} src={`${imgBaseUrl}/${authorEmail ? authorEmail : '00000000-0000-0000-0000-000000000000'}.jpg`} />
				</IonAvatar>
			</div>


		</IonItemGroup>
		<br />
		<IonItemGroup>
			<IonItemDivider style={{ backgroundColor: "GhostWhite" }}>
				<IonLabel color="secondary">Basic Info</IonLabel>
			</IonItemDivider>

			<IonItem>
				<IonLabel position={!isReadyOnly ? "stacked" : ""}>Course Type</IonLabel>
				{!isReadyOnly
					? <DropDownList placeholder="Select the type of course you are creating" dataSource={[{ value: "0", label: "Premium" }, { value: "-1", label: "Community" }]} onChange={(option) => setCourseType(option)} value={courseType} />
					: <IonLabel>{courseType === 0 ? "Premium" : "Community"}</IonLabel>
				}
			</IonItem>

			<InputLabel type={textType} placeholder="Enter course Title" caption="Title" value={courseTitle} onChange={e => setCourseTitle(sanitezeString(e))} />

			<InputLabel type={textType} placeholder="Enter course promo video Vimeo ID" caption="Course Promo Vimeo ID" value={courseVideo} onChange={e => setCourseVideo(e)} />

			<InputLabel type={textType} placeholder="Enter course cover URL" caption="Course Cover URL" value={courseCover} onChange={e => setCourseCover(e)} />

			{courseCover && courseCover !== "" &&
				<div style={{ marginTop: 10, marginLeft: 20 }}>
					<IonImg style={{ width: 160 }} src={courseCover} alt={seoCoverText} />
				</div>
			}

		</IonItemGroup>

		<br />

		<IonItemGroup>
			<IonItemDivider style={{ backgroundColor: "GhostWhite" }}>
				<IonLabel color="secondary">More Info</IonLabel>
			</IonItemDivider>

			<InputLabel type={textareaType} placeholder="Enter what you'll lear in this course" caption="What you'll learn" value={whatLearn} onChange={e => setWhatLearn(sanitezeString(e))} />
			<InputLabel type={textareaType} placeholder="Enter course target audience" caption="Target Audience" value={targetAudience} onChange={e => setTargetAudience(sanitezeString(e))} />
		</IonItemGroup>

		<br />
		<IonItemGroup>
			<IonItemDivider style={{ backgroundColor: "GhostWhite" }}>
				<IonLabel color="secondary">SEO</IonLabel>
			</IonItemDivider>

			<InputLabel maxlength={69} type={textType} placeholder="Enter SEO Title Tag" caption="Title Tag" value={seoTitle} onChange={e => setSEOTitle(sanitezeString(e))} />
			<InputLabel maxlength={159} type={textType} placeholder="Enter SEO description Tag" caption="Description Tag" value={seoDescription} onChange={e => setSEODescription(sanitezeString(e))} />
			<InputLabel maxlength={134} type={textType} placeholder="Enter SEO Cover Image Text" caption="Cover Image Text" value={seoCoverText} onChange={e => setSEOCoverText(sanitezeString(e))} />
		</IonItemGroup>

		<br />
		<IonItemGroup>
			<IonItemDivider style={{ backgroundColor: "GhostWhite" }}>
				<IonLabel color="secondary">Course Description</IonLabel>
			</IonItemDivider>

			<RichInput isReadOnly={isReadyOnly} caption="" value={courseDescription} onChange={e => setCourseDescription(sanitezeString(e))} />
		</IonItemGroup>






	</>);
}

export default forwardRef(BasicInfo);