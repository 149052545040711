import { useLocation } from 'react-router-dom';

export const useSearch = () => {
	const location = useLocation();

	if (location.state && location.state.search)
		return location.state.search;
	else if (location.search) {
		return new URLSearchParams(location.search).get("search");
	}
	else
		return "beginner";
}
