
import React from 'react';
import { star, starOutline } from 'ionicons/icons';
import { IonIcon, IonText } from '@ionic/react';

export default function Stars({ rating, reviews, showNumber = true, size = "small" }) {

	if (!rating || rating == 0 || rating == undefined) rating = 5;
	const array = [...Array(5).keys()];

	const stars = array.map(iconinfo => (
		<IonIcon key={iconinfo} icon={iconinfo < rating ? star : starOutline} style={{ marginRight: 3 }} size={size} color="gold" />
	));

	const lbl = <IonText color="dark-gold" style={{ fontWeight: "bold", fontSize: 21 }} >{rating} </IonText>;
	const rev = <IonText color="medium"  >({reviews})</IonText>;

	return <>{showNumber && lbl} {stars} {reviews && rev}</>;
}