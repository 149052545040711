import React from 'react';
import PageBase from '../shared/PageBase';

import { IonIcon, IonButton } from '@ionic/react';
import { logIn, play } from 'ionicons/icons';

import { useLocation } from "react-router-dom";

const useData = () => {
	const location = useLocation();

	if (location.state && location.state.data)
		return location.state.data;
	else
		return undefined;
}

export default function PurchaseThankYou() {

	const data = useData();

	const PageContent = () => {
		if (!data)
			return (<p>Loading....</p>);

		const Action = ({ isNotAuthenticated }) => {

			if (isNotAuthenticated)
				return (<IonButton size="large" routerLink={{ pathname: "/dashboard", state: { email: data.email, password: data.password } }}>
					Login
					<IonIcon icon={logIn} slot="end" />
				</IonButton>);

			else
				return (<IonButton size="large" routerLink={{ pathname: "/home/course-detail", state: { courseId: data.courseId } }}>
					Watch your course now
					<IonIcon icon={play} slot="end" />
				</IonButton>);

		}

		let msg = "";
		if (data.expiredAutoLogin || !data.isAuthenticated)
			msg = "In order to watch your recently purchased course, you will need to log into your Account. If this is your first purchase and you did not create your account, no worries, we created one for you. Click the Button Bellow to log in. We advice to change your password as soon as possible."

		return (

			<div style={{ textAlign: "center" }}>
				<br />
				<img style={{ width: 200 }} alt="[:)] SUCCESS [;-)]" src="https://jiujitsux.com/wp-content/uploads/2022/06/seal.png" />
				<h1>Thank You</h1>
				<h2>{msg}</h2>
				<br />
				<Action isNotAuthenticated={data.expiredAutoLogin || !data.isAuthenticated} />
			</div>);
	}

	return (<PageBase title="Purchase Thank You" robots="noindex, nofollow" description="Purchase feedback - Thank You!" canonical="https://jiujitsux.app/home/thank-you" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo" >
		<PageContent />
	</PageBase>);

}