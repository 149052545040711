import React, { useState, useContext } from "react";

const AppPlayerContext = React.createContext();

export function useAppPlayerContext() {
	return useContext(AppPlayerContext);
}

export function AppPlayerContextProvider({ children }) {

	const [playerPlayList, setPlayerPlayList] = useState([]);

	function togglePlayerPlayList(list) {
		setPlayerPlayList(list);
	}

	React.useEffect(() => {


	}, []);

	return (
		<AppPlayerContext.Provider value={[playerPlayList, togglePlayerPlayList]} >
			{children}
		</AppPlayerContext.Provider>
	);

}