
import React from 'react';

import { IonLabel, IonItem, IonSkeletonText } from '@ionic/react';
import { getMaxElements } from '../../../core/Functions';

export default function CourseItemTemplateSkeleton() {

	const getHeight = () => {
		let h = getMaxElements() * 40;
		if (h < 120) h = 120;
		return h;
	}

	return (

		<IonItem lines="none">
			<IonLabel>
				<h1>
					<IonSkeletonText animated style={{ width: '100%', height: getHeight() }} />
				</h1>
				<h2>
					<IonSkeletonText animated style={{ width: '85%' }} />
				</h2>
				<p>
					<IonSkeletonText animated style={{ width: '25%' }} />
				</p>
				<p>
					<IonSkeletonText animated style={{ width: '45%' }} />
				</p>
				<p>
					<IonSkeletonText animated style={{ width: '20%' }} />
				</p>
			</IonLabel>

		</IonItem>

	);
}
