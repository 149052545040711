import React, { useState } from 'react';

import { IonIcon, IonItem, IonLabel, IonList, IonLoading, useIonToast, IonItemSliding, IonItemOptions, IonItemOption } from '@ionic/react';
import { bookmarkOutline, skull, heartOutline, information, informationCircle } from 'ionicons/icons';

import { setDeletePlayList } from '../../../core/backend/GQLMutations';
import { getAllPlayListLessons, getAllPlayLists } from '../../../core/backend/GQLQueries';
import { getSourceToPlay } from '../../../core/Functions';

import { useIonRouter } from "@ionic/react";
import { GATrackOpenedPlayList } from '../../../core/google';
import { useAppPlayerContext } from '../../shared/AppPlayerContext';

export default function Playlists() {

	const [playerPlayList, togglePlayerPlayList] = useAppPlayerContext();

	const router = useIonRouter();
	const [present, dismiss] = useIonToast();
	const [showLoading, setShowLoading] = useState(true);


	const [playLists, setPlayLists] = React.useState([]);

	const playPlayList = (id, name) => {
		setShowLoading(true);

		getAllPlayListLessons(id).then(result => {
			var items = result.data.allPlayListLessons;

			let tmpList = [];
			let tmpListIdx = 0;

			items.forEach(function (topics) {
				topics.children.forEach(function (item) {
					tmpList.push({
						key: tmpListIdx++,
						id: item.id,
						vimeoId: item.vimeoId,
						title: item.title,
						ignore: false,
						playThumbnail: item.thumbnail,
						downloadUrl: item.download,
						duration: item.duration,
						watchPercent: 0,
						playUrl: getSourceToPlay([], item)
					});
				});
			});

			togglePlayerPlayList(tmpList);

			if (tmpList.length > 0) {
				GATrackOpenedPlayList(name);

				router.push({
					pathname: "/home/flow-player", state:
					{
						courseId: 10000,
						playList: tmpList,
						initialPlayUrl: tmpList[0].playUrl, //getSourceToPlay([], startingPoint),
						courseTitle: name
					}
				}, "forward", "push");
			}
			else {
				present({
					buttons: [{ text: 'X', handler: () => dismiss() }],
					message: 'Playlist is Empty',
					duration: 2000,
					icon: informationCircle,
					color: "tertiary"
				});
			}


			setShowLoading(false);
		}).catch(err => {

			console.log(err);

			if (err) {
				present({
					buttons: [{ text: 'X', handler: () => dismiss() }],
					message: 'We Could not retrieve playlist items',
					duration: 1000,
					icon: skull,
					color: "warning"
				});
			}
		});
	}


	const loadAllPlayLists = (abortController) => {

		getAllPlayLists(abortController).then(result => {
			setPlayLists([{ id: -1, name: "Favorites" }].concat(result.data.allPlayLists));
			setShowLoading(false);
		}).catch(err => {
			if (err) {
				present({
					buttons: [{ text: 'X', handler: () => dismiss() }],
					message: 'We Could not retrieve all playlists',
					duration: 1000,
					icon: skull,
					color: "warning"
				});
			}
		});
	}

	const deletePlayList = (id) => {
		setShowLoading(true);
		setDeletePlayList(id).then(result => {

			if (!result.success) {
				present({
					buttons: [{ text: 'X', handler: () => dismiss() }],
					message: 'Oh no! We found problems. Try again.',
					duration: 2000,
					icon: skull,
					color: "warning"
				});
			}
			else {
				loadAllPlayLists();
			}

			setShowLoading(false);

		}).catch(e => {
			present({
				buttons: [{ text: 'X', handler: () => dismiss() }],
				message: 'Oh no! We are sorry. Try again.',
				duration: 2000,
				icon: skull,
				color: "danger"
			});
			setShowLoading(false);
		});
	}


	React.useEffect(() => {

		let abortController = new AbortController();

		loadAllPlayLists(abortController)

		return () => {
			abortController.abort();
		}

	}, []);

	const Item = ({ item }) => (

		<IonItemSliding style={{ cursor: "pointer" }}>
			<IonItem lines="full" key={item.id} onClick={() => playPlayList(item.id, item.name)}>
				<IonIcon icon={item.id < 0 ? heartOutline : bookmarkOutline} slot="start" />
				<IonLabel>{item.name}</IonLabel>
			</IonItem>

			<IonItemOptions side="start">
				<IonItemOption onClick={() => deletePlayList(item.id)} color="secondary">
					DELETE
				</IonItemOption>
			</IonItemOptions>

			<IonItemOptions side="end">
				<IonItemOption onClick={() => deletePlayList(item.id)} color="secondary">
					DELETE
				</IonItemOption>
			</IonItemOptions>

		</IonItemSliding>
	);

	const PlayList = () => {

		if (!playLists || playLists.length == 0)
			return (<></>);
		return (
			<IonList >
				{playLists.map((item) => {
					return (
						<Item key={item.id} item={item} />
					);
				})}
			</IonList>);
	}

	return (<>
		<IonItem>
			<IonLabel ><h1>My Playlists</h1></IonLabel><br />
		</IonItem>

		<PlayList />

		<IonLoading
			isOpen={showLoading}
			onDidDismiss={() => setShowLoading(false)}
			message={'Please wait...'}
			duration={5000}
		/>

	</>);
};
