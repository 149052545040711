import React from 'react';
import { breakInChuncks, splitCategories } from '../../../core/Functions';
import { getHomeCourses } from '../../../core/backend/GQLQueries';
import CourseSectionMultiple from '../../courses/CourseSectionMultiple';
import CourseSectionSkeleton from '../../courses/skeleton/CourseSectionSkeleton';
import { useSearch } from '../../shared/hooks';

export default function Search() {
	const inputedTerm = useSearch();

	const [state, setState] = React.useState({
		dataStatus: { called: true, loading: true, error: false },
		dsCourses: [],
		dsCourseCategory: [],
		dsCategories: {},
		dsAuthors: {},
	});

	const loadCourses = (abortController) => {

		getHomeCourses(inputedTerm, abortController).then(result => {

			setState(
				{
					dataStatus: { called: false, loading: false, error: false },
					dsCourseCategory: result.data.lightHomeData,
					dsCourses: result.data.allLibraryCourses,
					dsCategories: splitCategories(result.data.wholeLibraryCategories),
					dsAuthors: splitCategories(result.data.wholeLibraryAuthors),
				}
			);

		}).catch(err => {
			if (err) {
				setState(
					{
						dataStatus: { called: true, loading: false, error: true },
						dsCourses: [],
						dsCourseCategory: [],
						dsCategories: {},
						dsAuthors: {},
					}
				);
			}
		});

	}

	const filterCourses = (ids) => {

		let result = [];

		for (let idxIds = ids.length - 1; idxIds >= 0; idxIds--) {
			for (let idxCourses = 0; idxCourses < state.dsCourses.length; idxCourses++) {
				const course = state.dsCourses[idxCourses];
				if (course.id === ids[idxIds]) {
					result.push(course);
					break;
				}
			}
		}

		return result;
		//state.dsCourses.filter((course) => ids.includes(course.id))
	}


	React.useEffect(() => {

		let abortController = new AbortController();

		loadCourses(abortController);

		return () => {
			abortController.abort();
		}

	}, []);


	if (state.dataStatus.called && state.dataStatus.loading) return <CourseSectionSkeleton />;
	if (state.dataStatus.called && state.dataStatus.error) return <CourseSectionSkeleton />;

	return (
		state.dsCourseCategory.map((item, index) => {

			return (<CourseSectionMultiple
				key={index}
				title={item.title}
				shuffle={item.shuffle}
				addCategoriesModule={item.addCategories}
				addAuthorsModule={item.addAuthors}
				courses={breakInChuncks(item.lines, filterCourses(item.coursesIds))}
				dsCategories={state.dsCategories}
				dsAuthors={state.dsAuthors}
				horizontal={true}
				showPrice={item.showPrice}
				showProgress={item.showProgress}
				refreshing={false} />);

		})
	);

}