import React, { useState, useImperativeHandle, forwardRef } from "react";

import { IonItem, IonLabel, IonInput, IonTextarea, IonCheckbox } from "@ionic/react";

const ImportContent = ({ importDataFrom }, ref) => {

	const [vimeoProjectId, setVimeoProjectId] = useState(importDataFrom.vimeoProjectId);
	const [courseScript, setCourseScript] = useState(importDataFrom.courseScript);
	const [dontImportAgain, setDontImportAgain] = useState(importDataFrom.dontImportAgain);

	useImperativeHandle(ref, () => ({
		getData: () => {
			const data = { vimeoProjectId: vimeoProjectId || "", courseScript: courseScript || "", dontImportAgain: dontImportAgain || false }
			return data;
		}
	}));


	return (<>

		<IonItem lines="none">
			<IonCheckbox checked={dontImportAgain} onIonChange={e => setDontImportAgain(s => !s)} slot="start"></IonCheckbox>
			<IonLabel>Don't import again</IonLabel>
		</IonItem>
		<br />
		<br />
		<IonItem>
			<IonLabel position="stacked">Vimeo Project ID</IonLabel>
			<IonInput type="number" placeholder="Enter Vimeo Project ID" value={vimeoProjectId} onIonChange={e => setVimeoProjectId(e.detail.value)} > </IonInput>
		</IonItem>
		<IonItem>
			<IonLabel position="stacked">Course Script</IonLabel>
			<IonTextarea rows={10} autoGrow={true} type="text" placeholder="Enter Course Script" value={courseScript} onIonChange={e => setCourseScript(e.detail.value)} > </IonTextarea>
		</IonItem>
	</>);

}

export default forwardRef(ImportContent);