import React from 'react';
import PageBase from './PageBase';

export default function Error({ err }) {

	return (
		<PageBase title="Error" robots="noindex, nofollow" description="Error Page" canonical="https://jiujitsux.app/error" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">
			<p>There was a problem loading the feed. Try Logout and Login again.</p>
		</PageBase>
	);
}