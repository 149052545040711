import React, { useState, } from "react";
import { IonButton, IonLoading, useIonAlert, } from "@ionic/react";
import "rsuite/dist/rsuite.min.css";
import BasicInfo from "./BasicInfo";
import CourseCurriculum from "./CourseCurriculum";
import AppConfig from "../../../../App.config";

//TODO: Create robot to rename vimeo videos based on Course Script

const ReadyToCreate = ({ basicInfo, importContent, content }) => {

	const [isLoading, setIsLoading] = useState(false);
	const [presentAlert] = useIonAlert();
	const validateFields = () => {
		let fields = [];
		//Basic Info
		for (var key in basicInfo) {
			if (basicInfo[key] === null || basicInfo[key] === undefined || basicInfo[key] === '')
				fields.push(`[${key}]`);
		}
		if (fields.length > 0)
			return fields.join(", ");

		if (!(content && content.length > 0 && content[0].lessons && content[0].lessons.length > 0))
			return "[Course Content: Topics and Lessons]";


		return '';
	}

	const saveWork = (isDraft) => {
		var validForm = validateFields();
		if (validForm !== "") {
			presentAlert({
				header: 'Validation',
				subHeader: 'Error',
				message: `The field(s) "${validForm}" is/are required!`,
				buttons: ['OK']
			});

			return;
		}

		setIsLoading(true);

		const inputData = {
			title: basicInfo.title,
			basicInfo: JSON.stringify(basicInfo),
			importContent: JSON.stringify(importContent),
			courseContent: JSON.stringify(content),
			draftId: basicInfo.loadDraft,
			onlySaveDraft: false,
			AuthorID: "xpto",
		};

		const endPoint = `${AppConfig.LegionAPI}/save-course-and-draft`;

		fetch(endPoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			},
			body: JSON.stringify(inputData)
		})
			.then(response => {
				if (!response.ok) {
					console.log('RESPONSE NOT OK', response.text);
					presentAlert({
						header: 'Error',
						subHeader: 'Failure!',
						message: 'It was not possible to excute you request!',
						buttons: ['OK'],
					});
					setIsLoading(false);
				}
				return response.json();
			})
			.then(data => {
				presentAlert({
					header: 'Information',
					subHeader: 'Success!',
					message: isDraft ? 'Work Saved with Success!' : 'Course Created with Success!',
					buttons: ['OK'],
					onDidDismiss: () => window.location.reload()
				});

				setIsLoading(false);
			})
			.catch(error => {
				console.log('There was an error with the fetch operation:', error);
				presentAlert({
					header: 'Error',
					subHeader: 'Failure!',
					message: 'It was not possible to excute you request!',
					buttons: ['OK'],
				});
				setIsLoading(false);
			});
	}

	return (<>

		<BasicInfo isReadyOnly={true} basicInfo={basicInfo} />
		<br />
		<br />
		<div style={{ textAlign: "center" }}>
			<iframe referrerpolicy="strict-origin-when-cross-origin" title="video" src={`https://player.vimeo.com/video/${basicInfo.introVideo}`} width="640" height="360" frameBorder="0" allow="picture-in-picture"></iframe>
		</div>
		<br />
		<br />
		<CourseCurriculum dataSource={content} />
		<br />
		<br />
		<div style={{ textAlign: "center" }}>
			<IonButton size="large" color="primary" onClick={() => saveWork(false)} >Create New Course</IonButton>
		</div>

		<IonLoading
			isOpen={isLoading}
			onDidDismiss={() => setIsLoading(false)}
			message={'Please wait, this could take a long time. Saving Work...'}
			duration={80000}
		/>

	</>);
}

export default ReadyToCreate;