
import React, { useState } from 'react';
import { Suspense } from "react";

import Splash from '../shared/Splash';
import Error from '../shared/Error';

import { Test, addZeroes, nl2br, getViewers } from '../../core/Functions';

import ReviewSummary from './reviews/ReviewSummary';
import Rating from './reviews/Rating';
import { getCourseDetail } from '../../core/backend/GQLQueries';
import { setRating, setCreateStripeSession, setToggleWishlist } from '../../core/backend/GQLMutations';

import { IonSpinner, IonItem, IonLoading, IonLabel, IonIcon, IonRippleEffect, IonImg, IonText, IonButton, IonGrid, IonRow, IonCol, useIonToast } from '@ionic/react';
import { timer, wallet, caretForwardCircleOutline, heart, checkmark, heartDislike } from 'ionicons/icons';
import Stars from './reviews/Stars';


import { Link, useLocation } from "react-router-dom";
import { useIonRouter } from "@ionic/react";
import { loadStripe } from "@stripe/stripe-js";

import PageBase from '../shared/PageBase';
import { IsAuthenticated } from '../auth/AuthCore';
import { GATrackReview, GATrackStartedCourse, GATrackPurchaseStarted, GATrackOpenedCourseDetail } from '../../core/google';
import { useGetPartner } from '../../core/hooks';
import Viewers from './Viewers';
import PromoBanner from './PromoBanner';

const CourseDetailsSuggestions = React.lazy(() => import('./CourseDetailSusggestions'));
const CourseCurriculum = React.lazy(() => import('./CourseCurriculum'));


const useParams = () => {
	const location = useLocation();

	let title = "";
	let id = 0;
	let toStripe = false;

	let query;

	try {
		//Query String
		if (location.search) {
			query = new URLSearchParams(location.search);
		}

		//Id
		if (location.state && location.state.courseId)
			id = location.state.courseId;
		else
			id = query.get("courseId");

		//Title
		if (location.state && location.state.title)
			title = location.state.title;
		else
			title = query.get("title");

		//Stripe
		if (location.state && location.state.toStripe)
			toStripe = location.state.toStripe;
		else
			toStripe = query.get("toStripe") == "true";


		return { id: Number(id), title, toStripe };
	}
	catch
	{
		return { id: Number(id), title, toStripe };
	}
}


// const useId = () => {
// 	const location = useLocation();

// 	if (location.state && location.state.courseId)
// 		return location.state.courseId;
// 	else if (location.search) {
// 		return new URLSearchParams(location.search).get("courseId");
// 	}
// 	else
// 		return undefined;
// }

export default function CourseDetail() {

	let title = "";
	let id = 0;
	let toStripe = false;

	({ id, title, toStripe } = useParams());

	const partner = useGetPartner();
	const router = useIonRouter();

	const [present, dismiss] = useIonToast();

	const [showLoading, setShowLoading] = useState(toStripe);

	const [wishButton, setWishButton] = useState({ message: "Add to your Wishlist", icon: heart });

	const [called, setCalled] = React.useState(true);
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState(false);

	const [isMyCourse, setIsMyCourse] = React.useState(false);
	const [courseInfo, setCourseInfo] = useState({});

	const addToWishlist = () => {
		setToggleWishlist(id).then(result => {

			if (result.data.toggleWishlist === "Successfully added to your wishlist")
				setWishButton({ message: "Remove from your Wishlist", icon: heartDislike });
			else
				setWishButton({ message: "Add to your Wishlist", icon: heart });

			present({
				buttons: [{ text: 'X', handler: () => dismiss() }],
				message: result.data.toggleWishlist,
				duration: 2500,
				icon: checkmark
			});

		}).catch(err => {
			console.log(err);
		});
	}

	const buyNow = () => {
		setShowLoading(true);

		GATrackPurchaseStarted(id, courseInfo.title, courseInfo.discountedPrice);

		setCreateStripeSession(Number(id), partner).then(result => {


			if (!result.success) {
				if (process.env.NODE_ENV === 'development')
					console.log(result.data);
			}
			else {

				loadStripe(result.data.createStripeSession.publicKey).then(stripe => {

					stripe.redirectToCheckout({
						sessionId: result.data.createStripeSession.sessionId
					});

				});
			}

			setShowLoading(false);

		}).catch(e => {
			if (process.env.NODE_ENV === 'development') console.log(e);
			setShowLoading(false);
		});
	}

	const playAll = (startingPoint) => {

		GATrackStartedCourse(id, courseInfo.title);

		router.push({
			pathname: "/home/flow-player", state:
			{
				courseId: id,
				playList: undefined,
				initialPlayUrl: startingPoint,
				courseTitle: courseInfo.title

			}
		}, "forward", "push");
	}

	const showAllReviews = () => {
		router.push({
			pathname: "/home/all-reviews", state:
			{
				isAllFromAuthor: false,
				id: id,
				title: courseInfo.title
			}
		}, "forward", "push");
	}

	const onFinishReview = (comment, stars) => {
		setShowLoading(true);
		setRating(Number(id), comment, stars).then(result => {

			if (!result.success) {
				alert("Ooops! - Something went wrong, internet maybe?. Try again.");
			}
			else {
				GATrackReview(id, courseInfo.title, stars);
				alert("Oss - Thank you for reviewing this course. This will help us improve it for you.")
			}

			setShowLoading(false);

		}).catch(e => {
			alert("Ooops! - Something went wrong, internet maybe?. Try again.");
			setShowLoading(false);
		});
	}


	const getData = (courseId, abortController) => {

		getCourseDetail(courseId, Test(), abortController)
			.then(result => {

				const data = result.data;

				GATrackOpenedCourseDetail(courseId, data.newCourseDetails.title);

				data.newCourseDetails.rating = addZeroes(data.newCourseDetails.rating);

				setCourseInfo(data.newCourseDetails);

				setIsMyCourse(data.newCourseDetails.isMyCourse);

				setLoading(false);
			})
			.catch(err => {
				if (err) {
					setError(true);
					setLoading(false);
				}
			});
	}


	React.useEffect(() => {

		let abortController;

		if (toStripe == true)
			buyNow();
		else {
			abortController = new AbortController();

			if (id && id > 0)
				getData(Number(id), abortController)
		}

		return () => {
			if (abortController)
				abortController.abort();
		}

	}, [id]);

	const PageContent = () => {
		return (<div style={{ paddingLeft: "5%", paddingRight: "5%", paddingBottom: "2%", paddingTop: "2%" }}>
			<IonGrid>
				<IonRow className='ion-justify-content-center'>
					<IonCol size={12} size-xl={6} size-lg={6} size-md={12} size-sm={12}>
						<IonGrid>
							<IonRow>
								<IonRow>
									<IonCol>
										<IonLabel><h1>{courseInfo.title}</h1></IonLabel>
									</IonCol>
								</IonRow>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonItem lines='none' onClick={() => playAll()} className="ion-activatable ripple-parent" >
										<IonImg src={courseInfo.defaultThumbnail} />
										<IonRippleEffect></IonRippleEffect>
									</IonItem>
									<Viewers />
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonCol>
					<IonCol size={12} size-xl={6} size-lg={6} size-md={12} size-sm={12}>

						<IonGrid>
							<IonRow>
								<IonCol>
									<IonLabel position='stacked'><Stars rating={courseInfo.rating} reviews={courseInfo.reviews} /></IonLabel>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									{courseInfo.totalEnrolls != 0 && <IonLabel>{courseInfo.totalEnrolls} students</IonLabel>}

								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<IonLabel>
										{"Created by: "}
										<Link to={
											{
												pathname: "/home/author-bio",
												state: {
													name: courseInfo.author, id: courseInfo.authorId
												}
											}
										} >
											{courseInfo.author}
										</Link>
									</IonLabel>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<IonIcon position='stacked' icon={timer} slot="start" />
									<IonLabel position='stacked'>

										{`Last updated ${courseInfo.lastUpdate}`}

									</IonLabel>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<IonLabel style={{ marginTop: 15 }}>

										{"$" + courseInfo.discountedPrice}
										{courseInfo.discountedPrice != courseInfo.price &&
											<IonText style={{ fontSize: 14, textDecoration: "line-through", marginTop: 6, marginLeft: 3 }} color="danger" >
												{"$" + courseInfo.price}
											</IonText>
										}

									</IonLabel>
									<div style={{ marginLeft: -18 }}>
										<PromoBanner type='none' />
									</div>

								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<IonLabel>
										{isMyCourse
											? <IonButton size="large" expand="block" onClick={() => playAll()}>
												Start Course
												<IonIcon icon={caretForwardCircleOutline} slot="end" />
											</IonButton>
											: <IonButton size="large" expand="block" onClick={() => buyNow()}>
												Buy Now
												<IonIcon icon={wallet} slot="end" />
											</IonButton>
										}

										{!isMyCourse &&
											<IonButton color="tertiary" size="small" expand="block" onClick={() => addToWishlist()}>
												{wishButton.message}
												<IonIcon icon={wishButton.icon} slot="end" />
											</IonButton>}

									</IonLabel>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<IonLabel>
										<b>What Will I Learn?</b>
									</IonLabel>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<div dangerouslySetInnerHTML={{ __html: '&#128312;' + nl2br(courseInfo.benefits) }} />
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<IonLabel >
										<b>Target Audience</b>
									</IonLabel>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<div dangerouslySetInnerHTML={{ __html: '&#128312;' + nl2br(courseInfo.targetAudience) }} />
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<IonLabel>
										<b>Description</b>
									</IonLabel>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<div dangerouslySetInnerHTML={{ __html: courseInfo.description }} />
								</IonCol>
							</IonRow>

						</IonGrid>
					</IonCol>
				</IonRow>
			</IonGrid>

			<Suspense fallback={<IonSpinner name="bubbles" color="secondary" />}>
				<CourseCurriculum courseId={courseInfo.courseId} isMine={courseInfo.isMyCourse} trailerThumbnail={courseInfo.defaultThumbnail} trailerVideo={courseInfo.defaultVideo} courseTitle={courseInfo.title} />
			</Suspense>

			<Suspense fallback={<IonSpinner name="bubbles" color="secondary" />}>
				<CourseDetailsSuggestions type="sales" id={0} />
			</Suspense>


			<ReviewSummary
				onShowAllReviews={() => showAllReviews()}
				summary={courseInfo.reviewsSummary}
				showAllReviews={false} />


			{
				IsAuthenticated()
				&& <div>
					<br />
					<br />
					<Rating courseId={id} onRate={onFinishReview} comment={courseInfo.reviewsSummary.myReview.comment} rating={courseInfo.reviewsSummary.myReview.rating} date={courseInfo.reviewsSummary.myReview.date} />
				</div>
			}

			<Suspense fallback={<IonSpinner name="bubbles" color="secondary" />}>
				<CourseDetailsSuggestions type="daily" id={0} />
			</Suspense>

			<Suspense fallback={<IonSpinner name="bubbles" color="secondary" />}>
				<CourseDetailsSuggestions type="author" id={courseInfo.authorId} />
			</Suspense>

			<IonLoading
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				message={'Please wait...'}
				duration={5000}
			/>
		</div >);
	}

	if ((called && loading) || (id === undefined)) return <Splash />;
	if (called && error) return <Error />;

	return (<PageBase title={courseInfo.title} gaOverridePage={window.location.pathname + `?courseId=${id}`} robots="index, follow" description={courseInfo.title} canonical="https://jiujitsux.app/home/course-detail" og_type="website" image={courseInfo.defaultThumbnail} image_alt={"Course Cover"} >
		<PageContent />
	</PageBase>);



}