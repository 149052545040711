import React from 'react';
import { IonReactRouter } from '@ionic/react-router';
import { Menu } from './Menu';
import Routes from './Routes';


const Main = () => {

	return (
		<IonReactRouter>
			<Menu />
			<Routes />
		</IonReactRouter>);
}

export default Main;
