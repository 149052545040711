
import React, { useState } from 'react';
import { IonIcon, IonItem } from '@ionic/react';
import { eye } from 'ionicons/icons';
import { getViewers } from '../../core/Functions';



export default function Viewers() {

	const [viewers, setViewers] = useState(getViewers());

	React.useEffect(() => {
		const interval = setInterval(() => {
			setViewers(getViewers());
		}, 7000);

		return () => {
			clearInterval(interval);
		}

	}, [viewers]);

	return (<IonItem lines='none'>
		<IonIcon className="fe-pulse" slot="start" icon={eye}></IonIcon>
		{viewers} people are currently looking at this product.
	</IonItem>);

}