import React from 'react';
import { IonLabel, IonTextarea, IonItem, IonButton } from '@ionic/react';

import ReactStars from "react-rating-stars-component";


const Rating = ({ courseId, onRate, comment, rating, date }) => {

	const [stars, setStars] = React.useState(rating);
	const [text, setText] = React.useState(comment);

	const ratingCompleted = (rating) => {
		setStars(rating);
	}

	const getAffix = () => {
		const len = `${500 - text.length}/500`;
		return len;
	}

	return (<div style={{ marginLeft: 20 }}>

		<IonLabel><h1>Rate this course</h1></IonLabel>

		{comment && comment.trim() != "" && <span><i> {`You reviewed this course on ${date}`}</i></span>}
		<br />
		<IonItem fill="solid" style={{ marginRight: 20 }} >
			<IonTextarea
				placeholder="Describe your experience (optional)"
				maxLength={500}
				value={text}
				onIonChange={(e) => setText(e.detail.value)} >
			</IonTextarea>

			<IonLabel slot="end">{getAffix()}</IonLabel>
		</IonItem>
		<IonLabel color='medium'>
			<i>Reviews are public and include your account info.</i>
		</IonLabel>

		<ReactStars
			value={stars}
			count={5}
			onChange={ratingCompleted}
			size={45}
			activeColor="#D1B000"
			style={{ marginTop: -30 }}
		/>

		<IonButton style={{ width: "98%", marginBottom: 20, marginTop: 10, alignSelf: 'center' }} onClick={() => onRate(text, stars)} >
			{comment.trim() != "" ? "Change it" : "Rate it !"}
		</IonButton>


	</div>
	);
};

export default Rating;