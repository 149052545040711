
import React, { useState } from 'react';
import { CreateAnimation, IonIcon, IonItem, IonLabel, IonText } from '@ionic/react';
import { alarmOutline, closeOutline, timer } from 'ionicons/icons';



export default function PromoBanner({ type = 'banner' }) {

	const iconRef = React.useRef();

	const [promoEndDate, setPromoEndDate] = useState(new Date(localStorage.getItem("promo_end")));
	const [promoBanner, setPromoBanner] = useState(localStorage.getItem("promo_banner"));
	const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const [visible, setVisible] = useState(true);

	React.useEffect(() => {

		const interval = setInterval(function (endDate = promoEndDate) {
			let date_future = endDate;
			let date_now = new Date();

			let seconds = Math.floor((date_future - (date_now)) / 1000);
			let minutes = Math.floor(seconds / 60);
			let hours = Math.floor(minutes / 60);
			let days = Math.floor(hours / 24);

			hours = hours - (days * 24);
			minutes = minutes - (days * 24 * 60) - (hours * 60);
			seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

			setDays(days);
			setHours(hours);
			setMinutes(minutes);
			setSeconds(seconds);

		}, 1000);

		return () => {
			clearInterval(interval);
		}

	}, [days, hours, minutes, seconds]);

	if (promoEndDate < new Date())
		return <></>;
	else {
		if (type === 'banner')
			return (
				visible && <IonItem color="warning" lines='none'>
					<IonIcon className="fe-pulse" slot="start" icon={alarmOutline}></IonIcon>
					<IonText className='ion-text-center'><h6>{promoBanner}.Ends in {days}d {hours}h {minutes}m {seconds}s</h6></IonText>
					<IonIcon slot="end" icon={closeOutline} style={{ cursor: 'pointer' }} onClick={() => setVisible(false)}></IonIcon>
				</IonItem>
			);
		else
			return (
				visible && <IonItem lines='none'>
					<IonIcon className="fe-pulse" slot="start" icon={alarmOutline}></IonIcon>
					<IonText className='ion-text-center'><h6>{days}d {hours}h {minutes}m {seconds}s left at this price!</h6></IonText>
				</IonItem>
			);
	}

}