import { send, sendOutline } from 'ionicons/icons';
import { useState } from 'react';
import { IonItem, IonIcon, IonInput } from '@ionic/react';
import { IsAuthenticated } from '../auth/AuthCore';

const SendMessageForm = ({ sendMessage }) => {
    const [message, setMessage] = useState('');

    const sendNow = () => {
        sendMessage(message);
        setMessage('');
    }


    return (<IonItem slot='bottom' mode="md" fill='solid' color="light" style={{ borderRadius: 10, marginLeft: 7, marginRight: 7 }} >
        <IonInput value={message} placeholder={IsAuthenticated() ? "Message..." : "Login to send messages...."} disabled={!IsAuthenticated()} onIonChange={e => setMessage(e.target.value)} onKeyUp={(e) => e.key == 'Enter' && sendNow()} ></IonInput>
        <IonIcon color="secondary" disabled={!(IsAuthenticated() && message && message.trim() != "")} slot="end" icon={send} onClick={() => sendNow()} />
    </IonItem>);

}

export default SendMessageForm;