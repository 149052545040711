import React from 'react';
import uuid from 'react-uuid'

import { initializeApp } from "firebase/app";
import {
    getFirestore,
    query,
    orderBy,
    onSnapshot,
    collection,
    limit,
    getDoc,
    setDoc,
    getDocs,
    addDoc,
    deleteDoc,
    updateDoc,
    doc,
    serverTimestamp,
    arrayUnion
} from "firebase/firestore";

import { loggedUserData } from '../auth/AuthCore';

import Chat from './Chat';
import './FirebaseChat.css'
import PageBase from '../shared/PageBase';


// firebase.initializeApp({
//     apiKey: "AIzaSyB0Q_So3wgOZ0OglAhmmstrZsoXZjiuA6Q",
//     authDomain: "jiu-jitsu-x-2021.firebaseapp.com",
//     projectId: "jiu-jitsu-x-2021",
//     storageBucket: "jiu-jitsu-x-2021.appspot.com",
//     messagingSenderId: "1027202856951",
//     appId: "1:1027202856951:web:75d3e60151feb47711e0f2",
//     measurementId: "G-4G2M362MX9"
// });

const firebaseConfig = {
    apiKey: "AIzaSyB0Q_So3wgOZ0OglAhmmstrZsoXZjiuA6Q",
    authDomain: "jiu-jitsu-x-2021.firebaseapp.com",
    projectId: "jiu-jitsu-x-2021"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


const FirebaseChat = () => {

    const userData = loggedUserData();

    const [messages, setMessages] = React.useState([]);

    const streamMessages = (snapshot, error) => {
        const msgRef = collection(db, 'messages');
        const myquery = query(msgRef, orderBy('createdAt', 'desc'), limit(60));
        return onSnapshot(myquery, snapshot, error);
    };

    React.useEffect(() => {

        const unsubscribe = streamMessages(
            (querySnapshot) => {
                const updated =
                    querySnapshot.docs.map(docSnapshot => docSnapshot.data());
                setMessages(updated.reverse());
            },
            (error) => setMessages([])
        );

        return unsubscribe;

    }, []);

    const sendMessage = async (message) => {

        const new_id = uuid();

        const data = {
            id: new_id,
            text: message,
            createdAt: serverTimestamp(),
            user_id: userData.userId,
            nick: userData.displayName.replace(" ", "-").substring(0, 20)
        };

        await setDoc(doc(db, "messages", new_id), data);
    }

    const softDeleteMessage = async (data) => {

        const docRef = doc(db, "messages", data.id);


        if (data.text == "* # * # *") {
            deleteDoc(docRef)
                .then(() => {
                    console.log("Entire Document has been deleted successfully.")
                })
                .catch(error => {
                    console.log(error);
                })
        }
        else {
            data.text = "* # * # *";

            setDoc(docRef, data)
                .then(docRef => {
                    console.log("Entire Document has been updated successfully");
                })
                .catch(error => {
                    console.log(error);
                });
        }

    }

    return (<PageBase title='Chat' robots="index, nofollow" description="Joi to our Super Chat and lets talk abouit Jiu Jitsu" canonical="https://jiujitsux.app/chat" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">
        <Chat sendMessage={sendMessage} messages={messages} me={userData.userId} updateDoc={softDeleteMessage} />
    </PageBase>);

}

export default FirebaseChat;
