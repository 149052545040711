import React from 'react';
import { IonLabel, IonItem } from '@ionic/react';

function CourseCurriculum({ dataSource }) {

	const Lesson = ({ item }) => (<dd>
		<IonItem>
			<IonLabel>
				<iframe referrerpolicy="strict-origin-when-cross-origin" title="video" src={`https://player.vimeo.com/video/${item.vimeoId}`} width="160" height="90" frameBorder="0" allow="picture-in-picture"></iframe>
			</IonLabel>

			<IonLabel>
				<div>
					<h2>{item.title}</h2>
					<p style={{ color: "darkgrey" }} ><i>{item.description ? item.description : "this lesson has no description."}</i></p>
				</div>
			</IonLabel>
		</IonItem>
	</dd>);

	const Topic = ({ item }) => {
		return (<>
			<dt>
				<IonItem color="light">
					<IonLabel> <h2>{item.title}</h2>
						<p><i>{item.description ? item.description : "this topic has no description."}</i></p>
					</IonLabel>
				</IonItem>
			</dt>
			{
				item.lessons && item.lessons.length > 0 && item.lessons.map((item) => (
					<Lesson key={item.id} item={item} />
				))
			}
		</>);
	}


	return dataSource && dataSource.length > 0 && dataSource.map((item, index) => (
		<dl>
			<Topic key={index} item={item} />
		</dl>
	));

}

export default CourseCurriculum;