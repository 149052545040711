import React, { Suspense } from 'react';
import { IonItem, IonLabel, IonSpinner } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getMaxElements } from '../../core/Functions';

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode } from "swiper";

import AuthorModule from '../main/authors/AuthorModule';
import CategoryModule from '../main/categories/CategoryModule';

import { GATrackSwipeToEnd } from '../../core/google';
import CourseItemTemplate from './CourseItemTemplate';
import { useAppContext } from '../shared/AppContext';

function shuffleCourses(array) {
	array.sort(() => Math.random() - 0.5);
}

const CourseRenderItem = ({ item, showPrice, showProgress }) => {
	return (<>
		<CourseItemTemplate key={1} item={item.item1} showPrice={showPrice} showProgress={showProgress} />
		{item.item2 && <CourseItemTemplate key={2} item={item.item2} showPrice={showPrice} showProgress={showProgress} />}
		{item.item3 && <CourseItemTemplate key={3} item={item.item3} showPrice={showPrice} showProgress={showProgress} />}
		{item.item4 && <CourseItemTemplate key={4} item={item.item4} showPrice={showPrice} showProgress={showProgress} />}
		{item.item5 && <CourseItemTemplate key={5} item={item.item5} showPrice={showPrice} showProgress={showProgress} />}
	</>);
}

export default function CourseSectionMultiple({ showPrice, title, courses, shuffle = false, addCategoriesModule = false, dsCategories, addAuthorsModule = false, showProgress = false, dsAuthors }) {


	const [appState, changeAppState] = useAppContext();

	if (courses.length == 0)
		return (<></>);

	if (shuffle)
		shuffleCourses(courses);

	return (
		<div style={{ marginBottom: 65 }}>
			{title &&
				<IonItem lines="none" >
					<IonLabel>
						<h1 dangerouslySetInnerHTML={{ __html: title }} />

					</IonLabel>
				</IonItem>}

			<Swiper
				//onSlideChange={() => console.log('slide change')}
				//onSwiper={(swiper) => console.log(swiper)}
				onReachEnd={() => showPrice && GATrackSwipeToEnd(title, courses.length)}
				spaceBetween={0}
				slidesPerView={appState.slidesPerView}
				freeMode={{
					enabled: true,
					momentumBounce: true,
					momentum: true,
					sticky: false,
					minimumVelocity: 0.02,
					momentumBounceRatio: 1,
					momentumRatio: 1,
					momentumVelocityRatio: appState.isIOS ? 0.5 : 0.9
				}}
				modules={[FreeMode]}
			>

				{courses.map((item, index) => {

					return (

						<SwiperSlide key={index}>
							<CourseRenderItem item={item} showPrice={showPrice} showProgress={showProgress} />
						</SwiperSlide>


					);
				})}

			</Swiper>

			{addCategoriesModule && <CategoryModule showTitle={false} dsLine1={dsCategories.row1} dsLine2={dsCategories.row2} dsLine3={dsCategories.row3} />}

			{addAuthorsModule && <AuthorModule showTitle={true} dsLine1={dsAuthors.row1} dsLine2={dsAuthors.row2} dsLine3={dsAuthors.row3} />}


		</div >
	);



}