import { gql } from 'graphql-request'
import AppConfig from '../../App.config';
import { executeGQL, GQClient } from './GQClient';


export const setCreateNewCourse = async (onlySaveDraft, loadDraft, title, basicInfo, importContent, courseContent) => {

	return await executeGQL(gql`mutation createNewCourse($draft: AppCourseBuilderInput!) {
		createNewCourse(draft: $draft) }`,
		{
			draft: {
				onlySaveDraft,
				draftId: loadDraft,
				title,
				basicInfo,
				importContent,
				courseContent
			}
		});
}

export const setToggleWishlist = async (courseId) => {
	return await executeGQL(gql`mutation toggleWishlist($courseId: Long!) {
		toggleWishlist(courseId: $courseId) }`, { courseId: courseId });
}


export const setLessonCompleted = async (lessonId) => {
	return await executeGQL(gql`mutation lessonCompleted($lessonId: Long!) {
		lessonCompleted(lessonId: $lessonId) }`, { lessonId: lessonId });
}

export const setRequestPartnerApproval = async (socialLink) => {

	return await executeGQL(gql`mutation requestPartnerApproval($socialLink: String!) {
		requestPartnerApproval(socialLink: $socialLink) }`,
		{
			socialLink: socialLink
		});
}



export const setCreateStripeSession = async (courseId, partner) => {

	return await executeGQL(gql`mutation createStripeSession($input: CreateStripeSessionInput!) {
		createStripeSession(input: $input) {sessionId, publicKey, errorMessage} }`,
		{
			input: {
				courseId: courseId,
				priceId: partner, //Using this field because it was already there and not being used. REFACTOR IT !
				successUrl: AppConfig.Stripe.PurchaseSuccessFallBack,
				failureUrl: AppConfig.Stripe.PurchaseFailFallBack
			}
		});
}

export const setCreatePlayList = async (playListName, lessonId) => {

	return await executeGQL(gql`mutation createPlayList($playListName: String!, $lessonId: Long!) {
		createPlayList(playListName: $playListName, lessonId: $lessonId) }`, { playListName: playListName, lessonId: lessonId });
}

export const setDeletePlayList = async (playListId) => {

	return await executeGQL(gql`mutation deletePlayList($playListId: Long!) {
		deletePlayList(playListId: $playListId) }`, { playListId: playListId });
}


export const setAddToPlayList = async (playListId, lessonId) => {

	return await executeGQL(gql`mutation addToPlayList($playListId: Long!, $lessonId: Long! ) {
		addToPlayList(playListId: $playListId, lessonId: $lessonId) }`, { playListId: playListId, lessonId: lessonId });
}

export const setRemoveFromPlayList = async (playListId, lessonId) => {

	return await executeGQL(gql`mutation removeFromPlayList($playListId: Long!, $lessonId: Long! ) {
		removeFromPlayList(playListId: $playListId, lessonId: $lessonId) }`, { playListId: playListId, lessonId: lessonId });
}

export const setUploadAgreement = async () => {
	return await executeGQL(gql`mutation addUploaderAgreement {
        addUploaderAgreement }`, {});
}

export const setAddToUserRolls = async (video) => {
	return await executeGQL(gql`mutation addVideoToUserReels($video: VideoInput!) {
					addVideoToUserReels(video: $video) }`, {
		video: video

	});
}

export const setAddTopic = async (video) => {
	return await executeGQL(gql`mutation newTopic($video: VideoInput!) {
					newTopic(video: $video) }`, {
		video: video

	});
}

export const setAddLesson = async (video) => {
	return await executeGQL(gql`mutation newLesson($video: VideoInput!) {
					newLesson(video: $video)}`, {
		video: video

	});
}

export const setAddVideo = async (video) => {
	return await executeGQL(gql`mutation newCourse($video: VideoInput!) {
					newCourse(video: $video)}`, {
		video: video

	});
}

export const setCourseAuthor = async (data) => {
	return await executeGQL(gql`mutation changeCourseAuthor($data: String!) {
					changeCourseAuthor(data: $data)	}`, {
		data: data

	});
}

export const setPushNotification = async (title, body, sendToEveryone, justInstructors, image, isTopic, to, extraData) => {
	return await executeGQL(gql`mutation sendPushNotification($notification: PushNotificationInput!) {
					sendPushNotification(notification: $notification) }`, {
		notification: {
			title: title,
			body: body,
			sendToEveryone: sendToEveryone,
			justInstructors: justInstructors,
			image: image,
			isTopic: isTopic,
			to: to,
			extraData: extraData
		}
	});
}

export const setReport = async (reportType, beginDate, endDate, hasAccess) => {
	return await executeGQL(gql`mutation createReport($filter: ExpenseFilterInput! ) {
					createReport(filter: $filter)}`, {
		filter: {
			reportType: reportType,
			beginDate: beginDate,
			endDate: endDate,
			sendTo: hasAccess
		}
	});
}

export const setNewKOMember = async (email, password, firstName, lastName, plan, nickname) => {
	return await executeGQL(gql`mutation createLegionMemberUser($user: UserInput!) {
					createLegionMemberUser(user: $user)	}`,
		{
			user: {
				Email: email,
				Password: password,
				FirstName: firstName,
				LastName: lastName,
				plan: plan,
				NickName: nickname
			}
		});
}

export const setRevokeKOAccess = async (email, password, firstName, lastName, plan, nickname) => {
	return await executeGQL(gql`mutation changeCourseAccess($access: CourseAccessInput!) {changeCourseAccess(access: $access)}`, {
		user: {
			Email: email,
			Password: password,
			FirstName: firstName,
			LastName: lastName,
			plan: plan,
			NickName: nickname
		}
	});
}

export const setCourseAccess = async (userEmail, courseThumbnail, courseId, hasAccess) => {
	return await executeGQL(gql`mutation changeCourseAccess($access: CourseAccessInput!) {changeCourseAccess(access: $access)}`, {
		access: {
			userEmail: userEmail,
			courseThumbnail: courseThumbnail,
			courseId: courseId,
			hasAccess: hasAccess
		}
	});
}

export const setExpense = async (dateAndTime, categoryId, instructorId, description, amount) => {
	return await executeGQL(gql`mutation addExpense($expense: ExpenseInput!) {
					addExpense(expense: $expense)}`, {
		expense: {
			dateAndTime: dateAndTime,
			categoryId: categoryId,
			instructorId: instructorId,
			description: description,
			amount: amount
		}

	});
}

export const setBookmark = async (lessonId) => {
	return await executeGQL(gql`mutation toggleLessonBookmark($lessonId: Long!) {
		toggleLessonBookmark(lessonId: $lessonId) {id, isFavorite} }`, { lessonId: lessonId });
}

export const setLog = async (event, screen, moreInfo) => {
	return await executeGQL(gql`mutation log($input: LogInput!) {
		log(input: $input) }`, { input: { event: event, screen: screen, moreInfo: moreInfo } });
}

export const setValidatePurchase = async (field1, field2, field3, field4) => {
	return await executeGQL(gql`mutation testingAccessObsolete($input: AccessDTOInput!) {
		testingAccessObsolete(input: $input) }`, { input: { field1: field1, field2: field2, field3: field3, field4: field4 } });
}

export const setClaimFreeCourse = async (courseId) => {
	return await executeGQL(gql`mutation claimPromotion($courseId: Long!) {
		claimPromotion(courseId: $courseId)	}`, { courseId: courseId });
}

export const setWpLogin = async (email, password) => {
	return await executeGQL(gql`mutation WordPressAutoLoginToken($login: LoginInput!) {
		WordPressAutoLoginToken(login: $login) }`, { login: { Email: email, Password: password } });
}

export const setRating = async (postId, content, rating) => {
	return await executeGQL(gql`mutation rateCourse($rating: TutorInput!) {
		rateCourse(rating: $rating) { id }
	}`, { rating: { postId: postId, content: content, rating: rating } });
}

export const setEulaAndGeneral = async () => {
	return await executeGQL(gql`
	mutation addEulaAndGeneral {
        addEulaAndGeneral
    }`, {});
}

export const setLogin = async (email, password, token) => {

	const client = await GQClient(false);

	return await executeGQL(gql`mutation UserLogin($login: LoginInput!) {
	UserLogin(login: $login) {token, isAdmin, isManager, isInstructor, hasSubscription, userId, userEmail, displayName, referralOfName, referralOfId, linkKey} }`,
		{
			login: {
				Email: email,
				Password: password,
				Captcha: token
			}
		}, client);
}

export const setUser = async (email, password, firstName, lastName, plan, nickName) => {

	const client = await GQClient(false);

	return await executeGQL(gql`mutation CreateUser($user: UserInput!) {
	CreateUser(user: $user) {token, isAdmin, isManager, isInstructor, userId, userEmail, displayName, referralOfName, referralOfId, linkKey} }`,
		{
			user: {
				Email: email,
				Password: password,
				FirstName: firstName,
				LastName: lastName,
				plan: plan,
				NickName: nickName
			}
		}, client);
}

export const setFcmToken = async (token) => {

	const client = await GQClient(false);

	return await executeGQL(gql`mutation saveFcmToken($token: String!) {
	saveFcmToken(token: $token) }`,
		{
			token: token
		}, client);
}
