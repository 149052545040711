import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router';

export const useSetPartner = () => {
	const [cookies, setCookie] = useCookies(['partner']);

	const location = useLocation();

	let partner = "";

	let query;

	try {
		//Query String
		if (location.search) {
			query = new URLSearchParams(location.search);
		}

		partner = query.get("partner");

		if (partner) {
			setCookie('partner', partner, { path: '/' });
		}

	}
	catch
	{
	}
}


export const useGetPartner = () => {
	const [cookies, setCookie] = useCookies(['partner']);

	return cookies.partner ? cookies.partner : "";
}
