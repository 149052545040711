import React from 'react';
import { IonLabel } from '@ionic/react';

import CategoryList from './CategoryList';

const CategoryModule = ({ showTitle, dsLine1, dsLine2, dsLine3 }) => {

	return (<div style={{ marginLeft: 12, marginTop: 50 }}>
		{showTitle && ((dsLine1.length + dsLine2.length + dsLine3.length) > 0) && <IonLabel style={{ marginLeft: 8 }}><h2>More Categories</h2></IonLabel>}
		<CategoryList dataSource={dsLine1} />
		<CategoryList dataSource={dsLine2} />
		<CategoryList dataSource={dsLine3} />
	</div>);

}

export default CategoryModule;