import AppConfig from "../App.config";
import { loggedUserData } from "../components/auth/AuthCore";


export function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getViewers() {
	const d = new Date();
	let s = d.getSeconds();
	let min = Math.floor(s / 3);
	let max = Math.ceil(s);

	if (min < 1)
		min = getRandomInt(2, 5);

	if (max < 10)
		max = getRandomInt(6, 21);

	if (max < min)
		max = min + getRandomInt(1, min);

	if (max > 30)
		max = getRandomInt(min + 5, 29);

	return getRandomInt(min, max);
}

export function getMyAffiliateCode() {
	const userData = loggedUserData();

	if (userData.linkKey && userData.linkKey.trim() != "" && userData.linkKey.trim() != "application_submited")
		return userData.linkKey;
	else
		return "NONE";
}

export function getMyAffiliateLink() {
	const code = getMyAffiliateCode();

	if (code && code.trim() != "NONE")
		return AppConfig.AppHome + `/home?partner=${code}`;
	else
		return "NONE";
}

export function isIOSDevice() {

	const system = getMobileOperatingSystem();

	return system === 'iOS' || system === 'Mac' || system === 'Safari';
}

export function getMobileOperatingSystem() {
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;

	if (userAgent.indexOf('Mac OS X') != -1) {
		return "Mac";
	}

	// Windows Phone must come first because its UA also contains "Android"
	if (/windows phone/i.test(userAgent)) {
		return "Microsoft";
	}

	if (/android/i.test(userAgent)) {
		return "Android";
	}

	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return "iOS";
	}

	if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
		return "Safari";
	}

	return "Desktop";
}

export function nl2br(str) {
	return str.replace(/(\r\n|\n\r|\r|\n)/g, '&#128312;');
}

export const splitCategories = (dataSource) => {

	if (dataSource.length < 3) {
		return {
			row1: dataSource,
			row2: [],
			row3: []
		}
	}

	const threePartIndex = Math.ceil(dataSource.length / 3);

	let list = [...dataSource];

	const thirdPart = list.splice(-threePartIndex);
	const secondPart = list.splice(-threePartIndex);
	const firstPart = list;

	return {
		row1: firstPart,
		row2: secondPart,
		row3: thirdPart
	}
}


export const setMaxElements = () => {
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;

	/*
		
		sm	540px	Set grid width to 540px when (min-width: 576px)
		md	720px	Set grid width to 720px when (min-width: 768px)
		lg	960px	Set grid width to 960px when (min-width: 992px)
		xl	1140px	Set grid width to 1140px when (min-width: 1200px)
		
		*/
	let max = 1.35;

	if (userAgent.indexOf('Instagram') < 0) {
		if (window.innerWidth <= 576) {
			max = 1.35;
		} else if (window.innerWidth > 576 && window.innerWidth <= 768) {
			max = 1.35;
		} else if (window.innerWidth > 768 && window.innerWidth <= 922) {
			max = 2.35;
		} else {
			max = 4.35;
		}
	}

	localStorage.setItem("maxElements", max);
}

export const getMaxElements = () => {
	return Number(localStorage.getItem("maxElements"));
}


export const breakInChuncks = (chunk, array) => {

	let result = [];

	if (!array || array.length == 0)
		return result;


	let i, j, temporary;
	for (i = 0, j = array.length; i < j; i += chunk) {
		temporary = array.slice(i, i + chunk);
		result.push({ item1: temporary[0], item2: temporary[1], item3: temporary[2], item4: temporary[3], item5: temporary[4] });
	}

	return result;
}

export const splitArray = (chunkSize, array) => {

	let result = [];

	for (let i = 0; i < array.length; i += chunkSize) {
		const chunk = array.slice(i, i + chunkSize);
		result.push(chunk);
	}

	return result;
}

export const Shuffle = (array) => {
	var currentIndex = array.length, randomIndex;

	// While there remain elements to shuffle...
	while (currentIndex != 0) {

		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex], array[currentIndex]];
	}

	return array;
}

export const Captcha = (email) => {
	return email.substr(0, 5) + email.substr(2, 3);
}

export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const formatDate = (dateString) => {

	return dateString.split("T")[0];

}

export const addZeroes = (num) => {
	if (!num || num == 0)
		return "5.0";

	const dec = num.toString().split('.')[1]
	const len = dec && dec.length > 1 ? dec.length : 1
	return Number(num).toFixed(len)
}

export const isCurrency = (currStr) => {

	var regex = /^\d+(?:\.\d{0,2})$/;
	return regex.test(currStr);

}

export const Test = () => {

	let today = new Date(Date.now());

	const salt =
		zeroPad(today.getUTCFullYear(), 4) +
		zeroPad(today.getUTCMonth(), 2) +
		zeroPad(today.getUTCDate(), 2) +
		zeroPad(today.getUTCHours(), 2) +
		zeroPad(today.getUTCMinutes(), 2) +
		zeroPad(today.getUTCSeconds(), 2);

	let increment = Number(salt[0]) + Number(salt[13]) % 2;
	if (increment == 0)
		increment = 2;

	let result = 0;
	for (let i = 0; i < 14; i = i + increment) {
		result = result + Number(salt[i]);
	}

	let p = 1;
	// if (Platform.OS === 'android') {
	// 	p = '0';
	// }
	// else if (Platform.OS === 'ios') {
	// 	p = '9';
	// }

	return p + salt + result;
}

export const getExcerpt = (str) => {
	const limit = 200;
	const regex = /(<([^>]+)>)/ig;

	const cleanStr = str.replace(regex, '');

	return cleanStr == '' ? '' : cleanStr.substr(0, cleanStr.lastIndexOf(' ', limit)) + '...';
}

export const convertHMS = (timeString) => {
	const arr = timeString.split(":");
	return (Number(arr[0]) * 60) + Number(arr[1]);
}


export const getSourceToPlay = (downloadedList, item) => {
	try {
		let idx = downloadedList.findIndex((obj => obj.vimeoId == item.vimeoId));

		if (idx >= 0) {
			return downloadedList[idx].location;
		}
		else {
			return item.defaultPlayUrl;
		}
	} catch (err) {
		return item.defaultPlayUrl;
	}
}
