import React from 'react';
import { IonToolbar, IonSegment, IonSegmentButton, IonLabel, IonIcon } from '@ionic/react';
import { bookmarksOutline, cloudUploadOutline, listOutline, personOutline } from 'ionicons/icons';
import Courses from '../../courses/Courses';
import Playlists from './Playlists';
import AccountTab from './AccountTab';
import PageBase from '../../shared/PageBase';
import CourseUpload from './CourseUpload';

const Dashboard = () => {

	const [activeTab, setActiveTab] = React.useState("enrolled");


	const ActiveTab = () => {
		switch (activeTab) {
			case "enrolled":
				return (<Courses />)
			case "playlist":
				return (<Playlists />);
			case "account":
				return (<AccountTab />);

			default:
				return (<></>);
		}
	}

	return (
		<PageBase title='Dashboard' robots="noindex, nofollow" description="User Dashboard" canonical="https://jiujitsux.app/dashboard" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">
			<IonToolbar>
				<IonSegment value={activeTab} scrollable={true} onIonChange={e => setActiveTab(e.detail.value)}>
					<IonSegmentButton value="enrolled">
						<IonIcon icon={listOutline} />
						<IonLabel>Enrolled</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton value="playlist">
						<IonIcon icon={bookmarksOutline} />
						<IonLabel>PlayLists</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton value="account">
						<IonIcon icon={personOutline} />
						<IonLabel>Account</IonLabel>
					</IonSegmentButton>
				</IonSegment>
			</IonToolbar>

			<ActiveTab />

		</PageBase>
	);
}

export default Dashboard;
