import { IonLabel, IonProgressBar, IonGrid, IonRow, IonCol } from '@ionic/react';
import React from 'react';
import Stars from './Stars';

export default function RatingSummary({ summary, title }) {

	const Rating = ({ totalReview, thisone, stars }) => {
		return (
			<IonGrid>
				<IonRow >
					<IonCol >
						<IonProgressBar color='medium' style={{ borderRadius: 7, height: 20, marginRight: 10 }} value={thisone / totalReview} ></IonProgressBar>
					</IonCol>
					<IonCol >
						<Stars rating={stars} showNumber={false} />
						<IonLabel style={{ fontWeight: "normal", fontSize: 10, marginTop: 5, marginLeft: 3 }}>{Math.round((thisone / totalReview) * 100)}%</IonLabel>
					</IonCol>
				</IonRow>
			</IonGrid>);
	}


	const formatRating = (n) => {
		if (Number.isInteger(n))
			return `${n}.0`;
		else
			return n;
	}

	if (!summary)
		return null;

	return (<>
		<IonGrid>
			<IonRow>
				<IonCol size="3">
					<IonLabel position='stacked' style={{ fontSize: 40, fontWeight: "bold" }} color="dark-gold">{formatRating(summary.rating)}</IonLabel>
				</IonCol>
				<IonCol>
					<IonLabel position='stacked' style={{ fontSize: 18, fontWeight: "bold", fontStyle: "italic" }} color="dark-gold">{title}</IonLabel><br />
					<Stars rating={summary.rating} showNumber={false} size="large" />
				</IonCol>
			</IonRow>
		</IonGrid>

		<Rating totalReview={summary.totalReviews} stars={5} thisone={summary.fiveStars} />
		<Rating totalReview={summary.totalReviews} stars={4} thisone={summary.fourStars} />
		<Rating totalReview={summary.totalReviews} stars={3} thisone={summary.threeStars} />
		<Rating totalReview={summary.totalReviews} stars={2} thisone={summary.twoStars} />
		<Rating totalReview={summary.totalReviews} stars={1} thisone={summary.oneStars} />

	</>);
}