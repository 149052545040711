import React, { useState } from 'react';

import { zeroPad } from '../../core/Functions';

import { IonItem, IonLabel, IonList, IonGrid, IonRow, IonCol, useIonViewWillLeave, useIonViewDidEnter } from '@ionic/react';

import ReactPlayer from "react-player";
import { useLocation } from 'react-router';
import { getCourseFreeLessons } from '../../core/backend/GQLQueries';
import PageBase from '../shared/PageBase';
import { getSourceToPlay } from '../../core/Functions';
import CourseItemTemplateSkeleton from '../courses/skeleton/CourseItemTemplateSkeleton';




const useParams = () => {
	const location = useLocation();

	let courseId = 0;
	let courseTitle = "";

	let query;

	try {
		//Query String
		if (location.search) {
			query = new URLSearchParams(location.search);
		}

		//courseId
		if (location.state && location.state.courseId)
			courseId = location.state.courseId;
		else
			courseId = query.get("courseId");

		//courseId
		if (location.state && location.state.courseTitle)
			courseTitle = location.state.courseTitle;
		else
			courseTitle = query.get("courseTitle");




		return { courseId: Number(courseId), courseTitle };
	}
	catch
	{
		return { courseId: Number(courseId), courseTitle };
	}
}


export default function FlowPlayerGuests() {

	let courseId = 0;
	let courseTitle = "";

	({ courseId, courseTitle } = useParams());

	const [called, setCalled] = React.useState(true);
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState(false);


	const [playList, setPlayList] = React.useState([]);

	const [idx, setIdx] = React.useState(0);
	const [paused, setPaused] = useState(false);
	const [muted, setMuted] = useState(false);



	const handleBlur = () => {
		setPaused(true);
	}

	const handleEnter = () => {
		setPaused(false);
	}

	useIonViewWillLeave(() => {
		handleBlur();
	});

	useIonViewDidEnter(() => {
		setMuted(false);
		setPaused(false);
	});


	const getData = (courseId, abortController) => {

		getCourseFreeLessons(courseId, abortController)
			.then(result => {

				let tmpList = [];
				let tmpListIdx = 0;

				result.data.courseFreeLessons.forEach(function (item) {
					tmpList.push({
						key: tmpListIdx++,
						id: item.id,
						vimeoId: item.vimeoId,
						title: item.title,
						ignore: false,
						playThumbnail: item.thumbnail,
						downloadUrl: item.download,
						duration: item.duration,
						watchPercent: 0,
						playUrl: getSourceToPlay([], item)
					});
				});

				setPlayList(tmpList);
				setLoading(false);
			})
			.catch(err => {
				if (err) {
					setError(true);
					setLoading(false);
				}
			});
	}

	React.useEffect(() => {

		let abortController = new AbortController();

		window.addEventListener("blur", handleBlur);
		window.addEventListener("enter", handleEnter);

		getData(courseId, abortController)

		return () => {
			window.removeEventListener("blur", handleBlur);
			window.removeEventListener("enter", handleEnter);
			abortController.abort();
		}

	}, [courseId]);


	const onEnd = () => {
		next();
	};

	const next = () => {
		let newIdx = idx + 1;
		if (newIdx == playList.length)
			newIdx = 0;
		setIdx(newIdx);
		setPaused(false);
	};

	const Item = ({ item, onPress, background, fontWeight, fontColor, fontStyle }) => (
		<IonItem lines="full" key={item.id} onClick={onPress} color={background}>
			<IonLabel color={fontColor} style={{ fontStyle: fontStyle, fontWeight: fontWeight }}>{zeroPad(item.key + 1, 2)} - {item.title}   ({item.duration})</IonLabel>
			{/* <DownloadButton item={item} /> */}
		</IonItem>
	);

	const PlayList = () => {

		if (!playList || playList.length == 0)
			return (<></>);
		return (
			<IonList >
				{playList.map((item, index) => {
					const fontWeight = item.key === idx ? "bold" : "normal";
					const fontColor = item.key === idx ? "secondary" : "";
					const fontStyle = item.key === idx ? "normal" : "italic";

					const background = item.key === idx ? "light" : "";

					return (
						<Item key={index}
							item={item}
							onPress={() => {
								setIdx(playList.map(e => e.playUrl).indexOf(item.playUrl));
							}}
							fontWeight={fontWeight}
							fontColor={fontColor}
							fontStyle={fontStyle}
							background={background}
						/>
					);
				})}
			</IonList>);
	}

	const PageContent = () => {
		return (<>
			<IonItem>
				<IonLabel ><h1>{playList && playList[idx].title}</h1></IonLabel><br />
			</IonItem>
			<IonGrid>
				<IonRow class="ion-justify-content-center">
					<IonCol size={12} size-xl={6} size-lg={6} size-md={12} size-sm={12}>

						<ReactPlayer
							muted={muted}
							playsInline
							playsinline={true}
							playing={!paused}
							onEnded={onEnd}
							width="100%"
							height="auto"
							controls={true}
							url={playList && playList[idx].playUrl}
							config={{
								file: {
									attributes: {
										controlsList: 'nodownload'
									}
								}
							}}
						/>
					</IonCol>
					<IonCol size={12} size-xl={6} size-lg={6} size-md={12} size-sm={12}>
						<PlayList />
					</IonCol>
				</IonRow>
			</IonGrid>
		</>);
	}

	if (called && loading) return <CourseItemTemplateSkeleton />;
	if (called && error) return <CourseItemTemplateSkeleton />;

	return (<PageBase title={`Player: ${courseTitle}`} robots="noindex, nofollow" description="Video Player" canonical="https://jiujitsux.app/home/flow-player" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">
		<PageContent />
	</PageBase>);
};
