import React, { useState } from "react";

import { IonItem, IonLabel, IonInput, IonButton, IonIcon } from "@ionic/react";
import { add, checkmark, close, create } from "ionicons/icons";
import { Droppable, Draggable } from "react-beautiful-dnd";

import { getListStyle } from "./operations";
import Lessons from "./Lessons";

const Topic = ({ index, item, onSaveTopic, onAddLesson, onSaveLesson, onDeleteLesson }) => {

	const [isEditing, setIsEditing] = useState(false);

	const [title, setTitle] = useState(item.title);
	const [description, setDescription] = useState(item.description);

	const saveIt = () => {
		onSaveTopic(index, title, description);
		setIsEditing(false);
	}

	const editIt = () => {
		setTitle(item.title);
		setDescription(item.description);

		setIsEditing(true)
	}

	return (
		<Draggable draggableId={`${index}`} index={index} >
			{(provided, snapshot) => (

				<div {...provided.draggableProps} ref={provided.innerRef}>


					{!isEditing
						? <IonItem color={snapshot.isDragging ? "tertiary" : "secondary"} {...provided.dragHandleProps}>
							<div>
								<button style={{ marginRight: 5, borderWidth: 2, borderColor: "black", borderRadius: 25, backgroundColor: "transparent" }} onClick={() => editIt()}>
									<IonIcon size="large" color="light" slot="icon-only" icon={create} ></IonIcon>
								</button>
							</div>
							<IonLabel> <h2>{item.title}</h2>
								<p><i>{item.description ? item.description : "this topic has no description."}</i></p>

							</IonLabel>

							<IonButton slot="end" onClick={() => onAddLesson(index)}>
								<IonIcon slot="icon-only" icon={add} ></IonIcon>
							</IonButton>
						</IonItem>
						: <IonItem color="secondary" {...provided.dragHandleProps}>
							<div>
								<button style={{ marginBottom: 5, borderWidth: 2, borderColor: "black", borderRadius: 25, backgroundColor: "transparent" }} onClick={() => saveIt()}>
									<IonIcon size="large" color="success" slot="icon-only" icon={checkmark} ></IonIcon>
								</button>
								<br />
								<button style={{ borderWidth: 2, borderColor: "black", borderRadius: 25, backgroundColor: "transparent" }} onClick={() => setIsEditing(false)}>
									<IonIcon size="large" color="danger" slot="icon-only" icon={close} ></IonIcon>
								</button>
							</div>
							<div>
								<IonInput maxlength={50} type="text" placeholder="Enter topic title" value={title} onIonChange={e => setTitle(e.detail.value)} > </IonInput>

								<IonInput maxlength={50} type="text" placeholder="Enter topic description" value={description} onIonChange={e => setDescription(e.detail.value)} > </IonInput>
							</div>

						</IonItem>
					}


					<Droppable droppableId={`${index}`} type="lesson" >
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								style={getListStyle(snapshot.isDraggingOver)}
								{...provided.droppableProps}
							>
								<Lessons items={item.lessons} topicIndex={index} onDeleteLesson={onDeleteLesson} onSaveLesson={onSaveLesson} />
								{provided.placeholder}
							</div>
						)}
					</Droppable>


				</div>
			)
			}
		</Draggable >
	);
}

const Topics = ({ dataSource, onAddTopic, onSaveTopic, onAddLesson, onSaveLesson, onDeleteLesson }) => {

	return (<><IonButton onClick={() => onAddTopic()}>New Topic</IonButton>
		<div style={{ display: "flex", flexFlow: "row" }}>

			{dataSource.map((item, index) => (
				<div key={index} style={{ margin: 10 }}>
					<Topic
						item={item}
						index={index}
						onSaveTopic={onSaveTopic}
						onAddLesson={onAddLesson}
						onSaveLesson={onSaveLesson}
						onDeleteLesson={onDeleteLesson} />
				</div>
			))}
		</div>
	</>);
}

export default Topics;