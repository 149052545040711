import React, { useState, useContext } from "react";
import { getMaxElements, isIOSDevice } from "../../core/Functions";

const AppContext = React.createContext();

export function useAppContext() {
	return useContext(AppContext);
}

export function AppContextProvider({ children }) {

	const [appState, setAppState] = useState({
		isThemeDark: true,
		newMessages: "new",
		isIOS: isIOSDevice(),
		slidesPerView: getMaxElements()
	});
	//const [isThemeDark, setIsThemeDark] = useState(true);

	function changeAppState(newState) {

		setAppState(newState);

		if (!newState.isIOS) {
			localStorage.setItem("isDark", newState.isThemeDark);

			if (newState.isThemeDark != appState.isThemeDark)
				document.body.classList.toggle('dark');
		}
	}

	React.useEffect(() => {
		//TODO: Diff
		if (!appState.isIOS) {

			const id = localStorage.getItem("isDark");
			const isDark = id && id == "true";

			setAppState(
				prevState => ({
					...prevState,
					isThemeDark: isDark
				})
			);

			if (isDark)
				document.body.classList.toggle('dark');
		}
	}, []);

	return (
		<AppContext.Provider value={[appState, changeAppState]} >
			{children}
		</AppContext.Provider>
	);

}