import { GraphQLClient } from 'graphql-request';

import AppConfig from '../../App.config';

export const GQClient = async (useAuthorization = true, endpoint = AppConfig.BackendEndpoint) => {
	const client = new GraphQLClient(endpoint)

	if (useAuthorization) {
		const credentials = localStorage.getItem("userdata");
		if (credentials) {
			const token = JSON.parse(credentials).token;
			client.setHeader('authorization', `Bearer ${token}`);
		}
	}

	return client;
}

export const executeGQL = async (query, variables, client = undefined, abortController = undefined) => {

	if (client == undefined) {
		client = await GQClient();
	}

	let args = { document: query, variables: variables };

	if (abortController)
		args.signal = abortController.signal;

	return new Promise((resolve, reject) => {

		try {
			client.request(args).then((data) => {

				resolve({
					success: true,
					data: data
				});

			});
		}
		catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.log(error);
			}

			if (error.name === 'AbortError') {
				reject(undefined);
			}
			else {
				reject({ success: false, data: error });
			}
		}
	});

}