
import React from 'react';

import ReviewSummary from './ReviewSummary';
import Splash from '../../shared/Splash';
import Error from '../../shared/Error';

import { getAllReviews, getAuthorReviews, getSiteReviews } from '../../../core/backend/GQLQueries';

import { useLocation } from "react-router-dom";
import PageBase from '../../shared/PageBase';

import { GATrackOpenedReviews } from '../../../core/google';


const useParams = () => {
	const allReviewsId = "WHOLE-SITE-REVIEWS";
	const location = useLocation();

	let id = 0;
	let title = "";
	let isAllFromAuthor = false;

	let query;

	try {
		//Query String
		if (location.search) {
			query = new URLSearchParams(location.search);
		}

		//Id
		if (location.state && location.state.id)
			id = location.state.id;
		else
			id = query.get("id");

		//Title
		if (location.state && location.state.title)
			title = location.state.title;
		else
			title = query.get("title");

		//Stripe
		if (location.state && location.state.isAllFromAuthor)
			isAllFromAuthor = location.state.isAllFromAuthor;
		else
			isAllFromAuthor = query.get("isAllFromAuthor") == "true";


		return { id: Number(id), title, isAllFromAuthor };
	}
	catch
	{
		return { id: Number(id), title, isAllFromAuthor };
	}
}


export default function AllReviews() {

	let id = 0;
	let title = "";
	let isAllFromAuthor = false;

	({ id, title, isAllFromAuthor } = useParams());

	const [dataSource, setDataSource] = React.useState([]);

	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState(false);


	const loadCourseReviews = (abortController) => {
		getAllReviews(id, abortController).then(result => {

			GATrackOpenedReviews(id, title);

			setDataSource(result.data.courseReviews);

			setLoading(false);
		}).catch(err => {
			if (err) {
				setError(true);
				setLoading(false);
			}
		});

	}

	const loadAuthorReviews = (abortController) => {
		getAuthorReviews(id, abortController).then(result => {

			GATrackOpenedReviews(id, title);

			setDataSource(result.data.authorReviews);

			setLoading(false);
		}).catch(err => {
			if (err) {
				setError(true);
				setLoading(false);
			}
		});
	}

	const loadAllReviews = (abortController) => {
		getSiteReviews(abortController).then(result => {

			GATrackOpenedReviews(id, title);

			setDataSource(result.data.allReviews);

			setLoading(false);
		}).catch(err => {
			if (err) {
				setError(true);
				setLoading(false);
			}
		});
	}

	const getTitle = () => {

		return !isAllFromAuthor ? "Course Rating" : (title === 'WHOLE-SITE-REVIEWS' ? "Jiu Jitsu X Rating" : "Author Rating");
	}

	React.useEffect(() => {

		let abortController = new AbortController();

		if (isAllFromAuthor) {

			if (title === 'WHOLE-SITE-REVIEWS')
				loadAllReviews(abortController);
			else
				loadAuthorReviews(abortController);
		}
		else
			loadCourseReviews(abortController);

		return () => {
			abortController.abort();
		}

	}, [id, title, isAllFromAuthor]);


	if (loading) return <Splash />;
	if (error) return <Error />;

	return (<PageBase title={`All Reviews : ${id}:${title}`} robots="noindex, nofollow" description="Find out what users are talking about Jiu Jitsu X. Read Now!" canonical="https://jiujitsux.app/home/all-reviews" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo" >
		<ReviewSummary title={getTitle()} summary={dataSource} showAllReviews={true} showLink={isAllFromAuthor} />
	</PageBase>);
}