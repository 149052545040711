import React, { useState } from 'react';

import { zeroPad } from '../../core/Functions';

import { IonIcon, IonItem, IonLabel, IonList, IonGrid, IonRow, IonCol, IonLoading, useIonViewWillLeave, useIonViewDidEnter, useIonToast, useIonActionSheet, IonItemSliding, IonItemOptions, IonItemOption, IonText } from '@ionic/react';
import { heart, listOutline, addCircleOutline, bookmarkOutline, checkmark, skull, happy, checkmarkCircleOutline, checkmarkDoneCircle, checkmarkDoneOutline, infiniteOutline } from 'ionicons/icons';

import ReactPlayer from "react-player";
import { useLocation } from 'react-router';
import { setAddToPlayList, setCreatePlayList, setLessonCompleted } from '../../core/backend/GQLMutations';
import { getAllPlayLists } from '../../core/backend/GQLQueries';
import PageBase from '../shared/PageBase';
import { useAppPlayerContext } from '../shared/AppPlayerContext';
import { GATrackAddedToPlaylist } from '../../core/google';
import { IsAuthenticated } from '../auth/AuthCore';

const useParams = (markedLesson) => {

	const [playerPlayList, setPlayerPlayList] = useAppPlayerContext();

	const location = useLocation();

	let courseId = 0;
	let courseTitle = "";
	let playList = undefined;
	let initialPlayUrl = "";
	let startIdx = 0;

	if (location.state) {
		({ courseId, playList, initialPlayUrl, courseTitle } = location.state);

		if (playList == undefined)
			playList = playerPlayList;

		if (!initialPlayUrl)
			initialPlayUrl = playList[0].playUrl;

		if (playList)
			startIdx = playList.map(e => e.playUrl).indexOf(initialPlayUrl);
	}

	if (playList) {
		for (const lesson of playList) {
			if (lesson.id === markedLesson.id) {
				lesson.watchPercent = markedLesson.status;
				break;
			}
		}
	}


	return { courseId, courseTitle, playList, startIdx };
}


export default function FlowPlayer() {

	const [markedLesson, setMarkedLesson] = useState({ id: 0, status: 0 });

	const [presentPlayLists, dismissPlayLists] = useIonActionSheet();
	const [present, dismiss] = useIonToast();
	const [showLoading, setShowLoading] = useState(true);

	let courseId = 0;
	let courseTitle = "";
	let playList = undefined;
	let startIdx = 0;

	({ courseId, playList, courseTitle, startIdx } = useParams(markedLesson));

	const [playLists, setPlayLists] = React.useState([]);
	const [idx, setIdx] = React.useState(startIdx);
	const [paused, setPaused] = useState(false);
	const [muted, setMuted] = useState(false);

	const handleBlur = () => {
		setPaused(true);
	}

	const handleEnter = () => {
		setPaused(false);
	}

	useIonViewWillLeave(() => {
		handleBlur();
	});

	useIonViewDidEnter(() => {
		setMuted(false);
		setPaused(false);
	});



	const showPlayLists = (lessonId) => {

		var fav = [{
			text: 'Favorite',
			icon: heart,
			handler: () => {
				addToFavorites(lessonId);
			}
		}];

		var nova = [{
			text: 'New PlayList',
			icon: addCircleOutline,
			handler: () => {
				addToNewPlaylist(lessonId);
			}
		}];

		var markAsComplete = [{
			text: 'Toggle completed',
			icon: checkmarkDoneCircle,
			role: 'cancel',
			handler: () => {
				saveProgress(lessonId);
			}
		}];

		var bts = playLists.map(function (item) {
			return {
				text: item.name,
				icon: bookmarkOutline,
				handler: () => {
					addToPlayList(item.id, lessonId);
				}
			};
		});

		presentPlayLists({
			animated: true,
			mode: "ios",
			header: "Add Lesson to Playlist",
			buttons: fav.concat(bts).concat(nova).concat(markAsComplete),
		});
	}

	React.useEffect(() => {


		let abortController = new AbortController();

		if (IsAuthenticated())
			listAllPlayLists(abortController);

		window.addEventListener("blur", handleBlur);
		window.addEventListener("enter", handleEnter);

		return () => {
			window.removeEventListener("blur", handleBlur);
			window.removeEventListener("enter", handleEnter);
			abortController.abort();
		}

	}, []);


	const onEnd = () => {
		saveProgress(playList[idx].id);
		next();
	};

	const getToggleNewStatus = (lessonId) => {
		for (const lesson of playList) {
			if (lesson.id === lessonId) {
				return lesson.watchPercent == 1 ? 0 : 1;
			}
		}
	}

	const saveProgress = (lessonId) => {

		setMarkedLesson({ id: lessonId, status: getToggleNewStatus(lessonId) });
		setLessonCompleted(lessonId).then(result => {
			console.log(result);
		}).catch(err => {
			console.log(err);
		});
	}

	const next = () => {
		let newIdx = idx + 1;
		if (newIdx == playList.length)
			newIdx = 0;
		setIdx(newIdx);
		setPaused(false);
	};

	const Item = ({ item, onPress, background, fontWeight, fontColor, fontStyle }) => (

		<IonItemSliding>
			<IonItem lines="full" key={item.id} onClick={onPress} color={background}>
				<IonIcon icon={item.watchPercent == 1 ? checkmarkDoneOutline : infiniteOutline} slot="end" color={item.watchPercent == 1 ? "success" : "medium"} />
				<IonText color={fontColor} style={{ fontStyle: fontStyle, fontWeight: fontWeight }}>{zeroPad(item.key + 1, 2)} : {item.title}   ({item.duration})</IonText>
				{/* <DownloadButton item={item} /> */}
			</IonItem>

			<IonItemOptions side="start">
				<IonItemOption onClick={() => showPlayLists(item.id)} color="secondary">
					<IonIcon size="large" icon={listOutline} />
				</IonItemOption>
			</IonItemOptions>

		</IonItemSliding>
	);

	const PlayList = () => {

		if (!playList || playList.length == 0)
			return (<></>);
		return (
			<IonList >
				{playList.map((item, index) => {
					const fontWeight = item.key === idx ? "bold" : "normal";
					const fontColor = item.key === idx ? "secondary" : "";
					const fontStyle = item.key === idx ? "normal" : "italic";

					const background = item.key === idx ? "light" : "";

					return (
						<Item key={index}
							item={item}
							onPress={() => {
								setIdx(playList.map(e => e.playUrl).indexOf(item.playUrl));
							}}
							fontWeight={fontWeight}
							fontColor={fontColor}
							fontStyle={fontStyle}
							background={background}
						/>
					);
				})}
			</IonList>);
	}

	const listAllPlayLists = (abortController) => {

		getAllPlayLists(abortController).then(result => {
			setPlayLists(result.data.allPlayLists);
			//setShowLoading(false);
		}).catch(err => {
			if (err) {
				present({
					buttons: [{ text: 'X', handler: () => dismiss() }],
					message: 'We Could not retrieve all playlists',
					duration: 1000,
					icon: skull,
					color: "warning"
				});
			}
		});
	}

	const addToNewPlaylist = (lessonId) => {
		//Removing the Loading makes video keep playing because session state is not updated
		//setShowLoading(true);
		var plName = "Playlist #".concat((playLists.length + 1).toString());
		setCreatePlayList(plName, lessonId).then(result => {

			if (!result.success) {
				present({
					buttons: [{ text: 'X', handler: () => dismiss() }],
					message: 'Oh no! We found problems. Try again.',
					duration: 2000,
					icon: skull,
					color: "warning"
				});
			}
			else {
				GATrackAddedToPlaylist(courseId, courseTitle, playList ? playList[idx].title : "no-title");
				present({
					buttons: [{ text: 'X', handler: () => dismiss() }],
					message: result.data.createPlayList ? 'Added Successfully' : 'You cannot have more than 10 Playlists.',
					duration: 2000,
					icon: result.data.createPlayList ? checkmark : happy
				});
			}

			listAllPlayLists();
		}).catch(e => {
			present({
				buttons: [{ text: 'X', handler: () => dismiss() }],
				message: 'Oh no! We are sorry. Try again.',
				duration: 2000,
				icon: skull,
				color: "danger"
			});
			//setShowLoading(false);
		});
	}

	const addToFavorites = (lessonId) => {
		addToPlayList(-1, lessonId);
	}

	const addToPlayList = (id, lessonId) => {
		//setShowLoading(true);

		setAddToPlayList(id, lessonId).then(result => {

			if (!result.success) {
				present({
					buttons: [{ text: 'X', handler: () => dismiss() }],
					message: 'Oh no! We found problems. Try again.',
					duration: 2000,
					icon: skull,
					color: "warning"
				});
			}
			else {
				GATrackAddedToPlaylist(courseId, courseTitle, playList ? playList[idx].title : "no-title");

				present({
					buttons: [{ text: 'X', handler: () => dismiss() }],
					message: result.data.addToPlayList ? 'Added Successfully' : 'The Video is part of this playlist already.',
					duration: 2000,
					icon: result.data.addToPlayList ? checkmark : happy
				});
			}

			//setShowLoading(false);

		}).catch(e => {
			present({
				buttons: [{ text: 'X', handler: () => dismiss() }],
				message: 'Oh no! We are sorry. Try again.',
				duration: 2000,
				icon: skull,
				color: "danger"
			});
			//setShowLoading(false);
		});
	}

	const PageContent = () => {
		return (<>
			<IonItem>
				<IonLabel ><h1>{playList && playList[idx].title}</h1></IonLabel><br />
				<IonIcon size="large" icon={listOutline} onClick={() => showPlayLists(playList[idx].id)} />
			</IonItem>
			<IonGrid>
				<IonRow class="ion-justify-content-center">
					<IonCol size={12} size-xl={6} size-lg={6} size-md={12} size-sm={12}>

						<ReactPlayer
							muted={muted}
							playsInline
							playsinline={true}
							playing={!paused}
							onEnded={onEnd}
							width="100%"
							height="auto"
							controls={true}
							url={playList && playList[idx].playUrl}
						// config={{
						// 	file: {
						// 		attributes: {
						// 			controlsList: 'nodownload'
						// 		}
						// 	}
						// }}
						/>
					</IonCol>
					<IonCol size={12} size-xl={6} size-lg={6} size-md={12} size-sm={12}>
						<PlayList />
					</IonCol>
				</IonRow>
			</IonGrid>

			<IonLoading
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				message={'Please wait...'}
				duration={5000}
			/>

		</>);
	}

	return (<PageBase title={`Player: ${courseTitle}`} robots="noindex, nofollow" description="Video Player" canonical="https://jiujitsux.app/home/flow-player" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">
		<PageContent />
	</PageBase>);
};
