import React from 'react';
import CategoryItem from './CategoryItem';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode } from "swiper";
import { useAppContext } from '../../shared/AppContext';

const CategoryList = ({ dataSource }) => {

	const [appState, changeAppState] = useAppContext();

	return (
		<Swiper
			spaceBetween={0}
			slidesPerView="auto"
			//watchOverflow={false}
			freeMode={{
				enabled: true,
				momentumBounce: true,
				momentum: true,
				sticky: false,
				minimumVelocity: 0.02,
				momentumBounceRatio: 1,
				momentumRatio: 1,
				momentumVelocityRatio: appState.isIOS ? 0.5 : 0.9
			}}
			modules={[FreeMode]}
		>

			{dataSource.map((item, index) => {
				return (<SwiperSlide style={{ width: "120px" }} key={index}>
					<CategoryItem item={item} />
				</SwiperSlide>);
			})}
		</Swiper>);
}

export default CategoryList;