import React, { useState, useImperativeHandle, forwardRef } from "react";
import { IonLoading } from "@ionic/react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from 'uuid';

import { reorder, move } from "./course_content/operations";
import { getCombineScriptAndVimeoProject } from "../../../../core/backend/GQLQueries";

import Topics from "./course_content/Topics";


function CourseContent({ content, importDataFrom }, ref) {

	const [isLoading, setIsLoading] = useState(!importDataFrom.dontImportAgain);
	const [state, setState] = useState(content);

	useImperativeHandle(ref, () => ({
		getData: () => {
			return state;
		}
	}));


	React.useEffect(() => {
		if (!importDataFrom.dontImportAgain) {
			getCombineScriptAndVimeoProject(importDataFrom.courseScript.replace(/(?:\r\n|\r|\n)/g, '[NEW_LINE]'), importDataFrom.vimeoProjectId, undefined)
				.then(result => {
					setState(result.data.combineScriptAndVimeoProject);
					setIsLoading(false);
				})
				.catch(err => {
					if (err) {
						console.log(err);
					}
					setIsLoading(false);
				});
		}

	}, []);

	const dragEndHandler = (result) => {

		const { source, destination, type } = result;

		// dropped outside the list
		if (!destination) {
			return;
		}

		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return;
		}

		if (type === 'topic') {

			const newState = reorder(state, source.index, destination.index);
			setState(newState);
			return;
		}

		const sInd = +source.droppableId;
		const dInd = +destination.droppableId;

		if (sInd === dInd) {
			const lessons = reorder(state[sInd].lessons, source.index, destination.index);
			const newState = [...state];
			newState[sInd].lessons = lessons;
			setState(newState);
		} else {
			const result = move(state[sInd].lessons, state[dInd].lessons, source, destination);
			const newState = [...state];

			newState[sInd].lessons = result[sInd];
			newState[dInd].lessons = result[dInd];

			setState(newState.filter(group => group.lessons.length));
		}
	}

	const addTopicHandler = () => {
		const newState = [...state];
		newState.push({ title: "New Topic", description: "", lessons: [] });
		setState(newState);
	}

	const saveTopicHandler = (topicIndex, title, description) => {
		const newState = [...state];
		newState[topicIndex].title = title;
		newState[topicIndex].description = description;
		setState(newState);
	}

	const addLessonHandler = (topicIndex) => {
		var newState = Array.from(state);

		var newItem = {
			id: `${uuidv4()}`,
			title: `New item ${newState[topicIndex].lessons.length}`,
			description: "",
			vimeoId: "",
			duration: 0
		}

		newState[topicIndex].lessons.unshift(newItem);
		setState(newState);
	}

	const deleteLessonHandler = (topicIndex, lessonIndex) => {
		const newState = [...state];
		newState[topicIndex].lessons.splice(lessonIndex, 1);
		setState(
			newState.filter(group => group.lessons.length)
		);
	}

	const saveLessonHandler = (topicIndex, lessonIndex, title, description, vimeoId) => {

		const newState = [...state];
		newState[topicIndex].lessons[lessonIndex].title = title;
		newState[topicIndex].lessons[lessonIndex].description = description;
		newState[topicIndex].lessons[lessonIndex].vimeoId = vimeoId;
		setState(newState);
	}

	return (<div style={{ display: "flex" }}>
		<DragDropContext onDragEnd={dragEndHandler}>
			<Droppable
				droppableId="all-topics"
				direction="horizontal"
				type="topic"
			>
				{provided => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}
					>
						<Topics dataSource={state}
							onAddTopic={addTopicHandler}
							onSaveTopic={saveTopicHandler}
							onAddLesson={addLessonHandler}
							onSaveLesson={saveLessonHandler}
							onDeleteLesson={deleteLessonHandler} />
						{provided.placeholder}
					</div>
				)}
			</Droppable>

		</DragDropContext>

		<IonLoading
			isOpen={isLoading}
			onDidDismiss={() => setIsLoading(false)}
			message={'Loading course content...'}
			duration={10000}
		/>
	</div>);

}

export default forwardRef(CourseContent);