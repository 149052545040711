
import React from 'react';

import { IonIcon, IonImg, IonHeader, IonPage, IonToolbar, IonContent, IonButton, IonButtons, IonMenuButton, IonPopover, IonLabel } from '@ionic/react';
import { searchOutline, person, chatboxEllipses } from 'ionicons/icons';

import { GATrackOpenedSideMenu, GATrackClickedJJXLogo, GATrackHit } from '../../core/google';
import { useSetPartner } from '../../core/hooks';
import PromoBanner from '../courses/PromoBanner';

export default function PageBase({ GaTrack = true, gaOverridePage, title = "Jiu Jitsu X ofer 150+ Instructionals [Including Free Videos]", showButtons = true, onSearch, children, robots = "index, follow", description, canonical, og_type, image, image_alt }) {

	useSetPartner();

	const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;

	const changeSEO = async (title, robots, description, canonical, og_type, image, image_alt, url) => {
		//Essential
		document.title = title;
		document.querySelector('meta[name="robots"]').setAttribute("content", robots);
		document.querySelector('meta[name="description"]').setAttribute("content", description);
		document.querySelector('link[rel="canonical"]').setAttribute("href", canonical);

		//Open Graph Social Media
		document.querySelector('meta[property="og:type"]').setAttribute("content", og_type);
		document.querySelector('meta[property="og:title"]').setAttribute("content", title);
		document.querySelector('meta[property="og:description"]').setAttribute("content", description);
		document.querySelector('meta[property="og:image"]').setAttribute("content", image);
		document.querySelector('meta[property="og:url"]').setAttribute("content", url);
		document.querySelector('meta[property="og:site_name"]').setAttribute("content", "Jiu Jitsu X");

		//Twitter CARD
		//document.querySelector('meta[name="twitter:card"]').setAttribute("content", "");
		document.querySelector('meta[name="twitter:title"]').setAttribute("content", title);
		document.querySelector('meta[name="twitter:description"]').setAttribute("content", description);
		document.querySelector('meta[name="twitter:image"]').setAttribute("content", image);
		document.querySelector('meta[name="twitter:image:alt"]').setAttribute("content", image_alt);
		//document.querySelector('meta[name="twitter:site"]').setAttribute("content", "@JiuJitsu_X");
		//document.querySelector('meta[name="twitter:creator"]').setAttribute("content", "@JiuJitsu_X");

	}

	const trackPage = () => {
		GATrackHit(gaOverridePage ? gaOverridePage : window.location.pathname + window.location.search);
	}

	React.useEffect(() => {
		//TODO: Diff
		if (GaTrack) {
			changeSEO(title, robots, description, canonical, og_type, image, image_alt, window.location.href).then(() => setTimeout(trackPage, 100));
		}

	}, []);

	return (
		<>
			<IonPage id="main-content">
				<IonHeader className='ion-justify-content-center'>
					<PromoBanner />
					<IonToolbar color="top-bar" className='ion-justify-content-center'>
						<IonButtons slot="start">
							<IonMenuButton onClick={() => GATrackOpenedSideMenu()} />

							<IonButton onClick={() => GATrackClickedJJXLogo()} routerLink={{ pathname: "/home" }} style={{ marginLeft: (width / 2) - (showButtons ? 80 : 16) }}>

								<IonImg slot="start" src='https://jiujitsux.com/wp-content/uploads/2021/08/jjx_app_logo-150x150.png' style={{ width: 32, height: 32 }} />
							</IonButton>

						</IonButtons>
						{showButtons &&
							<IonButtons slot="end">
								<IonButton id="view-reviews" routerLink={"/home/all-reviews?isAllFromAuthor=true&id=-1&title=WHOLE-SITE-REVIEWS"} >
									<IonIcon color='primary' slot="icon-only" ios={chatboxEllipses} md={chatboxEllipses}></IonIcon>
								</IonButton>

								{/* <IonPopover trigger="view-reviews" triggerAction="click" alignment='end' dismissOnSelect={true} >
									<IonContent class="ion-padding">
										<IonLabel> <h6><i>See what people are saying about our courses.</i></h6></IonLabel>
										<IonButton style={{ marginTop: 15 }} expand='block' routerLink={"/home/all-reviews?isAllFromAuthor=true&id=-1&title=WHOLE-SITE-REVIEWS"} >
											Read Reviews
										</IonButton>
									</IonContent>
								</IonPopover> */}

								<IonButton routerLink={{ pathname: "/search" }} onClick={onSearch}>
									<IonIcon slot="icon-only" ios={searchOutline} md={searchOutline}></IonIcon>
								</IonButton>
								<IonButton routerLink={{ pathname: "/dashboard", state: { email: "", password: "" } }} >
									<IonIcon slot="icon-only" ios={person} md={person}></IonIcon>
								</IonButton>
							</IonButtons>
						}

					</IonToolbar>
				</IonHeader>


				<IonContent fullscreen >
					{children}
				</IonContent>
			</IonPage>	</>);
}