import React, { useState } from 'react';

import { IonIcon, IonLabel, IonTabButton, IonRouterOutlet, IonTabs, IonTabBar, IonBadge } from '@ionic/react';
import { chatbubbleOutline, cloudUploadOutline, home, personCircleOutline, radioOutline, searchOutline } from 'ionicons/icons';

import { Redirect, Route } from 'react-router-dom';
import { IsAdmin, IsAuthenticated, loggedUserData } from './components/auth/AuthCore';

import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import CourseDetail from './components/courses/CourseDetail';

import FlowPlayer from './components/video/FlowPlayer';
import FlowPlayerGuests from './components/video/FlowPlayerGuests';

import Home from './components/main/home/Home';
import Search from './components/main/search/Search';
import Category from './components/main/categories/Category';
import AllReviews from './components/courses/reviews/AllReviews';
import Dashboard from './components/main/dashboard/Dashboard';
import Feed from './components/main/rolls/Feed';
import PurchaseSuccess from './components/courses/PurchaseSuccess';
import PurchaseFail from './components/courses/PurchaseFail';
import PurchaseThankYou from './components/courses/PurchaseThankYou';
import FirebaseChat from './components/firechat/FirebaseChat';
import AuthorPage from './components/main/authors/AuthorPage';
import CourseBuilder from './components/main/dashboard/CourseBuilder';


const Routes = () => {

	const [newMsg, setNewMsg] = useState("new");
	const credentials = loggedUserData();

	return (<IonTabs>
		<IonRouterOutlet id="main">

			<Route exact path="/home">
				<Home />
			</Route>

			<Route exact path="/course-builder">
				<CourseBuilder />
			</Route>

			<Route exact path="/home/course-detail">
				<CourseDetail />
			</Route>

			<Route exact path="/home/flow-player">
				<FlowPlayer />
			</Route>

			<Route exact path="/home/flow-player-guests">
				<FlowPlayerGuests />
			</Route>

			<Route exact path="/home/course-by-category">
				<Category />
			</Route>

			<Route exact path="/home/author-bio">
				<AuthorPage />
			</Route>

			<Route exact path="/home/all-reviews">
				<AllReviews />
			</Route>

			<Route exact path="/home/purchase-success">
				<PurchaseSuccess />
			</Route>

			<Route exact path="/home/thank-you">
				<PurchaseThankYou />
			</Route>

			<Route exact path="/home/purchase-fail">
				<PurchaseFail />
			</Route>

			<Route exact path="/search">
				<Search />
			</Route>

			<Route exact path="/dashboard">
				{IsAuthenticated() ? <Dashboard /> : <SignIn />}
			</Route>

			<Route exact path="/dashboard/register">
				{IsAuthenticated() ? <Dashboard /> : <SignUp />}
			</Route>

			<Route exact path="/rolls">
				<Feed />
			</Route>

			<Route exact path="/chat">
				<FirebaseChat />
			</Route>

			<Route exact path="/">
				<Redirect to="/home" />
			</Route>

			<Route render={() => <Redirect to={{ pathname: "/" }} />} />
		</IonRouterOutlet>

		<IonTabBar slot="bottom">
			<IonTabButton tab="home" href="/home">
				<IonIcon icon={home} />
				<IonLabel>Home</IonLabel>
			</IonTabButton>

			<IonTabButton tab="rolls" href="/rolls">
				<IonIcon icon={radioOutline} />
				<IonLabel>Rolls</IonLabel>
			</IonTabButton>

			<IonTabButton tab="chat" href="/chat" onClick={() => setNewMsg("")}>
				<IonIcon icon={chatbubbleOutline} />
				<IonLabel>Super Chat</IonLabel>
				{newMsg != "" && <IonBadge>{newMsg}</IonBadge>}
			</IonTabButton>


			<IonTabButton tab="dashboard" href="/dashboard">
				<IonIcon icon={personCircleOutline} />
				<IonLabel>Dashboard</IonLabel>
			</IonTabButton>

			<IonTabButton tab="search" href="/search">
				<IonIcon icon={searchOutline} />
				<IonLabel>Search</IonLabel>
			</IonTabButton>

			{IsAdmin(credentials) &&
				<IonTabButton tab="builder" href="/course-builder">
					<IonIcon icon={cloudUploadOutline} />
					<IonLabel>Course Builder</IonLabel>
				</IonTabButton>
			}

		</IonTabBar>
	</IonTabs>);
}


export default Routes;
