import React, { useState, useRef } from "react";
import { IonLoading } from "@ionic/react";
import { Steps, Panel, ButtonGroup, Button } from 'rsuite';

import PageBase from "../../shared/PageBase";
import BasicInfo from "./course_builder/BasicInfo";
import ImportContent from "./course_builder/ImportContent";
import CourseContent from "./course_builder/CourseContent";
import ReadyToCreate from "./course_builder/ReadyToCreate";
import { getListAllCourseDrafts, getCourseDraft } from "../../../core/backend/GQLQueries";

import AppConfig from "../../../App.config";

import "rsuite/dist/rsuite.min.css";

function CourseBuilder() {

	const [isLoading, setIsLoading] = useState(false);

	const [draftList, setDraftList] = useState([]);

	const basicInfoRef = useRef(null);
	const importContentRef = useRef(null);
	const courseContentRef = useRef(null);

	const [step, setStep] = React.useState(0);

	const [basicInfoData, setBasicInfoData] = React.useState({});
	const [importContentData, setImportContentData] = React.useState({});
	const [courseContentData, setCourseContentData] = React.useState([]);

	const onChange = (actualStep, nextStep) => {

		if (actualStep < nextStep) {
			switch (nextStep) {
				case 1:
					setBasicInfoData(basicInfoRef.current.getData());
					break;

				case 2:
					setImportContentData(importContentRef.current.getData());
					break;

				case 3:
					setCourseContentData(courseContentRef.current.getData());
					break;

				default:
					break;
			}
		}
		else {
			switch (nextStep) {

				case 2:
					importContentData.dontImportAgain = true;
					setImportContentData(importContentData);
					break;

				case 1:
					setCourseContentData(courseContentRef.current.getData());
					break;

				default:
					break;
			}
		}

		setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
	};

	const onNext = () => onChange(step, step + 1);
	const onPrevious = () => onChange(step, step - 1);

	React.useEffect(() => {

		const endPoint = `${AppConfig.LegionAPI}/list-course-drafts`;

		let abortController = new AbortController();
		const signal = abortController.signal;

		fetch(endPoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			},
			signal: signal,
		})
			.then(response => {
				if (!response.ok) {
					setDraftList([{ value: "-1", label: "Create New Draft" }]);
					console.error('Network response was not ok.');
				}
				return response.json();
			})
			.then(data => {

				setDraftList([{ value: "-1", label: "Create New Draft" }, ...data.default]);
			})
			.catch(error => {
				setDraftList([{ value: "-1", label: "Create New Draft" }]);
				console.error('There was an error with the fetch operation:', error);
			});

		return () => {
			if (abortController)
				abortController.abort();
		}

	}, []);

	const loadDraftHandler = (id) => {
		setIsLoading(true);

		const endPoint = `${AppConfig.LegionAPI}/get-course-draft?id=${id}`;

		fetch(endPoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			},
		})
			.then(response => {
				if (!response.ok) {
					console.error('Network response was not ok.');
					setIsLoading(false);
				}
				return response.json();
			})
			.then(data => {
				setCourseContentData(JSON.parse(data.default.courseContent));

				const newBasicInfo = JSON.parse(data.default.basicInfo);
				newBasicInfo.loadDraft = id;
				setBasicInfoData(newBasicInfo);

				const newImportContent = JSON.parse(data.default.importContent);
				newImportContent.dontImportAgain = true;
				setImportContentData(newImportContent);

				setIsLoading(false);
			})
			.catch(error => {
				console.error('There was an error with the fetch operation:', error);
				setIsLoading(false);
			});
	}


	const RenderStep = ({ step }) => {
		switch (step) {
			case 0: return <BasicInfo draftList={draftList} basicInfo={basicInfoData} onLoadDraft={(id) => loadDraftHandler(id)} ref={basicInfoRef} />;
			case 1: return <ImportContent importDataFrom={importContentData} ref={importContentRef} />
			case 2: return <CourseContent content={courseContentData} importDataFrom={importContentData} ref={courseContentRef} />
			case 3: return <ReadyToCreate basicInfo={basicInfoData} content={courseContentData} importContent={importContentData} />
			default: return null;
		}
	}

	return (
		<PageBase title='Course Builder' robots="noindex, nofollow" description="Course Upload Tool" canonical="https://jiujitsux.app/course-builder" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">

			<div style={{ margin: 30, marginTop: 65 }}>


				<Steps current={step}>
					<Steps.Item title="Start Here" description="Add basic information about your course." />
					<Steps.Item title="Import Content" description="To save time you can import Script/Videos." />
					<Steps.Item title="Fine Tune" description="Build your course content." />
					<Steps.Item title="Create Course" description="We are ready to create your new course." />
				</Steps>
				<hr />
				<ButtonGroup>
					<Button onClick={onPrevious} disabled={step === 0}>
						Previous
					</Button>
					<Button onClick={onNext} disabled={step === 3}>
						Next
					</Button>
				</ButtonGroup>
				<hr />
				<Panel>
					<RenderStep step={step} />
				</Panel>
			</div>

			<IonLoading
				isOpen={isLoading}
				onDidDismiss={() => setIsLoading(false)}
				message={'Please wait. Retrieving Draft Data...'}
				duration={9000}
			/>
		</PageBase>);
}

export default CourseBuilder;