import React from 'react';
import PageBase from '../../shared/PageBase';
import HomeLazy from './HomeLazy';

export default function Home() {

	return (<PageBase title='Home' robots="index, follow" description="Explore 150+ courses incluiding jiu-jitsu, wrestling and more!" canonical="https://jiujitsux.app/home" og_type="website" image="https://jiujitsux.com/wp-content/uploads/2021/11/Jiu-Jitsu-X-Expert-courses-1.jpg" image_alt="Jiu Jitsu X Logo">
		<HomeLazy />
	</PageBase>);

}
